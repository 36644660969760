import {Component, Input, OnInit} from '@angular/core';
import {SourceView, SourceViewWithMessages} from "../../../../types/sherpa";
import {ImportService} from "../../../../services/data/import.service";
import {util} from "../../../../../services";
import {ImportMessagesModalComponent} from "../import-messages-modal/import-messages-modal.component";
import _ from "lodash";
import {DialogService} from "primeng/dynamicdialog";

@Component({
	selector: 'base-archive-tab',
	templateUrl: './archive-tab.component.html',
	styleUrls: ['./archive-tab.component.scss']
})
export class ArchiveTabComponent implements OnInit {

	imports: SourceView[] = [];
	@Input()
	imports$: Promise<SourceView[]>;

	constructor(private incidentsService: ImportService, private dialogService: DialogService) {

	}

	ngOnInit(): void {
		this.imports$.then((result: SourceView[]) => {
			this.imports = result;
		});
	}

	translateUserAction = (user_action: string) => {

		switch (user_action) {
			case 'pending' : return 'Wacht op actie gebruiker';
			case 'canceled' : return 'Verworpen';
			case 'committed' : return 'Geimporteerd';
			default: return "Onbekende (server)fout";
		}
	};

	getDetail(sourceView: SourceView, selectionMode: boolean = true) {
		this.incidentsService.getSourceMessages(sourceView.importsource_id)
			.then((result: SourceViewWithMessages) => {
				this.openModal(result);
			});
	}

	openModal(viewWithMessages: SourceViewWithMessages) {
		let ref = this.dialogService.open(ImportMessagesModalComponent, {
			width: '70%',
			showHeader: true,
			modal: true,
			header: viewWithMessages.source.filename,
			data: {
				messages: viewWithMessages.messages,
				sourceView: viewWithMessages.source,
				showActionButtons : false
			}
		});

		ref.onDestroy.subscribe((success: boolean) => {

		});

		ref.onClose.subscribe((sourceView: SourceView) => {
			if(_.isUndefined(sourceView)) {
				return; // abort action
			}
		});
	}
}
