
<div class="p-d-flex" *ngIf="showActionButtons">
	<div class="p-mr-2" *ngIf="source.import_result.non_uniques > 0">
		<button pButton pRipple class="p-button-outlined" icon="fa fa-thumbs-up" label="Overschrijf duplicaten"
			(click)="commitImport(true)"></button>
	</div>
	<div class="p-mr-2" *ngIf="source.import_result.non_uniques > 0">
		<button pButton pRipple class="p-button-outlined" icon="fa fa-thumbs-up" label="Negeer duplicaten"
			(click)="commitImport(false)"></button>
	</div>
	<div class="p-mr-2" *ngIf="source.import_result.non_uniques === 0">
		<button pButton pRipple class="p-button-outlined" icon="fa fa-thumbs-up" label="Importeer incidenten"
			(click)="commitImport(false)"></button>
	</div>
	<div class="p-mr-2">
		<button pButton pRipple class="p-button-outlined" icon="fa fa-trash-alt" label="Import verwerpen"
			(click)="cancelImport()"></button>
	</div>
</div>


<p-table
	styleClass="p-datatable-dova p-mt-4"
	[columns]="visibleColumns"
	[value]="messages"
	[responsive]="true"
	[autoLayout]="true"
	[paginator]="true"
	[showCurrentPageReport]="true"
	[rows]="10"
	[sortField]="'source_id'"
	[rowsPerPageOptions]="[10,25,50,100,500]"
	[rowHover]="true">

	<ng-template pTemplate="header" let-columns>
		<tr>
			<th>Toetsing</th>
			<th *ngFor="let col of columns" [pSortableColumn]="col.field">
				{{col.header}}
				<p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
							ariaLabelDesc="Activate to sort in descending order"
							ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
			</th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-rowData let-columns="columns">
		<tr>
			<td>
				<span class="p-tag p-tag-success" *ngIf="rowData.error_code === 0">
					Goed
				</span>
				<span class="p-tag p-tag-warning" *ngIf="rowData.error_code === 1">
					Let op
				</span>
				<span class="p-tag p-tag-danger" *ngIf="rowData.error_code === 2">
					Waarde genegeerd
				</span>
				<span class="p-tag p-tag-danger" *ngIf="rowData.error_code === 3">
					Rij genegeerd
				</span>
				<span class="p-tag p-tag-danger" *ngIf="rowData.error_code === 4">
					Sheet afgekeurd
				</span>
			</td>
			<td *ngFor="let col of columns">
				<span *ngIf="rowData[col.field] == null" class="p-text-italic">NULL</span>
				<span *ngIf="rowData[col.field] != null">{{rowData[col.field]}}</span>
			</td>
		</tr>
	</ng-template>

</p-table>