import {Component, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api";
import {IriasTranslation, LanguageType} from "../../../classes/models/IriasTranslation";
import {IriasConfigService} from "../../../services/irias-config.service";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
	selector: 'base-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

	items: MenuItem[] = [];
	activeItem: MenuItem;

	sub: Subscription;

	constructor(public iriasConfig: IriasConfigService, private router: Router) {

		this.sub = this.iriasConfig.language.subscribe(lang => {
			this.items = [
				{
					label: this.iriasConfig.getTranslation('panels.back_to') + ' Home',
					icon: 'fa fa-chevron-left',
					url: 'https://www.evdata.nl/'
				},
				{
					label: 'Data',
					url: '/data'
				}
			];

			this.activeItem = this.items[1];
		});

	}

	ngOnInit() {
	}

	setLanguage(l: 'nl'|'en') {

		let urlTree = this.router.parseUrl(this.router.url);
		urlTree.queryParams['lang'] = l;

		this.router.navigateByUrl(urlTree);
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
	}

}
