<div class="p-d-flex p-flex-column dashboard-page">

	<div class="nav">
		<div class="mrae-container p-d-flex p-ai-center" style="height: 100%;">
			<img src="assets/img/logo-final.png">

			<div class="p-ml-auto">
				<p-tabMenu [model]="items" [activeItem]="activeItem" styleClass="mrae-menu"></p-tabMenu>
			</div>

		</div>
	</div>

	<div class="banner">
		<div class="mrae-container p-d-flex p-ai-center" style="height: 100%;">
			<h1 class="">{{iriasConfig.getTranslation('dashboard.page_title')}}</h1>

			<div class="p-ml-auto">
				<div class="lang-box p-d-flex p-ai-center">
					<span class="p-pl-3 p-pr-3">{{iriasConfig.getTranslation('main.language_label')}}</span>
					<div (click)="setLanguage('nl');" [class]="{'active':iriasConfig.getActiveLanguage() == 0}">NL</div>
					<div (click)="setLanguage('en');" [class]="{'active':iriasConfig.getActiveLanguage() == 1}">EN</div>
				</div>
			</div>
		</div>
	</div>


	<div class="content">
		<div class="mrae-container">
			<router-outlet></router-outlet>
		</div>
	</div>


	<div class="footer p-py-3">
		<div class="mrae-container p-d-flex">
			<img src="assets/img/logo-final.png" style="height: 4rem;">

			<div class="p-d-flex p-flex-column p-pl-6">
				<ng-container *ngIf="iriasConfig.getActiveLanguage() == 0">
					<p class="p-text-bold">Disclaimer</p>
					<p>
						© 2019, G4, MRA-Elektrisch (MRA-E), SGZH en de organisaties die zij vertegenwoordigen. Alle rechten voorbehouden. Het copyright van al het materiaal dat op deze website gepubliceerd is, met inbegrip van alle content op deze website, de inhoud, het ontwerp, de tekst, de grafieken en de data, is eigendom van de G4, MRA-E en SGZH, tenzij anders vermeld. Dit materiaal mag niet worden gereproduceerd of verspreid, niet in zijn geheel en niet in delen, zonder voorafgaande schriftelijke toestemming. Echter, reproductie en verpreiding, in zijn geheel of in delen, voor eigen gebruik door niet-commeciële partijen, onderzoeks- of onderwijsinstellingen, is wel toegestaan, mits op de juiste manier credits worden gegeven, met volledige bronvermelding en het copyright wordt erkend. Elke andere vorm van reproductie of verspreiding, in welke vorm en in welk medium dan ook, is nadrukkelijk niet toegestaan zonder voorafgaande schriftelijke toestemming door MRA-E.
					</p>
					<p>
						In uitzonderlijke gevallen kunnen er data op deze website ontbreken. Dit is niet in strijd met het doel van deze website: het geven van een overzicht van de ontwikkeling van het (publiek) elektrisch laden in Nederland. Voor onderzoek is een uitgebreide dataset beschikbaar. Wilt u toegang? Dien dan een verzoek in door het <a href="https://www.evdata.nl/data-delen/">formulier op de website</a> in te vullen.
					</p>
					<p>
						Aan de inhoud van deze EVdata Monitor kunnen geen rechten worden ontleend. De Hogeschool van Amsterdam, G4, MRA-E en SGZH aanvaarden geen enkele aansprakelijkheid voor schade die voortkomt uit het bezoek aan deze website, schade die voortkomt uit het gebruik van de geaggregeerde data op deze website of schade die voortkomt uit fouten in de data of een verkeerde interpretatie van de informatie in deze Monitor. De Hogeschool van Amsterdam, G4, MRA-E en SGZH garanderen niet dat deze website foutloos is en zonder storingen werkt.
					</p>
					<p>
						Meer weten? Neem <a href="mailto:c.poort@mrae.nl?subject=Vraag over Evdata">contact</a> op met MRA-Elektrisch.
					</p>
				</ng-container>
				<ng-container *ngIf="iriasConfig.getActiveLanguage() == 1">
					<p class="p-text-bold">Disclaimer</p>
					<p>
						© 2019, G4, MRA-Electric (MRA-E), SGZH and organisations they represent. All rights reserved. The copyright in all material published on this site, including all portions of the website, content, site design, text, graphics and the data material is owned by G4, MRA-E and SGZH, unless otherwise indicated. The material may not be reproduced or distributed, in whole or in part, without prior written permission. However, reproduction and distribution, in whole or in part, by non-profit, research or educational institutions for their own use is permitted if proper credit is given, with full citation, and copyright is acknowledged. Any other reproduction or distribution, in whatever form and by whatever media, is expressly prohibited without the prior written consent of MRA-E.
					</p>
					<p>
						Only in exceptional cases, data values are missing on this website. This does not conflict with the purpose of this website, to present an overall overview of the charging developments in the Netherlands. For research purposes an extended data set is used. Would you like to have access as well? Then you can apply for access by filling in <a href="https://www.evdata.nl/data-delen/">data sharing form.</a>
					</p>
					<p>
						No rights can be derived from any of the content presented in this EVdata Monitor. AUAS/G4/MRA-E/SGZH cannot be held responsible for any information found on this website. AUAS/G4/MRA-E/SGZH do not accept any liability for damage resulting from visiting this website, any damage caused by the use of offered aggregated data or any damage resulting from data-errors or incorrect interpretation of the information presented in this Monitor. AUAS/G4/MRA-E/SGZH do not guarantee this website to function without error or interruption.
					</p>
					<p>
						For further information or any specific questions, please <a href="mailto:c.poort@mrae.nl?subject=Question Evdata">contact MRA-E.</a>
					</p>
				</ng-container>
			</div>
		</div>
	</div>

</div>
