import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ConfirmationService, Message, MessageService} from "primeng/api";
import {ConfigsService} from "../../../services/data/configs.service";
import {util} from "../../../../services";
import {MultiChartQueryConfiguration, QueryConfiguration, StoredConfig} from "../../../types/sherpa";
import _ from "lodash";
import {AnalysePanel} from "../../../classes/models/AnalysePanel";
import {ImportService} from "../../../services/data/import.service";

@Component({
  selector: 'base-my-filter',
  templateUrl: './my-filter.component.html',
  styleUrls: ['./my-filter.component.scss']
})
export class MyFilterComponent implements OnInit {

	@Input() storedConfig: StoredConfig;
	@Input() panels: AnalysePanel[];
	@Input() myConfigs: StoredConfig[];

	@Output() onClose: EventEmitter<void> = new EventEmitter<void>();
	@Output() onDelete: EventEmitter<number> = new EventEmitter<number>();
	@Output() onSave: EventEmitter<StoredConfig> = new EventEmitter<StoredConfig>();

	form: FormGroup;
	duplicateError: boolean = false;

	public get controls() {
		return this.form.controls;
	}

	constructor(private _fb: FormBuilder, public messageService: MessageService, private incidentsService: ImportService, private confirmationService: ConfirmationService, public configsService: ConfigsService) {
	}

	containError(code: string) {
		return this.controls.errors.getError(code);
	}

	ngOnInit() {
		if(this.storedConfig === null) {
			this.storedConfig = {} as StoredConfig;
			this.storedConfig.label = null;
			this.storedConfig.show_in_menu = true
		}

		if(this.storedConfig.show_in_menu === null) {
			this.storedConfig.show_in_menu = true;
		}

		this.form = this._fb.group({
			name: [this.storedConfig.label, Validators.required],
			showInMenu: [this.storedConfig.show_in_menu, Validators.required]
		});
	}

	submit() {
		this.duplicateError = false;
		if(this.form.invalid) {
			return;
		}

		const label = this.controls.name.value;
		const already = _.some(this.myConfigs, ['label', label]);

		if(already && (this.storedConfig === null || this.storedConfig === undefined)) {
			this.duplicateError = true;
			return;
		}

		let cleanPanels: AnalysePanel[] = null;

		if((this.storedConfig === null || this.storedConfig === undefined) && this.myConfigs !== null && this.myConfigs !== undefined){
			// workaround
			this.storedConfig = {
				label: "", month_window_size: 0, show_in_menu: false, stored_config_id: null, user_email: "",
				_createNewConfig : true,
				_updateConfig : true,
				config : this.myConfigs
			}
		}
		else if(
			this.storedConfig.config === null || this.storedConfig.config === undefined
		){
			this.storedConfig.config = this.myConfigs;
			if(this.storedConfig._createNewConfig === null || this.storedConfig._createNewConfig === undefined) {
				this.storedConfig._createNewConfig = true;
			}
			if(this.storedConfig._updateConfig === null || this.storedConfig._updateConfig === undefined) {
				this.storedConfig._updateConfig = true;
			}
		}


		if(this.storedConfig?._updateConfig) {
			cleanPanels = [];

			_.forEach(this.panels, panel => {
				let clean = _.omit(panel, this.incidentsService.panelAdditionProperties) as AnalysePanel;
				cleanPanels.push(clean);
			});

			let range = _.range(cleanPanels.length);
			_.forEach(range, i => {
				if (!this.panels[i].visible) {
					cleanPanels[i] = null;
				}
			});
		}

		let storedConfig = {
			stored_config_id: this.storedConfig?._createNewConfig ? null : this.storedConfig?.stored_config_id,
			label: this.controls.name.value,
			show_in_menu: this.controls.showInMenu.value,
			user_email: null,
			config: cleanPanels
		} as StoredConfig;



		this.configsService.saveConfig(storedConfig).then((result: StoredConfig) => {
			this.close();
			this.onSave.emit(result);
		});
	}

	close() {
		this.onClose.emit();
	}

	confirmDelete() {
		this.confirmationService.confirm({
			message: 'Weet u zeker dat u dit filter wilt verwijderen?',
			header: 'Verwijderen bevestigen',
			icon: 'pi pi-info-circle',
			accept: () => {
				this.configsService.deleteConfig(this.storedConfig.stored_config_id)
					.then(() => this.onDelete.emit(this.storedConfig.stored_config_id));
			},
			reject: () => {
			}
		});
	}

}
