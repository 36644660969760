import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {sherpa} from "../../services";
import {global} from "../../globals";
import {PasswordResetComponent} from "../components/modals/password-reset/password-reset.component";
import {PasswordComponent} from "../components/modals/password/password.component";
import {AuthParameter, UserModel} from "../classes/models/UserModel";
import {BackendFunctions, Sherpa} from "../classes/Sherpa";
import {SessionService} from "./data/session.service";
import {SherpaError} from "../classes/SherpaError";
import _ from "lodash";
import {DialogService} from "primeng/dynamicdialog";
import {MessageService} from "primeng/api";

@Injectable({
	providedIn: 'root'
})
export class AuthService implements CanActivate {

	subscription: Subscription;
	authSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	sessionUser: UserModel | null = null;
	authApi: BackendFunctions = {};

	constructor(public router: Router, public sessionService: SessionService, public dialogService: DialogService, public messageService: MessageService) {
		this.subscription = sherpa.auth$.subscribe((sherpa: Sherpa) => {
			this.authApi = sherpa.api
		});
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		global.requestedUrl = state.url;
		global.loginModal = false;

		const tokenFromUrl = route.queryParams['login'] as string;
		if(!_.isNil(tokenFromUrl)) {
			this.setToken(tokenFromUrl);
		}

		return this.sessionService.whoami()
			.then((user: UserModel) => {
				this.setUser(user);
				this.authSubject.next(true);
				return true;
			})
			.catch((error: SherpaError) => {
				console.error('Access denied:', error.message, state.url);
				this.clear(false);
				global.requestedUrl = state.url;
				global.loginModal = true;
				this.authSubject.next(false);
				return false;
			});
	}

	clear(reload: boolean = true): void {
		this.removeToken();
		this.setUser(null);
		if(reload) {
			window.location.reload();
		}
	}

	setUser(user: UserModel): void {
		this.sessionUser = user;
		global.loginUser = user;
		global.loginModal = user === null;
		global.requestedUrl = '/';
	}

	getUser(): UserModel | null {
		return this.sessionUser;
	}

	getUser$(): Observable<UserModel> {
		return of(this.getUser());
	}

	token(): string {
		return sessionStorage.getItem('token') || '';
	}

	setToken(token: string): void {
		sessionStorage.setItem('token', token);
	}

	removeToken(): void {
		sessionStorage.removeItem('token');
	}


	/* auth modals */



	loginModal(requested_url: string): void {
	}

	passwordResetModal() {
		this.dialogService.open(PasswordResetComponent, {
			width: '36rem',
			showHeader: true,
			header: 'Wachtwoord vergeten',
			data: {},
			baseZIndex: 1500
		});
	}

	passwordChangeModal(): void {
		this.dialogService.open(PasswordComponent, {
			width: '36rem',
			showHeader: true,
			header: 'Wachtwoord wijzigen',
			data: {},
			baseZIndex: 1500
		});
	}


	getUserParameterValue(key : string) {

		if(this.sessionUser.params !== null && this.sessionUser.params !== undefined){
			let f:AuthParameter = _.find(this.sessionUser.params, (up : AuthParameter) => {
				return up.key === key;
			});
			if(f !== null && f !== undefined){
				return f.value;
			}
		}
		return null;
	}
}
