import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Injector, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {app, setInjector} from "../services";
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PageNotFoundComponent} from './components/common/page-not-found/page-not-found.component';
import {DashboardComponent} from './components/pages/dashboard/dashboard.component';
import {LoginComponent} from './components/modals/login/login.component';
import {LinkComponent} from './components/modals/link/link.component';
import {CopyComponent} from './components/modals/copy/copy.component';
import {PasswordResetComponent} from "./components/modals/password-reset/password-reset.component";
import {PasswordComponent} from "./components/modals/password/password.component";
import {BeheerComponent} from './components/pages/beheer/beheer.component';
import {AppLoaderComponent} from './components/common/app-loader/app-loader.component';
import {LoaderInterceptorService} from "./services/interceptors/loader-interceptor.service";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {ImportsComponent} from './components/pages/admin/imports/imports.component';
import {ImportTabComponent} from './components/pages/admin/import-tab/import-tab.component';
import {RippleModule} from "primeng/ripple";
import {TableModule} from "primeng/table";
import {FileUploadModule} from "primeng/fileupload";
import {ToastModule} from "primeng/toast";
import {CardModule} from "primeng/card";
import {TabViewModule} from "primeng/tabview";
import {MessageModule} from "primeng/message";
import {InputTextModule} from "primeng/inputtext";
import {PasswordModule} from "primeng/password";
import {DropdownModule} from "primeng/dropdown";
import {ChipsModule} from "primeng/chips";
import {ConfirmationService, MessageService} from "primeng/api";
import {DialogService} from "primeng/dynamicdialog";
import {ChartModule} from "primeng/chart";
import {MenuModule} from "primeng/menu";
import {MenubarModule} from "primeng/menubar";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {DialogModule} from "primeng/dialog";
import {PanelModule} from "primeng/panel";
import {ImportMessagesModalComponent} from './components/pages/admin/import-messages-modal/import-messages-modal.component';
import {SidebarModule} from "primeng/sidebar";
import {TreeModule} from "primeng/tree";
import {DocsTabComponent} from './components/pages/admin/docs-tab/docs-tab.component';
import {FilterMultipleComponent} from './components/common/filter-multiple/filter-multiple.component';
import {FilterTreeComponent} from './components/common/filter-tree/filter-tree.component';
import {AccordionModule} from "primeng/accordion";
import {ToolbarModule} from "primeng/toolbar";
import {FilterSingleComponent} from './components/common/filter-single/filter-single.component';
import {RadioButtonModule} from "primeng/radiobutton";
import {TooltipModule} from "primeng/tooltip";
import {CheckboxModule} from "primeng/checkbox";
import {PanelMenuModule} from "primeng/panelmenu";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {MyFilterComponent} from './components/modals/my-filter/my-filter.component';
import {PanelsComponent} from './components/pages/panels/panels.component';
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {FiltersResolver} from "./resolvers/filters.resolver";
import {GroupOptionsResolver} from "./resolvers/groupOptions.resolver";
import {StoredConfigsResolver} from "./resolvers/storedConfigs.resolver";
import {ToggleButtonModule} from "primeng/togglebutton";
import {FilterDateComponent} from './components/common/filter-date/filter-date.component';
import {CalendarModule} from "primeng/calendar";
import {FilterExtentComponent} from './components/common/filter-extent/filter-extent.component';
import {InputSwitchModule} from "primeng/inputswitch";
import {SelectButtonModule} from "primeng/selectbutton";
import {IncidentTableComponent} from './components/common/incident-table/incident-table.component';
import {ArchiveTabComponent} from './components/pages/admin/archive-tab/archive-tab.component';
import {InplaceModule} from "primeng/inplace";
import {MultiSelectModule} from "primeng/multiselect";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {ImportDataOverviewComponent} from './components/pages/import-data-overview/import-data-overview.component';
import {FiltersSidebarComponent} from './components/common/filters-sidebar/filters-sidebar.component';
import {SplitButtonModule} from "primeng/splitbutton";
import {FilterBasicComponent} from './components/common/filter-basic/filter-basic.component';
import {FilterGroupbyComponent} from './components/common/filter-groupby/filter-groupby.component';
import {EditFilterMultipleComponent} from './components/common/edit-filter-multiple/edit-filter-multiple.component';
import {TriStateCheckboxModule} from "primeng/tristatecheckbox";
import {FilterPipe} from './pipes/filter.pipe';
import {FiltersTopbarMraeComponent} from './components/common/filters-topbar-mrae/filters-topbar-mrae.component';
import {FilterComparisonComponent} from './components/common/filter-comparison/filter-comparison.component';
import {EditFilterExtentComponent} from './components/common/edit-filter-extent/edit-filter-extent.component';
import {IncidentDetailComponent} from './components/common/incident-detail/incident-detail.component';
import {MyDefaultFiltersComponent} from './components/modals/my-default-filters/my-default-filters.component';
import {InputTextareaModule} from "primeng/inputtextarea";
import {TabMenuModule} from "primeng/tabmenu";
import {ConfirmComponent} from './components/pages/confirm/confirm.component';
import {RegisterComponent} from './components/modals/register/register.component';
import { AnalysesNewComponent } from './components/pages/analyses-new/analyses-new.component';
import { TagModule as PTagModule } from 'primeng/tag';
import { ChipModule as PChipModule } from 'primeng/chip';
import { DashboardTrendsComponent } from './components/pages/dashboard-trends/dashboard-trends.component';
import {PanelsAdminComponent} from "./components/pages/panels-admin/panels-admin.component";
import {FiltersTopbarComponent} from "./components/common/filters-topbar/filters-topbar.component";
import { ChartComponent } from './components/common/chart/chart.component';

@NgModule({
	declarations: [
		AppComponent,
		PageNotFoundComponent,
		DashboardComponent,
		BeheerComponent,
		PasswordComponent,
		PasswordResetComponent,
		LoginComponent,
		LinkComponent,
		CopyComponent,
		AppLoaderComponent,
		PanelsComponent,
		ImportsComponent,
		ImportTabComponent,
		ImportMessagesModalComponent,
		DocsTabComponent,
		FilterMultipleComponent,
		FilterTreeComponent,
		FilterSingleComponent,
		MyFilterComponent,
		FilterDateComponent,
		FilterExtentComponent,
		IncidentTableComponent,
		ArchiveTabComponent,
		ImportDataOverviewComponent,
		FiltersSidebarComponent,
		FilterBasicComponent,
		FilterGroupbyComponent,
		EditFilterMultipleComponent,
		FilterPipe,
		FiltersTopbarMraeComponent,
		FilterComparisonComponent,
		EditFilterExtentComponent,
		IncidentDetailComponent,
		MyDefaultFiltersComponent,
		ConfirmComponent,
		RegisterComponent,
		AnalysesNewComponent,
		DashboardTrendsComponent,
		PanelsAdminComponent,
		FiltersTopbarComponent,
		ChartComponent
	],
	imports: [
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		ReactiveFormsModule,
		HttpClientModule,
		TableModule,
		TabMenuModule,
		FormsModule,
		DropdownModule,
		InputTextModule,
		PTagModule,
		PChipModule,
		ChipsModule,
		ChartModule,
		ToastModule,
		MenuModule,
		CardModule,
		RippleModule,
		TabViewModule,
		PasswordModule,
		FileUploadModule,
		MessageModule,
		TableModule,
		FileUploadModule,
		ToastModule,
		CardModule,
		MessageModule,
		InputTextModule,
		PasswordModule,
		DropdownModule,
		ChipsModule,
		MenubarModule,
		ProgressSpinnerModule,
		DialogModule,
		PanelModule,
		SidebarModule,
		TreeModule,
		AccordionModule,
		ToolbarModule,
		RadioButtonModule,
		TooltipModule,
		CheckboxModule,
		PanelMenuModule,
		OverlayPanelModule,
		ConfirmDialogModule,
		ToggleButtonModule,
		CalendarModule,
		InputSwitchModule,
		SelectButtonModule,
		InplaceModule,
		MultiSelectModule,
		ScrollPanelModule,
		SplitButtonModule,
		TriStateCheckboxModule,
		InputTextareaModule,
	],
	providers: [
		CdkVirtualScrollViewport,
		MessageService, DialogService, ConfirmationService,
		FiltersResolver, GroupOptionsResolver, StoredConfigsResolver,
		{provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true}
	],
	bootstrap: [AppComponent],
	exports: [
		IncidentTableComponent,
		MyFilterComponent,
		FiltersSidebarComponent,
		LinkComponent,
		EditFilterMultipleComponent,
		EditFilterExtentComponent
	],
	entryComponents: [
		CopyComponent,
		LinkComponent,
		PasswordComponent,
		PasswordResetComponent,
		LoginComponent,
		ImportMessagesModalComponent
	]
})

export class AppModule {
	constructor(_injector: Injector) {
		//Set the Injector to be able to open modal in static functions
		if (app) {
			console.error('Programming error: AppInjector was already set');
		} else {
			setInjector(_injector);
		}
	}
}
