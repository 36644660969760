<form [formGroup]="form" novalidate>
	<p class="p-mt-0">Een analyse is persoonlijk en wordt niet gedeeld met andere gebruikers. U kunt uw analyse te allen tijde aanpassen, overschrijven of verwijderen.</p>

	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12">
			<label for="name" class="p-text-normal">Naam van uw analyse</label>
			<input id="name" type="text" pInputText formControlName="name">
			<small id="name-help2" class="p-invalid" *ngIf="controls.name.invalid && controls.name.dirty">
				Naam is verplicht
			</small>
			<small id="name-help" style="float: left" *ngIf="!duplicateError">
				Tip: Voer een duidelijke naam in, bijv: A-incidenten, Midden Overijssel, 2019
			</small>
			<small class="p-invalid" id="name-error" style="float: left" *ngIf="duplicateError">
				Deze naam bestaat al, kies een andere naam
			</small>
		</div>
		<div class="p-field-checkbox p-col-12">
			<p-checkbox name="showInMenu" formControlName="showInMenu" inputId="showInMenu" [binary]="true"></p-checkbox>
			<label [for]="'showInMenu'">Toon deze analyse als menu-item</label>
		</div>
	</div>
</form>

<p-toolbar styleClass="p-p-0 p-mt-3">
	<div class="p-toolbar-group-left">
		<button pButton pRipple type="button" icon="fa fa-trash-alt" class="p-button-danger p-button-rounded"
				*ngIf="storedConfig?.stored_config_id"
				(click)="confirmDelete()"></button>
	</div>

	<div class="p-toolbar-group-right">
		<button pButton pRipple type="button" label="Annuleren" class="p-button-secondary p-button-text p-button-rounded p-mr-2"
				(click)="close()"></button>
		<button pButton pRipple type="button" label="Opslaan" class="p-button-rounded p-text-uppercase"
				(click)="submit()"
				[disabled]="form.invalid ? true : null"></button>
	</div>
</p-toolbar>

<p-confirmDialog #cd [style]="{width: '30rem'}" [baseZIndex]="10000">
	<p-footer>
		<button pButton pRipple type="button" label="Nee" class="p-button-secondary p-button-text p-button-rounded p-mr-2"
				(click)="cd.reject()"></button>
		<button pButton pRipple type="button" label="Ja, verwijder" class="p-button-rounded p-text-uppercase"
				(click)="cd.accept()"></button>
	</p-footer>
</p-confirmDialog>
