import {Component, OnInit} from '@angular/core';
import {DataFields} from "../../../../types/sherpa";
import {ImportService} from "../../../../services/data/import.service";
import _ from "lodash";
import marked from "marked";

@Component({
	selector: 'base-docs-tab',
	templateUrl: './docs-tab.component.html',
	styleUrls: ['./docs-tab.component.scss']
})
export class DocsTabComponent implements OnInit {
	fields: DataFields = null;

	constructor(private incidentsService: ImportService) {
	}

	ngOnInit(): void {
		this.incidentsService.getFields().then((result: DataFields) => {
			this.fields = result;
		});
	}

	arrToString(arr: string []) {
		return _.join(arr, ', ');
	}

	asMarkdown(txt: string) {
		return marked(txt);
	}
}
