<div class="p-d-flex p-flex-column p-ai-stretch">

	<div class="p-d-flex dialog-header">
		<i class="fal fa-charging-station p-pr-2"></i>
		<div class="">
			{{iriasConfig.getTranslation('panels.register_title')}}
		</div>
	</div>

	<form [formGroup]="registerForm" (ngSubmit)="submit()" *ngIf="!signMode">

		<div class="p-fluid">
			<div class="p-field">
				<label for="b">{{iriasConfig.getTranslation('panels.register_name')}}</label>
				<input id="b" type="text" pInputText
					   formControlName="name"
					   [ngClass]="{ 'is-invalid': submitted && r_controls.name.errors }"/>
			</div>
			<div class="p-field">
				<label for="c">{{iriasConfig.getTranslation('panels.register_org')}}</label>
				<input id="c" type="text" pInputText formControlName="organisation"/>
			</div>
			<div class="p-field">
				<label for="a">E-mail</label>
				<input id="a" type="email" pInputText
					   formControlName="email"
					   [ngClass]="{ 'is-invalid': submitted && r_controls.email.errors }"/>
			</div>
		</div>

		<div class="p-d-flex p-ai-center p-mt-4">
			<button pButton pRipple type="submit"
					[disabled]="registerForm.invalid && submitted"
					[label]="iriasConfig.getTranslation('panels.register_button').toString()"
					class="mrae-button p-button-rounded p-button-raised p-button-text p-button-secondary p-d-flex">
			</button>

			<button pButton pRipple type="button"
					style="color: white;"
					(click)="signMode = !signMode;"
					[label]="iriasConfig.getTranslation('panels.register_switch_button').toString()"
					class="p-button-link p-ml-2">
			</button>
		</div>

	</form>


	<form [formGroup]="signForm" (ngSubmit)="sign()" *ngIf="signMode">

		<div class="p-fluid">
			<div class="p-field">
				<label for="d">E-mail</label>
				<input id="d" type="email" pInputText
					   formControlName="email"
					   [ngClass]="{ 'is-invalid': signed && s_controls.email.errors }"/>
			</div>
			<div class="p-field">
				<label for="e">{{iriasConfig.getTranslation('panels.register_secret')}}</label>
				<input id="e" type="text" pInputText
					   formControlName="secret"
					   [ngClass]="{ 'is-invalid': signed && s_controls.secret.errors }"/>
			</div>

			<p-message severity="warn"
					   *ngIf="signStatus != null"
					   [text]="iriasConfig.getTranslation('panels.register_' + signStatus)" styleClass="">
			</p-message>
		</div>


		<div class="p-d-flex p-ai-center p-mt-4">
			<button pButton pRipple type="submit"
					[label]="iriasConfig.getTranslation('panels.register_sign_button')"
					class="mrae-button p-button-rounded p-button-raised p-button-text p-button-secondary p-d-flex"
					[disabled]="signed && signForm.invalid">
			</button>

			<button pButton pRipple type="button"
					style="color: white;"
					(click)="signMode = !signMode;"
					[label]="iriasConfig.getTranslation('main.back')"
					class="p-button-link p-ml-2">
			</button>
		</div>

	</form>

</div>
