// Makes the services globally accessible without having to inject them in the constructor of the component
import {Injector} from "@angular/core";
import {AuthService} from "./app/services/auth.service";
import {UtilService} from "./app/services/util.service";
import {SherpaService} from "./app/services/sherpa.service";

export let app: Injector;
export let auth: AuthService;
export let util: UtilService;
export let sherpa: SherpaService;

export function setInjector(injector: Injector) {
	app = injector;
	sherpa = app.get(SherpaService);
	auth = app.get(AuthService);
	util = app.get(UtilService);
}