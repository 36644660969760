import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ImportService} from '../../../services/data/import.service';
import {Filter, GroupOption, UpdateIncident} from '../../../types/sherpa';
import {Table} from 'primeng/table';
import {BehaviorSubject} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {AnalysePanel, PanelType} from "../../../classes/models/AnalysePanel";
import {TableColumn} from "../../../classes/primeng/TableColumn";
import _ from "lodash";
import {auth, util} from "../../../../services";
import {ConfirmationService, MessageService} from "primeng/api";
import {AuthService} from "../../../services/auth.service";
import {LanguageType} from "../../../classes/models/IriasTranslation";

@Component({
	selector: 'base-incidents-overview',
	templateUrl: './import-data-overview.component.html',
	styleUrls: ['./import-data-overview.component.scss']
})
export class ImportDataOverviewComponent implements OnInit {

	panelSubject: BehaviorSubject<AnalysePanel> = new BehaviorSubject<AnalysePanel>(null);

	@ViewChild('dt') table: Table;
	loading: boolean = true;
	columns: TableColumn[] = [];
	fields: string[] = [];

	filters$: Promise<Filter[]>;
	groupOptions$: Promise<GroupOption[]>;

	panel: AnalysePanel;
	filters: Filter[];
	dataResult: any[] = [];

	showSidebar: boolean = false;
	showRowDetail: boolean = false;
	submitted: boolean = false;
	selectedRow: any = null;
	selectedRows: any[] = [];

	public isVervoerder: boolean = null;
	public dataownercode: string;
	public isAdmin: boolean = null;

	showFilterButton = false;

	constructor(private route: ActivatedRoute,
				private cd: ChangeDetectorRef,
				private incidentsService: ImportService,
				private confirmationService: ConfirmationService,
				private authService : AuthService) {
		this.columns = [
			{ field: 'unique_external_id', header: '#UID' },
			{ field: 'som_kwh', header: 'Som kWh' },
			{ field: 'aantal_elektrische_kilometers', header: 'Aantal eKM' },
			{ field: 'aantal_unieke_rfids', header: 'Aantal uniek RFIDs' },
			{ field: 'date', header: 'Datum' },
			{ field: 'importsource', header: 'Bronbestand' },
			{ field: 'importsourcetype', header: 'Soort' },
		];

		this.route.queryParamMap.subscribe(params => {
			this.showFilterButton = params.has('filter');
		});
	}

	ngOnInit(): void {
		this.filters$ = this.incidentsService.getFilters({analyse_type : 'incident_overview'} as AnalysePanel);
		this.groupOptions$ = this.incidentsService.getGroupOptions();

		this.fields = _.map(this.columns, 'field');

		this.filters$.then(result => {
			this.filters = result;
			this.initPanel();
		});
	}

	initPanel() {
		this.panel = new AnalysePanel(true, 0);
		this.panel.type = PanelType.table;
		this.panel.analyse_type = 'data_overview';
		this.panel.includeFields = this.fields;
		this.panel.filters = this.filters;

		this.getData(this.panel);
	}

	openPanelFilter() {
		this.showSidebar = !this.showSidebar;
		this.panelSubject.next(this.panel);
	}


	/* TABLE */

	search(target: any) {
		this.table.filterGlobal(target.value, 'contains');
	}

	searchColumn(target: any, field: string, mode: 'startsWith' | 'contains' | 'endsWith' | 'equals' | 'notEquals' | 'in' | 'lt' | 'lte' | 'gt' | 'gte') {
		this.table.filter(target.value, field, mode);
	}

	onRowSelect(event: any) {
		this.showRowDetail = !this.showRowDetail;
	}

	onRowUnselect(event: any) {
		this.showRowDetail = !this.showRowDetail;
	}

	onRowClick(item: any) {
		this.selectedRow = item;
		this.showRowDetail = !this.showRowDetail;
	}


	/* SIDEBAR CALLBACK */

	panelUpdated(panel: AnalysePanel) {
		this.getData(panel);
		this.panel = panel;
		this.panel.analyse_type = 'data_overview';
	}


	/* FETCH DATA */

	getData(byPanel: AnalysePanel = {} as AnalysePanel) {
		this.loading = true;

		this.incidentsService.getDataForTable(byPanel).then(result => {
			this.dataResult = result;
			this.loading = false;
			this.submitted = true;
		});
	}

	deleteSelected() {
		let bulk: any[] = [];

		_.forEach(this.selectedRows, rowData => {
			let item = _.pick(rowData, ['unique_external_id']);
			bulk.push(item);
		});

		this.loading = true;
		this.incidentsService.deleteBulkData(bulk)
			.then(() => {
				this.getData(this.panel);
				this.selectedRows = [];
			})
			.finally(() => {
				this.loading = false;
			});
	}

	getSelected(){

		let filtered = _.filter(this.selectedRows, (item : any) => {
			if(this.isVervoerder && _.upperCase(this.dataownercode) === _.upperCase(item.dataownercode)) {
				return true;
			}
			else if(!this.isVervoerder){
				return true;
			}
			else {
				return false;
			}
		});

		return filtered;

	}


	/* refactor to upload-owner ??? */

	checkIsVervoerder() {}
	isCorrectVervoerder(dataownercode : string) {}


	/* HELPERS */

	canDelete(item : any){
		if(this.isAdmin || true){
			return true;
		}

		return false;
	}

	confirmDelete() {
		this.confirmationService.confirm({
			message: `Weet u zeker dat u ${this.selectedRows.length} rijen wilt verwijderen?`,
			header: 'Verwijderen bevestigen',
			icon: 'pi pi-info-circle',
			accept: () => {
				this.deleteSelected();
			},
			reject: () => {
			}
		});
	}

	/* unsubscribe when changing pages */
	ngOnDestroy() {
		this.panelSubject.unsubscribe();
	}
}
