<div class="p-d-flex p-flex-column p-ai-stretch">

	<div class="p-d-flex dialog-header">
		<i class="fal fa-charging-station p-pr-2"></i>
		<div class="">
			{{iriasConfig.getTranslation('panels.register_success_title')}}
		</div>
	</div>

	<div *ngIf="registrationResult === null">
		{{iriasConfig.getTranslation('panels.register_pending_text')}}
	</div>

	<div class="p-d-flex p-ai-center"
		 *ngIf="registrationResult !== null && registrationResult.status === 'confirmed' && registrationResult.registration !== null">

		<p>
				<span>
					{{iriasConfig.getTranslation('panels.register_text_1')}}
				</span>
			<span>
					<strong>{{ registrationResult.registration.email}}</strong>
				</span>
			<span>
					{{iriasConfig.getTranslation('panels.register_text_2')}}
				</span>
		</p>

	</div>

	<div class="p-d-flex p-ai-center p-mt-4">

		<p-button (onClick)="downloadFile()"
				  styleClass="p-button-raised p-button-text p-button-lg p-button-rounded button-white button-download">
			<span class="p-mr-3">Download data sheet</span>
			<img alt="logo" src="assets/img/icon_arrow_blue.png"/>
		</p-button>

	</div>
</div>


