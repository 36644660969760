import {Component, OnInit} from '@angular/core';
import {RegistrationCredentials, RegistrationResult, UserRegistration} from "../../../types/sherpa";
import {ConfigsService} from "../../../services/data/configs.service";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {IriasConfigService} from "../../../services/irias-config.service";
import _ from "lodash";
import {UtilService} from "../../../services/util.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
	selector: 'base-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
	registration: UserRegistration;

	signMode = false;
	secret: string = '';
	signStatus: string = null;


	registerForm: FormGroup;
	signForm: FormGroup;
	submitted = false;
	signed = false;


	constructor(private configService: ConfigsService, private ref: DynamicDialogRef, private config: DynamicDialogConfig, public iriasConfig: IriasConfigService, private utilService: UtilService, private formBuilder: FormBuilder) {

		this.registration = {
			email: "",
			name: "",
			organisation: "",
			id: this.config.data.config_id,
			index: this.config.data.panel.index,
			lang: this.iriasConfig.getActiveLanguageAsString()
		};

		this.registerForm = this.formBuilder.group({
			name: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			organisation: ['']
		});

		this.signForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			secret: ['', Validators.required]
		});

	}

	ngOnInit(): void {
	}

	get r_controls() { return this.registerForm.controls; }
	get s_controls() { return this.signForm.controls; }


	submit() {
		this.submitted = true;

		if(this.registerForm.invalid) {
			return;
		}

		this.registration.email = this.r_controls.email.value;
		this.registration.name = this.r_controls.name.value;
		this.registration.organisation = this.r_controls.organisation.value;

		this.configService.registerUser(this.registration).then(registrationResult => {

			const credentials = {
				result: registrationResult,
				secret: null
			} as RegistrationCredentials;

			this.ref.close(credentials);
		});
	}

	sign() {
		this.signStatus = null;
		this.signed = true;

		if(this.signForm.invalid) {
			return;
		}

		const email = this.s_controls.email.value;
		const secret = this.s_controls.secret.value;

		this.configService.getRegistrationStatus(email, secret).then(result => {

			this.signStatus = result.status;

			if(result.status === 'confirmed') {
				localStorage.setItem('registration', email);
				localStorage.setItem('secret', secret);

				result.registration = {
					email: email
				} as UserRegistration;

				const credentials = {
					result: result,
					secret: secret
				} as RegistrationCredentials;

				this.ref.close(credentials);
			}

		})
	}
}
