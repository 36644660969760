<h2>1. Kies een bestand en upload.</h2>

<p-fileUpload
	name="importfile"
	url="/rest/upload"
	chooseLabel="Kies een bestand"
	uploadLabel="Start upload"
	cancelLabel="Annuleer bestand"
	[showCancelButton]="true"
	[previewWidth]="0"
	[headers]="headers"
	(onUpload)="onUpload($event)">
	<ng-template let-file pTemplate="file">
		<p-table [responsive]="true" [value]="[file]">
			<ng-template pTemplate="body" let-item>
				<tr>
					<td colspan="2" class="p-text-nowrap p-text-truncate">{{item.name}}</td>
					<td>{{format(item.size)}}</td>
					<td>
						<p-tag styleClass="p-text-uppercase" severity="success" [value]="getFileType(item.name) + '-file'"></p-tag>
					</td>
					<td colspan="2">
						<div class="p-d-flex p-justify-end">
							<p-tag styleClass="p-text-uppercase" icon="pi pi-check" severity="info" value="Klaar om te uploaden"></p-tag>
						</div>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</ng-template>
	<ng-template pTemplate="content">

	</ng-template>
</p-fileUpload>

<h2 class="p-mt-5">2. Bestanden worden gevalideerd.</h2>
<p-panel [showHeader]="false">
	<p-table [responsive]="true" [value]="validatingImports">
		<ng-template pTemplate="body" let-item>
			<tr>
				<td colspan="2" class="p-text-nowrap p-text-truncate">{{item.filename}}</td>
				<td>{{format(item.filesize)}}</td>
				<td colspan="3">
					<div class="p-d-flex p-justify-end p-ai-center">
						<p-tag styleClass="p-text-uppercase p-mr-2" icon="pi pi-cog" severity="warning" [value]="translateStatus(item.status)"></p-tag>

						<p-progressSpinner [style]="{width: '1rem', height: '1rem'}" strokeWidth="6" fill="#EEEEEE"
									   animationDuration=".5s"></p-progressSpinner>
					</div>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="6"><i>Er worden op dit moment geen bestanden gevalideerd.</i></td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>

<h2 class="p-mt-5">3. Gevalideerde bestanden die een vervolgactie nodig hebben.</h2>
<p-panel [showHeader]="false">
	<p-table [responsive]="true" [autoLayout]="true" [value]="pendingImports" sortField="processed_at" sortMode="single"
			 [sortOrder]="-1">
		<ng-template pTemplate="body" let-item>
			<tr>
				<td colspan="2" class="p-text-nowrap p-text-truncate">{{item.filename}}</td>
				<td>{{format(item.filesize)}}</td>
				<td>
					<span class="p-tag p-text-uppercase p-tag-success">{{translateStatus(item.status)}}</span>
				</td>
				<td>
					<div class="p-d-flex p-ai-center">
						<span class="p-overlay-badge p-mr-4" *ngIf="item.msg_count > 0">
							<button pButton pRipple label="Validatie-resultaat"
									class="p-button-sm p-button-warning"
									(click)="getDetail(item);"></button>
							<span class="p-badge p-badge-danger">{{item.msg_count}}</span>
						</span>
						<div class="p-mr-2" *ngIf="item.import_result?.non_uniques > 0">
							<button pButton pRipple class="p-button-outlined p-button-sm"
									[label]="'Overschrijf '+item.import_result.non_uniques+' duplicaten'"
									(click)="commitImport(item, true)"></button>
						</div>
						<div class="p-mr-2" *ngIf="item.import_result?.non_uniques > 0">
							<button pButton pRipple class="p-button-outlined p-button-sm"
									[label]="'Negeer '+item.import_result.non_uniques+ ' duplicaten'"
									(click)="commitImport(item, false)"></button>
						</div>
						<div class="p-mr-2" *ngIf="item.import_result?.non_uniques === 0">
							<button pButton pRipple class="p-button-outlined p-button-sm"
									[label]="'Importeer '+item.import_result.uniques+ ' rijen'"
									(click)="commitImport(item, false)"></button>
						</div>
						<div class="p-mr-2 p-ml-auto">
							<button pButton pRipple class="p-button-danger p-button-rounded p-button-sm" icon="fa fa-trash-alt"
									(click)="cancelImport(item)"></button>
						</div>
					</div>

				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="6"><i>Er zijn geen vervolgacties gevonden.</i></td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>

<h2 class="p-mt-5">4. Gevalideerde bestanden die momenteel worden verwerkt.</h2>
<p-panel [showHeader]="false">
	<p-table [responsive]="true"
			 [autoLayout]="true"
			 [value]="committingImports" sortField="processed_at" sortMode="single"
			 [sortOrder]="-1">
		<ng-template pTemplate="body" let-item>
			<td colspan="2" class="p-text-nowrap p-text-truncate">{{item.filename}}</td>
			<td>{{format(item.filesize)}}</td>
			<td colspan="3">
				<div class="p-d-flex p-justify-end p-ai-center">
					<p-tag styleClass="p-text-uppercase p-mr-2" icon="pi pi-cog" severity="success" [value]="translateStatus(item.status)"></p-tag>

					<p-progressSpinner [style]="{width: '1rem', height: '1rem'}" strokeWidth="6" fill="#EEEEEE"
									   animationDuration=".5s"></p-progressSpinner>
				</div>
			</td>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr *ngIf="finishedUploadedFile !== null">
				<td colspan="2" class="p-text-nowrap p-text-truncate">{{finishedUploadedFile.filename}}</td>
				<td>{{format(finishedUploadedFile.filesize)}}</td>
				<td colspan="3">
					<div class="p-d-flex p-justify-end">
						<p-tag styleClass="p-text-uppercase" icon="pi pi-check" severity="success" value="klaar"></p-tag>
					</div>
				</td>
			</tr>
			<tr>
				<td colspan="6"><i>Klaar. Er zijn geen gevalideerde bestanden die momenteel worden verwerkt of nog moeten worden verwerkt.</i></td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>