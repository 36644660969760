import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {global} from "../../globals";
import {ImportService} from "../services/data/import.service";
import {Filter, StoredConfigsWrapper} from "../types/sherpa";
import {ConfigsService} from "../services/data/configs.service";

@Injectable({
	providedIn: 'root'
})
export class StoredConfigsResolver implements Resolve<StoredConfigsWrapper> {

	constructor(private configsService: ConfigsService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<StoredConfigsWrapper> {
		global.enableLoader();
		return this.configsService.getStoredConfigs();
	}
}