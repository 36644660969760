import {Component, OnInit} from '@angular/core';
import {MapService} from "../../../services/map.service";
import {StyleService} from "../../../services/style.service";
import {MenuItem} from "primeng/api";
import {Globals} from "../../../classes/Globals";
import {Subscription} from "rxjs";
import {global} from "../../../../globals";
import {auth} from "../../../../services";
import {StoredConfig, StoredConfigsWrapper} from "../../../types/sherpa";
import _ from "lodash";
import {ConfigsService} from "../../../services/data/configs.service";
import {SessionService} from "../../../services/data/session.service";

@Component({
	selector: 'base-beheer',
	templateUrl: './beheer.component.html',
	styleUrls: ['./beheer.component.scss']
})
export class BeheerComponent implements OnInit {

	global: Globals = global;
	subscription: Subscription;

	accountItems: MenuItem[] = [];
	items: MenuItem[] = [];

	menuFetched: boolean = false;
	authenticated: boolean = false;

	constructor(private mapService: MapService, private styles: StyleService, private configsService: ConfigsService, private sessionService: SessionService) {

		this.subscription = auth.authSubject.subscribe(ok => {

			// no access
			if(!ok) {
				this.menuFetched = false;
				this.accountItems = [];
				this.items = [];
				return;
			}

			// already authenticated, no need to continue
			if(this.authenticated) {
				return;
			}

			this.authenticated = true;

			this.setMenu(); // defaults
			// this.getMenu(); // custom
		});

	}

	ngOnInit(): void {
	}

	getMenu() {
		this.menuFetched = false;

		this.configsService.getStoredConfigs().then((wrapper: StoredConfigsWrapper) => {

			_.forEach(wrapper.kpis, (item: StoredConfig) => {
				let menuItem = {
					label: item.label,
					routerLink: 'analyse/kpis',
					queryParams: {filter: _.kebabCase(item.label), id: item.stored_config_id}
				} as MenuItem;
				this.items.push(menuItem);
			});

			_.forEach(wrapper.myFilters, (item: StoredConfig) => {
				let menuItem = {
					label: item.label,
					routerLink: '/analyses',
					queryParams: {filter: _.kebabCase(item.label), id: item.stored_config_id}
				} as MenuItem;
				this.items.push(menuItem);
			});

			this.menuFetched = true;
		});
	}

	setMenu(): void {
		this.items = [] as MenuItem[];

		this.accountItems = [{
			label: 'Importeren',
			routerLink: '/beheer/imports'
		},{
			label: 'Geimporteerde data',
			routerLink: '/beheer/data'
		},{
			label: 'Account',
			expanded: false,
			styleClass: 'expandable',
			items: [
				{
					label: 'Wachtwoord wijzigen',
					command: event => auth.passwordChangeModal()
				}, {
					label: 'Uitloggen',
					command: event => this.logout()
				}]
		}] as MenuItem[];
	}

	logout() {
		return this.sessionService.logout('/beheer/imports')
			.then(() => {
				auth.clear(false);
			});
	}

	ngOnDestroy() {
		if(this.subscription) {
			this.subscription.unsubscribe();
		}
	}


}
