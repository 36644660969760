import {UserModel} from "./models/UserModel";
import {MenuItem} from "primeng/api";

export interface Globals {
	loader: AppLoader;
	loginUser: UserModel | null;
	disableLoader: Function;
	enableLoader: Function;
	requestedUrl: string;
	loginModal: boolean;
	menu: boolean;
	hideAll: boolean;
	menuItems: MenuItem[];
}

export enum AppLoader {
	PAGE = 'PAGE',
	NONE = 'NONE'
}