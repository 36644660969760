import {Component, Input, OnInit} from '@angular/core';
import {ImportService} from "../../../services/data/import.service";
import {AnalysePanel} from "../../../classes/models/AnalysePanel";
import _ from "lodash";
import {FilterField} from "../../../types/sherpa";
import ExtentInteraction, {ExtentEvent, Options} from "ol/interaction/Extent";
import {shiftKeyOnly} from "ol/events/condition";
import {Fill, Style} from "ol/style";
import {Extent} from "ol/extent";
import {MapService} from "../../../services/map.service";
import {Coordinate} from "ol/coordinate";
import {Point} from "ol/geom";
import {Map as OLMap} from "ol";
import VectorLayer from "ol/layer/Vector";
import Geometry from "ol/geom/Geometry";
import SimpleGeometry from "ol/geom/SimpleGeometry";
import {StyleFunction} from "ol/style/Style";
import Feature from "ol/Feature";
import {StyleService} from "../../../services/style.service";
import {LazyLoadEvent} from "primeng/api";

@Component({
	selector: 'base-incident-detail',
	templateUrl: './incident-detail.component.html',
	styleUrls: ['./incident-detail.component.scss']
})
export class IncidentDetailComponent implements OnInit {

	@Input() data_id: number;
	@Input() inputPanel: AnalysePanel;

	panel: AnalysePanel;
	rowdata: any = null;
	columns: any[] = [];
	loading: boolean = true;

	// openlayers
	map: OLMap;
	layer: VectorLayer;

	constructor(private incidentsService: ImportService, private mapService: MapService, private styles: StyleService) {

		this.columns = [
			{ field: 'data_id', header: '#ID' },
			{ field: 'unique_external_id', header: '#UID' },
			{ field: 'importsource', header: 'Bronbestand' },
			{ field: 'importsourcetype', header: 'Soort' },
			{ field: 'date', header: 'Datum' },
			{ field: 'som_kwh', header: 'Som kWh' },
			{ field: 'som_connectietijd', header: 'Som connectietijd' },
			{ field: 'aantal_elektrische_kilometers', header: 'Aantal elektrische kilometers' },
			{ field: 'co2_reduction', header: 'co2 reductie' },
			{ field: 'aantal_sessies', header: 'Aantal sessies' },
			{ field: 'aantal_unieke_rfids', header: 'Aantal unieke RFIDs' },
			{ field: 'aantal_palen', header: 'Aantal palen' },
			{ field: 'gemiddeld_aantal_sessies', header: 'Gemiddeld aantal sessies' },
			{ field: 'kwh_per_sessie', header: 'Kwh per sessie' },
			{ field: 'kwh_per_paal', header: 'Kwh per paal' },
			{ field: 'rfid_per_paal', header: 'RFID per paal' },
			{ field: 'kwh_per_rfid', header: 'Kwh per RFID' },
			{ field: 'aantal_laadpunten', header: 'Aantal laadpunten' },
		];

	}

	ngOnInit(): void {
		this.panel = _.cloneDeep(this.inputPanel);
		const filterField = {key: 'data_id', expectedValue: this.data_id} as FilterField;

		this.panel.andFilterFields.push(filterField);
		this.panel.includeFields = _.map(this.columns, 'field');

		this.loading = true;
	}

	loadData(event: LazyLoadEvent) {
		this.loading = true;
		this.incidentsService.getDataForTable(this.panel)
			.then(result => {
				this.rowdata = _.head(result);
			})
			.finally(() => {
				this.loading = false;
			});
	}


}
