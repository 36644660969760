import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {AnalysePanel, PanelType} from "../../../classes/models/AnalysePanel";
import {Chart, ChartDataset, ChartOptions} from "chart.js";
import _ from "lodash";
import {isArray} from "rxjs/internal-compatibility";
import {StaticDataService} from "../../../services/data/static-data.service";
import {Filter} from "../../../types/sherpa";
import {Subject, Subscription} from "rxjs";

@Component({
	selector: 'irias-chart',
	templateUrl: './chart.component.html',
	styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, AfterViewInit {

	@Input() panel: AnalysePanel;
	@Input() subject: Subject<ChartUpdate>

	chart: Chart = null;
	subscription: Subscription;

	constructor(private staticData: StaticDataService) {
	}

	ngOnInit(): void {
		this.subscription = this.subject.subscribe(next => {

			if(next.index === this.panel.index) {
				this.transformChart(next.toChart, next.stacked);
				this.setup();
			}

		});
	}

	ngAfterViewInit() {
		this.transformChart(this.panel.type, this.panel.stacked);
		this.setup();
	}

	setup() {
		if(this.chart) {
			this.chart.destroy();
		}

		const element = document.getElementById('p-' + this.panel.index) as HTMLCanvasElement;

		if (element !== null) {
			let type = this.panel.type;
			if(type === PanelType.horizontalBar) {
				//type = PanelType.bar;
			}

			this.chart = new Chart(element, {
				type: type as any,
				data: this.panel.resultData,
				options: this.panel.chartOptions,
				plugins: []
			});
		}
	}

	transformChart(toChart: PanelType, stacked: boolean = false) {
		this.setChartOptions(toChart, stacked);
		this.setChartData(toChart);

		this.reloadPanel();

		this.panel.type = toChart;
	}

	setChartOptions(type: PanelType, stacked: boolean) {
		this.panel.stacked = stacked;
		this.panel.chartOptions = null;

		if (!this.isChart(type)) {
			return;
		}

		let showLegend = this.showLegend(this.panel);
		let legendPosition = 'top';

		if (type === PanelType.pie || type == PanelType.radar) {
			showLegend = true; // always use legend in pie charts
			legendPosition = 'left'; // always use legend in pie charts
		}

		// all chart types
		const options = {
			//indexAxis: this.panel.type === PanelType.horizontalBar ? 'y' : 'x',
			responsive: true,
			maintainAspectRatio: true,
			aspectRatio: 16/9,
			interaction: {
				intersect: true,
				mode: 'point',
			},
			legend: {
				display: showLegend,
				position: legendPosition,
				align: 'start',
				labels: {
					boxWidth: 14,
					fontSize: 14,
					fontStyle: '300',
					fontFamily: 'MuseoSans',
					fontColor: '#000'
				}
			},
			animation: {
				duration: 0
			},
			scales: {}
		} as ChartOptions;


		// custom additions
		if (type === PanelType.bar || type === PanelType.horizontalBar || type === PanelType.line) {
			options.scales = {
				y: {
					beginAtZero: true,
					stacked: stacked,
					position: 'left',
					ticks: {
						padding: 5
					}
				},
				x: {
					beginAtZero: true,
					stacked: stacked,
					ticks: {}
				}
			};
		}

		this.panel.chartOptions = options;
	}

	setChartData(type: PanelType) {
		switch (type) {
			case PanelType.bar:
			case PanelType.horizontalBar:
				this.panel.resultData = this.asBarChartData(this.panel.orgResultData);
				break;
			case PanelType.line:
				this.panel.resultData = this.asLineChartData(this.panel.orgResultData);
				break;
			case PanelType.pie:
				this.panel.resultData = this.asPieChartData(this.panel.orgResultData);
				break;
			case PanelType.radar:
				this.panel.resultData = this.asRadarChartData(this.panel.orgResultData, this.panel.groupBy?.length > 1);
				break;
			default:
				this.panel.resultData = _.cloneDeep(this.panel.orgResultData);
		}
	}


	/* transform chart */

	asLineChartData(chartData: any) {
		if (chartData === null) {
			return chartData;
		}

		let clone = _.cloneDeep(chartData);

		_.forEach(clone.datasets, (dataset: ChartDataset) => {

			// dataset.showLine = true;
			// dataset.fill = false;

			if (isArray(dataset.backgroundColor)) {
				dataset.backgroundColor = dataset.backgroundColor[0];
			}
			dataset.borderColor = dataset.backgroundColor;

			// hacky
			if (dataset.label === 'Trend') {
				// dataset.fill = true;
				dataset.backgroundColor = 'rgba(0,0,0,0)';
			}
		});

		return clone;
	}

	asPieChartData(chartData: any) {

		if (chartData === null) {
			return chartData;
		}

		let clone = _.cloneDeep(chartData);
		let colors = this.staticData.getColorPalette();

		_.remove(clone.datasets, (dataset: ChartDataset) => {
			return dataset.label === 'Trend';
		});

		_.forEach(clone.datasets, (dataset: ChartDataset) => {

			if (!isArray(dataset.backgroundColor)) {
				dataset.backgroundColor = colors;
			} else {
				// check if all colors are equal
				if (_.uniq(dataset.backgroundColor).length === 1) {
					dataset.backgroundColor = colors;
				}
			}

			dataset.borderColor = '#f9f9f9';
			// dataset.datalabels = {
			// 	color: '#5a5858',
			// 	display: context => {
			// 		return this.panels[index].show_labels ? 'auto' : false;
			// 	}
			// }
		});

		return clone;
	}

	asRadarChartData(chartData: any, multipleGroups: boolean) {

		if (chartData === null) {
			return chartData;
		}

		let clone = _.cloneDeep(chartData);

		_.remove(clone.datasets, (dataset: ChartDataset) => {
			return dataset.label === 'Trend';
		});

		_.forEach(clone.datasets, (dataset: ChartDataset) => {
			// dataset.showLine = false;
			// dataset.fill = false;
			if (isArray(dataset.backgroundColor)) {
				dataset.borderColor = dataset.backgroundColor[0];
				dataset.backgroundColor = dataset.backgroundColor[0];
			}

			// dataset.datalabels = {
			// 	color: '#9f9f9f',
			// 	display: context => {
			// 		return this.panels[index].show_labels ? 'auto' : false;
			// 	}
			// }
		});

		return clone;
	}

	asBarChartData(chartData: any) {

		if (chartData === null) {
			return chartData;
		}

		let clone = _.cloneDeep(chartData);

		_.forEach(clone.datasets, (dataset: ChartDataset) => {
			// dataset.fill = true;

			if (dataset.label !== 'Trend') {
				// dataset.showLine = false;
				dataset.borderColor = '#9f9f9f';
				// dataset.datalabels = {
				// 	color: '#1d1d1d',
				// 	display: context => {
				// 		const value = context.dataset.data[context.dataIndex];
				//
				// 		if(value === null || value === 0) {
				// 			return false;
				// 		}
				//
				// 		return this.panels[index].show_labels ? 'auto' : false;
				// 	},
				// 	anchor: 'end',
				// 	align: 'end',
				// 	offset: 0,
				// 	clamp: true
				// }
			} else {
				dataset.backgroundColor = 'rgba(0,0,0,0)';
			}
		});

		return clone;
	}


	showLegend(p: AnalysePanel) {

		let result: boolean = false;
		if (p.groupBy?.length > 1) {
			result = true;
		} else if (p.resultData !== null) {
			let trend = _.find(p.resultData.datasets, (ds => {
				return ds.label === 'Trend';
			}));
			if (trend !== undefined && trend !== null) {
				result = true;
			}
		} else {
			result = p.show_legend === true;
		}

		if (p.type === PanelType.pie) {
			result = true;
		}

		return result;
	}

	isChart(type: PanelType) {
		const types = [
			PanelType.bar,
			PanelType.horizontalBar,
			PanelType.line,
			PanelType.pie,
			PanelType.radar
		];

		return _.includes(types, type);
	}

	reloadPanel() {
		if(this.chart === null) {
			return;
		}

		this.chart.data = this.panel.resultData;
		this.chart.update();
	}

	ngOnDestroy() {
		this.chart.destroy();
		this.subscription.unsubscribe();
	}
}

export declare class ChartUpdate {
	index: number;
	toChart: PanelType;
	stacked: boolean;
}
