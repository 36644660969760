<p-toast></p-toast>

<p-card header="Importeren" styleClass="shadow-mrae">

	<p>Kies een bestand dat voldoet aan de richtlijnen.</p>

	<p-tabView>
		<p-tabPanel header="Uploaden bestand">
			<base-import-tab [imports$]="imports$"></base-import-tab>
		</p-tabPanel>
		<p-tabPanel header="Archief">
			<base-archive-tab [imports$]="imports$"></base-archive-tab>
		</p-tabPanel>
		<p-tabPanel header="Documentatie">
			<base-docs-tab></base-docs-tab>
		</p-tabPanel>
	</p-tabView>

</p-card>

