import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Globals} from "./classes/Globals";
import {global} from 'src/globals';
import {MenuItem, MessageService, PrimeNGConfig, Translation} from 'primeng/api';
import _ from "lodash";
import {ConfigsService} from "./services/data/configs.service";
import {Subscription} from "rxjs";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	global: Globals = global;
	subscription: Subscription;


	constructor(private config: PrimeNGConfig) {

	}

	ngOnInit() {
		this.config.ripple = true;
	}

}
