import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {SherpaResponse} from "../../../../classes/SherpaResponse";
import {auth, util} from "../../../../../services";
import {SourceView, SourceViewWithMessages} from "../../../../types/sherpa";
import {HttpHeaders} from "@angular/common/http";
import {ImportService} from "../../../../services/data/import.service";
import _ from "lodash";
import {MenuItem, MessageService} from "primeng/api";
import {DialogService} from "primeng/dynamicdialog";
import {ImportMessagesModalComponent} from "../import-messages-modal/import-messages-modal.component";

@Component({
	selector: 'base-import-tab',
	templateUrl: './import-tab.component.html',
	styleUrls: ['./import-tab.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ImportTabComponent implements OnInit {

	@Input()
	imports$: Promise<SourceView[]>;

	items: MenuItem[] = [];
	uploadedFiles: any[] = [];
	headers: HttpHeaders;
	selectedDetails: SourceViewWithMessages = null;
	timers: Map<number, SourceView> = new Map<number, SourceView>();

	imports: SourceView[] = [];
	validatingImports: SourceView[];
	pendingImports: SourceView[];
	committingImports: SourceView[];
	committedImports: SourceView[];
	finishedUploadedFile: SourceView = null;
	private upladedFile: SourceView = null;

	constructor(private messageService: MessageService, private incidentsService: ImportService, private dialogService: DialogService) {
	}

	ngOnInit(): void {
		this.headers = new HttpHeaders({
			Authorization: 'Bearer ' + auth.token()
		});

		this.imports$.then((result: SourceView[]) => {
			this.imports = result;
			this.initLists();
			_.forEach(this.validatingImports, (sv : SourceView) => {
				//this.startTimer(sv.importsource_id);
			});
			_.forEach(this.committingImports, (sv : SourceView) => {
				//this.startTimer(sv.importsource_id);
			});
		});
	}

	initLists() {
		this.pendingImports = _.filter(this.imports, ['status', 'pending']);
		this.validatingImports = _.filter(this.imports, ['status', 'validating']);
		this.committingImports = _.filter(this.imports, ['status', 'committing']);
		this.committedImports = _.filter(this.imports, ['status', 'committed']);

		if(this.upladedFile !== null) {
			let f = _.find(this.committedImports, (sv: SourceView) => {
				return sv.importsource_id === this.upladedFile.importsource_id;
			});
			if(f !== null && f !== undefined){
				this.finishedUploadedFile = f;
			}
		}
	}

	onUpload(event: any) {
		let response = event.originalEvent.body as SherpaResponse;

		if (response.error !== null) {
			util.alert(response.error);
			return;
		}

		const sourceView = response.result as SourceView;

		// hacky to insert at index=0
		this.validatingImports = _.concat([sourceView], this.validatingImports);

		this.startTimer(sourceView.importsource_id);
		this.upladedFile = sourceView;
	}

	startTimer(importsource_id: number) {

		let sourceView = _.find(this.validatingImports, ['importsource_id', importsource_id]);

		if (sourceView === undefined) {
			sourceView = _.find(this.committingImports, ['importsource_id', importsource_id]);
			if (sourceView === undefined) {
				return;
			}
		}

		if (sourceView.status !== 'validating' && sourceView.status !== 'committing') {
			return;
		}

		_.delay(() => {
			this.getDetail(sourceView, false);
			this.startTimer(importsource_id);
		}, 2500);
	}

	getDetail(sourceView: SourceView, selectionMode: boolean = true) {
		this.incidentsService.getSourceMessages(sourceView.importsource_id)
			.then((result: SourceViewWithMessages) => {
				this.imports = util.upsert(this.imports, result.source, 'importsource_id');
				this.initLists();

				if (selectionMode) {
					this.openModal(result);
				}
			});
	}

	getFileType(filename: string) {
		if(_.includes(filename.toLowerCase(), 'year')) {
			return 'yearly';
		}

		return 'monthly';
	}

	openModal(viewWithMessages: SourceViewWithMessages) {
		let ref = this.dialogService.open(ImportMessagesModalComponent, {
			width: '70%',
			showHeader: true,
			modal: true,
			header: viewWithMessages.source.filename,
			data: {
				messages: viewWithMessages.messages,
				sourceView: viewWithMessages.source,
				showActionButtons: true
			}
		});

		ref.onDestroy.subscribe((success: boolean) => {
			// console.log('destroyed');
		});

		ref.onClose.subscribe((sourceView: SourceView) => {
			if(_.isUndefined(sourceView)) {
				return; // abort action
			}

			this.imports = util.remove(this.imports, sourceView.importsource_id, 'importsource_id');
			this.initLists();
		});
	}

	cancelImport(source: SourceView) {
		this.incidentsService.cancelImport(source.importsource_id)
			.then((result) => {
				this.imports = util.remove(this.imports, source.importsource_id, 'importsource_id');
				this.initLists();
			});
	}

	commitImport(source: SourceView, overwrite_non_uniques: boolean) {
		this.incidentsService.commitImport(source.importsource_id, overwrite_non_uniques)
			.then((result) => {
				source.status = 'committing';
				this.initLists();

				this.startTimer(source.importsource_id);
			});
	}

	format(size: number): string {
		return util.formatBytes(size);
	}

	translateStatus(status : string){
		switch (status) {
			case 'pending' : return 'Wacht op actie gebruiker';
			case 'committing' : return 'Bezig met verwerken';
			case 'validating' : return 'Bezig met valideren';
			case 'committed' : return 'Geimporteerd';
			default: return status;
		}
	}
}
