import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {StyleService} from "../../../services/style.service";
import _ from "lodash";
import {ImportService} from "../../../services/data/import.service";
import {
	Filter,
	FilterField,
	GroupOption,
	MultiChartDataWithConfig,
	RegistrationResult,
	StoredConfig,
	StoredConfigsWrapper
} from "../../../types/sherpa";
import {MenuItem} from "primeng/api";
import {Globals} from "../../../classes/Globals";
import {global} from "../../../../globals";
import {ConfigsService} from "../../../services/data/configs.service";
import {ActivatedRoute, Router} from "@angular/router";
import {BehaviorSubject, Subscription} from "rxjs";
import {AnalysePanel, PanelType} from "../../../classes/models/AnalysePanel";
import {StaticDataService} from "../../../services/data/static-data.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {DialogService} from "primeng/dynamicdialog";
import marked from "marked";
import {isArray} from "rxjs/internal-compatibility";
import {Location} from "@angular/common";
import {UtilService} from "../../../services/util.service";
import {Chart, ChartDataset, ChartOptions, LegendItem, Plugin, registerables} from 'chart.js';
import {IriasConfigService} from "../../../services/irias-config.service";

Chart.register(...registerables);


@Component({
	selector: 'mrae-panels',
	templateUrl: './panels.component.html',
	styleUrls: ['./panels.component.scss']
})
export class PanelsComponent implements OnInit {

	global: Globals = global;
	previousMenu: boolean = global.menu;

	// subscriptions
	navigationSubscription: Subscription;
	queryParamSubscription: Subscription;
	langSubscription: Subscription;

	// subject for broadcasting to other components like sidebar + filters
	panelSubject: BehaviorSubject<AnalysePanel> = new BehaviorSubject<AnalysePanel>(null);

	// page resolvers
	storedConfigs: StoredConfigsWrapper;

	// data
	filters: Filter[];
	panels: AnalysePanel[] = [];

	groupByOptions$: Promise<GroupOption[]>;
	selectedStoredConfig: StoredConfig = null; // bookmarks table-item
	storedConfig: StoredConfig = null;
	myStoredConfigs: StoredConfig[] = [];

	// helpers
	stored_config_id: number;
	stored_config_temp_id: number;
	submitted: boolean = true;
	filterPathClean: string = null;
	filterPath: string = 'analyse';
	limits: any[];

	// menu
	items: MenuItem[] = [];

	// register
	showDownloadDialog: boolean = false;
	downloadUrl: string = null;
	secret: string;
	registrationResult: RegistrationResult = null;

	htmlLegendPlugin: Plugin;
	legendState = new Map<number, boolean>(); // used after filtering to keep last legend selection
	charts: Chart[] = [];
	fetched = false;
	refreshMenu = false;

	constructor(private cd: ChangeDetectorRef, public iriasConfig: IriasConfigService, private route: ActivatedRoute, private router: Router, public styles: StyleService, public importService: ImportService, public configsService: ConfigsService, public staticData: StaticDataService, public dialogService: DialogService, private readonly http: HttpClient, private location: Location, private utilService: UtilService) {
	}

	ngOnInit() {
		this.filters = this.route.snapshot.data['filters'] as Filter[];
		this.storedConfigs = this.route.snapshot.data['storedConfigs'] as StoredConfigsWrapper;
		this.limits = this.route.snapshot.data['limits'];

		this.myStoredConfigs = this.storedConfigs.myFilters;
		this.groupByOptions$ = this.importService.getGroupOptions();

		this.watch();
	}

	private watch() {

		// watch language
		this.langSubscription = this.iriasConfig.language.subscribe(next_lang => {
			// reset menu
			this.setKPIsMenu(this.storedConfigs.kpis);
		});

		// keep track of the queryParamMap to fetch new panels
		this.queryParamSubscription = this.route.queryParamMap.subscribe(params => {

			/* STORE PATH */
			const isAnalyse = params.has('filter');
			this.filterPath = isAnalyse ? params.get('filter') : 'analyse';
			this.filterPathClean = isAnalyse ? _.replace(this.filterPath, new RegExp('-', 'g'), ' ') : null;
			const new_config_id = +params.get('id'); // the + converts a string to a number

			// no need to fetch
			if (this.stored_config_id == new_config_id) {
				return;
			}

			/* INIT */
			this.stored_config_id = new_config_id;
			this.stored_config_temp_id = +params.get('tmp_id');

			this.init();

			if (this.items.length == 0) {
				this.setKPIsMenu(this.storedConfigs.kpis);
			}
		});
	}

	private init() {

		// 0 means -> not set in url
		if (this.stored_config_id === 0 && this.stored_config_temp_id === 0) {
			return;
		}

		const is_temp = this.stored_config_temp_id > 0;
		const fetch_id = is_temp ? this.stored_config_temp_id : this.stored_config_id;

		this.fetched = false;
		this.configsService.getPanelsForConfigId(fetch_id).then(wrapper => {
			this.storedConfig = _.find(this.myStoredConfigs, ['stored_config_id', this.stored_config_id]) || null;
			this.fetched = true;

			this.preparePanels(wrapper);
		});
	}

	private setKPIsMenu(kpis: StoredConfig[]) {
		this.items = [];
		const menuItems: MenuItem[] = [];

		_.forEach(kpis, item => {
			const label = this.getLabel(item);
			const iconPath = this.getIcon(item.label);

			const menuItem = {
				label: `<img src="${iconPath}" width="24px" /> ${label}`,
				escape: false,
				routerLink: '/data/config',
				queryParams: {
					id: item.stored_config_id,
					filter: _.kebabCase(item.label),
					lang: this.iriasConfig.getActiveLanguage() == 1 ? 'en' : 'nl'
				}
			} as MenuItem;

			menuItems.push(menuItem);
		});

		this.items = menuItems;
	}

	getLabel(config: StoredConfig) {

		if (_.includes(config.label.toLowerCase(), 'trend')) {
			return this.iriasConfig.getTranslation('reports.trends_menu');
		} else if (_.includes(config.label.toLowerCase(), 'users')) {
			return this.iriasConfig.getTranslation('reports.users_per_station_menu');
		} else if (_.includes(config.label.toLowerCase(), 'stations')) {
			return this.iriasConfig.getTranslation('reports.charging_stations_menu');
		} else if (_.includes(config.label.toLowerCase(), 'sessions')) {
			return this.iriasConfig.getTranslation('reports.charging_sessions_menu');
		} else if (_.includes(config.label.toLowerCase(), 'per station')) {
			return this.iriasConfig.getTranslation('reports.charged_per_station_menu');
		} else if (_.includes(config.label.toLowerCase(), 'per session')) {
			return this.iriasConfig.getTranslation('reports.charged_per_session_menu');
		} else if (_.includes(config.label.toLowerCase(), 'total charged')) {
			return this.iriasConfig.getTranslation('reports.charged_total_menu');
		} else if (_.includes(config.label.toLowerCase(), 'per rfid')) {
			return this.iriasConfig.getTranslation('reports.charged_per_rfid_menu');
		} else if (_.includes(config.label.toLowerCase(), 'rfid')) {
			return this.iriasConfig.getTranslation('reports.unique_rfid_menu');
		}

		return config.label;
	}

	getIcon(str: string) {

		let path = '/assets/img/';
		str = str.toLowerCase();

		if (_.includes(str, 'rfid') && _.includes(str, 'unique')) {
			return path + 'unieke_gebruikers.png';
		} else if (_.includes(str, 'rfid')) {
			return path + 'kwh_per_RFIDs.png';
		} else if (_.includes(str, 'user')) {
			return path + 'aantal_unieke_RFIDs.png';
		} else if (_.includes(str, 'station')) {
			return path + 'aantal_oplaad_stations.png';
		} else if (_.includes(str, 'per session')) {
			return path + 'kwh_per_sessies.png';
		} else if (_.includes(str, 'session')) {
			return path + 'aantal_oplaad_sessies.png';
		} else if (_.includes(str, 'kwh')) {
			return path + 'totaal_aantal_kwh.png';
		}

		return path + 'aantal_oplaad_sessies.png';
	}

	getCaptionIcon(caption_key: string) {

		const path = '/assets/img/';
		let icon = 'new_uniek_RFID.png'; // default
		caption_key = caption_key.toLowerCase();

		if (_.includes(['charging_sessions', 'charged_per_session'], caption_key)) {
			icon = 'new_kwh_per_sessies.png';
		} else if (_.includes(['charged_per_station', 'charging_stations'], caption_key)) {
			icon = 'new_oplaadstation.png';
		} else if (caption_key == 'unique_rfid') {
			icon = 'new_uniek_actief.png';
		} else if (caption_key == 'charged_total') {
			icon = 'new_totaal_aantal_kwh.png';
		} else if (caption_key == 'charged_per_rfid') {
			icon = 'new_kwh_per_RFIDs.png';
		} else if (caption_key == 'users_per_station') {
			icon = 'new_uniek_RFID.png';
		}

		return path + icon;
	}

	preparePanels(wrapper: MultiChartDataWithConfig) {
		const panels: AnalysePanel[] = [];
		const indices = _.range(wrapper.config.length);
		this.legendState.clear();

		_.forEach(indices, i => {
			const visible = wrapper.config[i] !== null;

			// extend new AnalysePanel with config
			const panel = _.assignIn(new AnalysePanel(visible, i), wrapper.config[i]);
			panel.resultData = wrapper.chartData[i];
			panel.type = panel.type === null ? PanelType.bar : panel.type; // default to bar

			panels.push(panel);
		});

		this.initPanels(panels);
	}

	initPanels(panels: AnalysePanel[]) {

		_.forEach(panels, panel => {
			panel.filters = _.cloneDeep(this.filters);
			panel.orgResultData = _.cloneDeep(panel.resultData);

			if (panel.groupByField?.key === 'date_year') {
				panel.kind = 'yearly';
			} else if (panel.groupByField?.key === 'date_year_month') {
				panel.kind = 'monthly';
			}
		});

		// better to destroy first when old instances exist
		_.forEach(this.panels, oldPanel => {
			if (oldPanel.chartInstance) {
				oldPanel.chartInstance.destroy();
			}
		});

		this.panels = panels;

		const _createChart = (panel: AnalysePanel) => {
			_.delay(() => {
				const canvas = document.getElementById('p-' + panel.index) as HTMLCanvasElement;

				// no panel
				if (canvas !== null) {
					panel.chartInstance = new Chart(canvas, {
						type: panel.type as any,
						data: panel.resultData,
						options: panel.chartOptions,
						plugins: panel.index === 0 ? [this.htmlLegendPlugin] : [] // just 1 legend
					});
				}
			}, 0);
		};

		_.forEach(this.panels, panel => {
			this.transformChart(panel.index, panel.type, panel.stacked);
			_createChart(panel);
		});
	}

	transformChart(index: number, toChart: PanelType, stacked: boolean = false) {

		this.setChartOptions(index, toChart, stacked);
		this.setChartData(index, toChart);

		this.reloadPanel(index);

		this.panels[index].type = toChart;
	}

	reloadPanel(index: number) {
		// get chart instance
		const chart = Chart.getChart('p-' + index);

		if (chart) {
			const panel = this.panels[index];
			chart.data = panel.resultData;

			this.legendState.forEach((value, datasetIndex) => {
				chart.setDatasetVisibility(datasetIndex, value);
			});

			chart.update();
		}
	}

	setChartOptions(index: number, type: PanelType, stacked: boolean) {
		this.panels[index].stacked = stacked;
		this.panels[index].chartOptions = null;

		if (!this.isChart(type)) {
			return;
		}

		let options = {
			responsive: true,
			maintainAspectRatio: true,
			aspectRatio: 5 / 4,
			interaction: {
				intersect: true,
				mode: 'point',
			},
			plugins: {
				htmlLegend: {
					containerID: 'legend-container'
				},
				tooltip: {
					enabled: false, // we use a custom
					position: "nearest",
					external: this.externalTooltipHandler.bind(this)
				},
				legend: {
					display: false // we use a custom
				}
			},
			animation: {
				duration: 0
			},
			scales: {}
		} as ChartOptions;

		// custom additions
		if (type === PanelType.bar || type === PanelType.line) {
			options.scales = {
				y: {
					beginAtZero: true,
					stacked: stacked,
					grid: {
						drawBorder: true,
						borderColor: '#245BB9',
						borderWidth: 1,
						display: true,
						color: '#DFE6F6'
					},
					ticks: {
						// Include a 'K' sign in the ticks
						callback: (value, index, values) => {

							if ((value as number) < 1000) {
								return value;
							}

							if ((value as number) < 1000000) {
								return value as number / 1000 + 'K';
							}

							return value as number / 1000000 + 'M';
						},
						color: '#245BB9',
						font: {
							size: 14,
							weight: '500'
						},
						padding: 8
					}
				},
				x: {
					beginAtZero: true,
					grid: {
						drawBorder: true,
						borderColor: '#245BB9',
						borderWidth: 1,
						display: false
					},
					ticks: {
						color: '#245BB9',
						font: {
							size: 14,
							weight: '500'
						},
						padding: 8
					}
				}
			};
		}

		this.panels[index].chartOptions = options;
		this.setLegendPlugin();
	}

	setChartData(index: number, type: PanelType) {
		const originalData = this.panels[index].orgResultData;

		if (originalData === null) {
			this.panels[index].resultData = null;
			return;
		}

		switch (type) {
			case PanelType.bar:
				this.panels[index].resultData = this.asBarChartData(originalData, index);
				break;
			case PanelType.line:
				this.panels[index].resultData = this.asLineChartData(originalData);
				break;
			default:
				this.panels[index].resultData = _.cloneDeep(this.panels[index].orgResultData);
		}
	}

	asLineChartData(chartData: any) {
		const clone = _.cloneDeep(chartData);

		_.forEach(clone.datasets, (dataset: ChartDataset) => {

			if (isArray(dataset.backgroundColor)) {
				dataset.backgroundColor = dataset.backgroundColor[0];
			}

			dataset.borderColor = dataset.backgroundColor;
		});

		return clone;
	}

	asBarChartData(chartData: any, index: number) {
		const clone = _.cloneDeep(chartData);

		_.forEach(clone.datasets, (dataset: ChartDataset) => {

			if (this.panels[index].stacked) {
				dataset.stack = 'locations';

				if (dataset.label === 'Alle') {
					dataset.stack = 'all';
				}
			}
		});

		return clone;
	}

	isChart(type: PanelType) {
		const types = [PanelType.bar, PanelType.line];
		return _.includes(types, type);
	}

	getMostRecentYearFilterField(): FilterField[] {
		let dateFilter = _.find(this.filters, ['key', 'date']);
		if (!dateFilter) {
			return [];
		}

		const latestDateFilter = _
			.chain(dateFilter.values)
			.orderBy('label', 'desc')
			.head()
			.value();

		const valuesOnly = _.map(latestDateFilter.value, 'value');

		return [{key: 'date', expectedValue: valuesOnly}] as FilterField[];
	}


	/* EVENT */

	panelUpdated(panel: AnalysePanel) {
		this.panels[panel.index] = panel;

		// get new report-datasets
		this.fetchPanelData(panel);
	}


	/* FETCH DATA */

	fetchPanelData(panel: AnalysePanel) {
		const i = panel.index;

		this.importService.getResultForPanel(panel).then(result => {
			this.submitted = true;

			// reset the data
			this.panels[i].resultData = result;
			this.panels[i].orgResultData = _.cloneDeep(result);

			// make sure the chartsData and chartOptions are correct for this type
			this.transformChart(i, panel.type, panel.stacked);
		});
	}


	/* REGISTER + DOWNLOAD */

	asMarkdown(txt: string) {
		return marked(txt);
	}

	instantDownload(panel: AnalysePanel) {

		const baseUrl = `/rest/download/${panel.analyse_type}`;
		const headers = new HttpHeaders();

		this.http.get(baseUrl, {headers, responseType: 'blob' as 'json'}).subscribe(
			(response: any) => {
				let dataType = 'application/csv';
				let binaryData = [];
				binaryData.push(response);

				let downloadLink = document.createElement('a');
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute('download', "evdata.csv");

				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
	}

	/* CHART CUSTOM ELEMENTS */

	setLegendPlugin() {
		const _getOrCreateLegendList = this.getOrCreateLegendList;
		const _panels = this.panels;
		const that = this;

		this.htmlLegendPlugin = {
			id: 'htmlLegend',
			afterUpdate(chart: Chart, args: any, options: any) {

				const ul = _getOrCreateLegendList(chart, options.containerID);
				while (ul.firstChild) {
					ul.firstChild.remove(); // Remove old legend items
				}

				// Reuse the built-in legendItems generator
				const items: LegendItem[] = chart.options.plugins.legend.labels.generateLabels(chart);

				items.forEach(item => {
					const li = document.createElement('li');
					li.className = 'legend-item';
					li.onclick = () => {

						const currentVisible = chart.isDatasetVisible(item.datasetIndex);
						const visibility = !currentVisible; // toggle

						that.legendState.set(item.datasetIndex, visibility);

						_.forEach(_panels, panel => {
							if (panel.chartInstance === null) {
								return;
							}

							panel.chartInstance.setDatasetVisibility(item.datasetIndex, visibility);
							panel.chartInstance.update();
						});
					};

					// Checkbox
					const cb = document.createElement('span');
					cb.className = 'legend-checkbox';

					let visibility = true;
					if (that.legendState.has(item.datasetIndex)) {
						visibility = that.legendState.get(item.datasetIndex);
					}

					if (!item.hidden && visibility) {
						const icon = document.createElement('i');
						icon.classList.add('fas', 'fa-check');
						cb.appendChild(icon);
					}

					// Color
					const dot = document.createElement('span');
					dot.className = 'legend-color';
					dot.style.background = (item.fillStyle || 'black') as string;

					// Text
					const label = document.createElement('span');
					label.className = 'legend-label';
					label.innerText = item.text;


					if (item.text.toLowerCase() == 'mra-e') {
						label.style.borderBottom = '1px dashed';
						label.title = that.iriasConfig.getTranslation('panels.legend_mrae').toString();
					} else if (item.text.toLowerCase() == 'sgzh') {
						label.style.borderBottom = '1px dashed';
						label.title = that.iriasConfig.getTranslation('panels.legend_sgzh').toString();
					}


					li.appendChild(cb);
					li.appendChild(dot);
					li.appendChild(label);

					ul.appendChild(li);
				});
			}
		} as Plugin
	}

	externalTooltipHandler(context: any) {
		// Tooltip Element
		const {chart, tooltip} = context;
		const tooltipEl = this.getOrCreateTooltip(chart);

		// Hide if no tooltip
		if (tooltip.opacity === 0) {
			tooltipEl.style.opacity = '0';
			return;
		}

		const table = document.createElement('table');
		table.className = 'chart-tooltip-table';

		// Set body
		if (tooltip.body) {
			const titleLines: string[] = tooltip.title || [];
			const bodyLines = tooltip.body.map((b: any) => b.lines);
			const tr = document.createElement('tr');
			const td = document.createElement('td');

			titleLines.forEach(title => {
				const tr1 = tr.cloneNode();
				const td1 = td.cloneNode();
				const td2 = td.cloneNode();

				td1.textContent = this.iriasConfig.getTranslation('panels.tooltip_period') + ':'
				td2.textContent = title;

				table.appendChild(tr1);
				tr1.appendChild(td1);
				tr1.appendChild(td2);
			});

			bodyLines.forEach((body: string) => {
				const tr1 = tr.cloneNode();
				const tr2 = tr.cloneNode();
				const td1 = td.cloneNode();
				const td2 = td.cloneNode();
				const td3 = td.cloneNode();
				const td4 = td.cloneNode();

				const values = _.split(body, ':');
				td1.textContent = this.iriasConfig.getTranslation('panels.tooltip_location') + ':';
				td2.textContent = values[0];
				td3.textContent = this.iriasConfig.getTranslation('panels.tooltip_value') + ':';
				td4.textContent = values[1];

				table.appendChild(tr1);
				table.appendChild(tr2);

				tr1.appendChild(td1);
				tr1.appendChild(td2);
				tr2.appendChild(td3);
				tr2.appendChild(td4);
			});

			const container = tooltipEl.querySelector('.chart-tooltip-container');

			// Remove old children
			while (container.firstChild) {
				container.firstChild.remove();
			}

			// Add new children
			container.appendChild(table);
		}

		const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

		// Display, position, and set styles for font
		tooltipEl.style.opacity = '1';
		tooltipEl.style.left = positionX + tooltip.caretX + 'px';
		tooltipEl.style.top = positionY + tooltip.caretY + 'px';
		tooltipEl.style.font = tooltip.options.bodyFont.string;
		tooltipEl.style.padding = tooltip.padding + 'px ' + tooltip.padding + 'px';
	};

	getOrCreateLegendList(chart: Chart, id: any) {
		const legendContainer = document.getElementById(id);
		let listContainer = legendContainer.querySelector('ul');

		if (!listContainer) {
			listContainer = document.createElement('ul');
			listContainer.classList.add('p-d-flex', 'p-flex-column', 'p-m-0', 'p-p-0');

			legendContainer.appendChild(listContainer);
		}

		return listContainer;
	};

	getOrCreateTooltip(chart: Chart) {
		let tooltipEl = chart.canvas.parentNode.querySelector('div');

		if (!tooltipEl) {
			tooltipEl = document.createElement('div');
			tooltipEl.className = 'chart-tooltip';

			const container = document.createElement('div');
			container.className = 'chart-tooltip-container';

			tooltipEl.appendChild(container);
			chart.canvas.parentNode.appendChild(tooltipEl);
		}

		return tooltipEl;
	}


	/* unsubscribe when changing pages */
	ngOnDestroy() {
		this.panelSubject.unsubscribe();

		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
		if (this.queryParamSubscription) {
			this.queryParamSubscription.unsubscribe();
		}
		if (this.langSubscription) {
			this.langSubscription.unsubscribe();
		}
	}
}
