import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Filter, FilterField} from "../../../types/sherpa";
import {AnalysePanel} from "../../../classes/models/AnalysePanel";
import _ from "lodash";

@Component({
	selector: 'base-edit-filter-multiple',
	templateUrl: './edit-filter-multiple.component.html',
	styleUrls: ['./edit-filter-multiple.component.scss']
})
export class EditFilterMultipleComponent implements OnInit {

	lodash = _;

	@Input() filter: Filter;
	@Input() panel: AnalysePanel;

	@Output() onFilterFieldChanged: EventEmitter<FilterField> = new EventEmitter<FilterField>();

	collapsed: boolean = true;
	fieldLabel: string = null;
	searchContains: boolean = true;
	searchTerm: string = '';

	selectedFilterValues: any[] = [];
	asTree: boolean = false;
	refreshed: boolean = true;

	// key=date
	dateMinMax: boolean = false;
	fromDate: Date = null;
	toDate: Date = null;

	constructor(private cd: ChangeDetectorRef) {
	}

	ngOnInit(): void {
		this.asTree = this.filter.values.length && _.isArray(this.filter.values[0].value);

		if(this.asTree) {
			_.forEach(this.filter.values, root => {
				_.forEach(root.value, child => {
					child.parentLabel = root.label;
				});
			});
		}

		let matchedField = _.find(this.panel.andFilterFields, ['key', this.filter.key]) || {} as FilterField;


		if(this.filter.key === 'date') {
			if (_.has(matchedField.expectedValue, 'maxValue') && _.has(matchedField.expectedValue, 'minValue')) {
				const min = matchedField.expectedValue.minValue;
				const max = matchedField.expectedValue.maxValue;

				if(min === null && max === null) {
					this.clear();
					return;
				}

				this.dateMinMax = true;

				if (min === null && max !== null) {
					this.fromDate = null;
					this.toDate = new Date(max);
				} else if (min !== null && max === null) {
					this.fromDate = new Date(min);
					this.toDate = new Date();
				} else {
					this.fromDate = new Date(min);
					this.toDate = new Date(max);
				}
			}
		}


		if (this.asTree) {
			this._setSelectedValuesTree(matchedField);
		} else {
			this._setSelectedValuesMulti(matchedField);
		}
	}

	_setSelectedValuesTree(field: FilterField) {
		this.selectedFilterValues = [];

		_.forEach(this.filter.values, root => {
			_.forEach(root.value, child => {
				if (_.includes(field.expectedValue, child.value)) {
					this.selectedFilterValues.push(child);
				}
			});

			this.updateTreeRoot(root);
		});
	}

	_setSelectedValuesMulti(field: FilterField) {
		_.forEach(this.filter.values, item => {
			if (_.includes(field.expectedValue, item.value)) {
				this.selectedFilterValues.push(item);
			}
		});
	}

	done() {
		let valuesOnly = _.map(this.selectedFilterValues, 'value');

		if(this.filter.key === 'date' && this.dateMinMax) {
			valuesOnly = {
				minValue: this.fromDate || null,
				maxValue: this.toDate || null
			} as any;
		}

		// trigger parent
		this.onFilterFieldChanged.emit({key: this.filter.key, expectedValue: valuesOnly});
	}

	clear() {
		this.selectedFilterValues = [];
		this.fromDate = null;
		this.toDate = null;

		if(this.asTree) {
			this.update();
		}

		this.done();
	}

	all() {
		this.clear();

		_.forEach(this.filter.values, root => {

			if(!this.asTree) {
				if(this._matchSearch(root) || this._matchSearch(root)) {
					this.selectedFilterValues.push(root);
				}

				return; // continue loop
			}

			_.forEach(root.value, child => {
				if(this._matchSearch(child) || this._matchSearch(root)) {
					this.selectedFilterValues.push(child);
				}
			});

			this.updateTreeRoot(root);
		});

		this.done();
	}

	update() {
		_.forEach(this.filter.values, root => {
			this.updateTreeRoot(root);
		});
	}


	/* helpers */

	searching() {
		if(this.asTree) {
			this._searchTree();
		}
		else {
			this._searchMulti();
		}

		this.done();
	}

	_searchTree() {
		_.forEach(this.filter.values, root => {
			_.forEach(root.value, child => {
				if(_.includes(this.selectedFilterValues, child)) {
					if(!this._matchSearch(child) && !this._matchSearch(root)) {
						_.remove(this.selectedFilterValues, child);
					}
				}
			});

			this.updateTreeRoot(root);
		});
	}

	_searchMulti() {
		_.forEach(this.filter.values, item => {
			if(_.includes(this.selectedFilterValues, item)) {
				if(!this._matchSearch(item)) {
					_.remove(this.selectedFilterValues, item);
				}
			}
		});
	}

	_matchSearch(target: any): boolean {
		if(this.searchTerm.length === 0) {
			return true;
		}

		return target.label.toLowerCase().indexOf(this.searchTerm) !== -1;
	}

	updateTreeRoot(root: any) {
		let childSearchMatch: number = 0;
		let selectedCount: number = 0;

		_.forEach(root.value, child => {
			if(this._matchSearch(child)) {
				childSearchMatch++;
			}

			if (_.includes(this.selectedFilterValues, child)) {
				selectedCount++;
			}
		});

		let totalChildren = root.value.length;

		if(totalChildren === selectedCount) {
			root.state = 'all';
		}
		else if(childSearchMatch === selectedCount) {
			root.state = 'all';
		}
		else if(selectedCount > 0) {
			root.state = 'partial';
		}
		else {
			root.state = 'none';
		}

		root.active = root.state === 'all' || root.state === 'partial';
		root.selectedChilds = selectedCount;
	}

	treeCheckboxChanged(event: any, item: any) {
		this.refreshed = false;
		this.cd.detectChanges();

		if (event.checked) {
			_.forEach(item.value, child => {
				if(this._matchSearch(child) || this._matchSearch(item)) {
					this.selectedFilterValues.push(child);
				}
			});
		}
		else {
			_.forEach(item.value, child => {
				this.selectedFilterValues = _.pull(this.selectedFilterValues, child);
			});
		}

		this.updateTreeRoot(item);

		this.refreshed = true;
		this.cd.detectChanges();
	}

	normalCheckboxChanged(event: any, item: any) {
		this.updateTreeRoot(item);
	}

}
