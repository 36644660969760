
<p-toast position="top-right"
		 key="main-toast"
		 [baseZIndex]="12000"
		 [autoZIndex]="true"
		 [preventOpenDuplicates]="false">
</p-toast>

<app-loader></app-loader>

<div class="page">
	<router-outlet></router-outlet>
</div>


<!-- LOGIN MODAL -->
<p-dialog [visible]="global.loginModal"
		  [baseZIndex]="1500"
		  styleClass=""
		  maskStyleClass="backdrop"
		  [showHeader]="true"
		  header="MRAE Dashboards"
		  [style]="{'width': '30rem'}"
		  [draggable]="false"
		  [closeOnEscape]="false"
		  [closable]="false"
		  [autoZIndex]="true"
		  [modal]="true"
		  [blockScroll]="true">
	<app-login *ngIf="global.loginModal"></app-login>
</p-dialog>
