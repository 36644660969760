<div class="edit-filter-box" #filterBox>
	<div class="p-p-3" *ngIf="!dateMinMax">

		<!-- SEARCH -->
		<div class="p-inputgroup p-mb-4">
			<span class="p-inputgroup-addon">Bevat</span>
			<input type="text" pInputText [(ngModel)]="searchTerm" (ngModelChange)="searching()"
				   placeholder="Zoekterm..">
			<button type="button" pButton pRipple (click)="searchTerm = ''; update();"
					icon="pi pi-times"
					class="p-button-secondary p-button-outlined"></button>
		</div>

		<!-- FILTER VALUES CHECKBOXES -->
		<div *ngFor="let item of filter.values | filter:searchTerm; let i = index;">
			<div class="p-field-checkbox p-unselectable-text p-mb-1">

				<!-- MULTI-SELECT AS A TREE -->
				<ng-container *ngIf="asTree">
					<button pButton pRipple [icon]="item.expanded ? 'pi pi-minus' : 'pi pi-plus'"
							class="p-button-text p-button-secondary p-button-sm p-p-1 p-mr-2"
							(click)="item.expanded = !item.expanded"></button>

					<p-checkbox name="multiSelect"
								[binary]="true"
								(onChange)="treeCheckboxChanged($event, item); done();"
								[(ngModel)]="item.active"
								[checkboxIcon]="item.state === 'all' ? 'pi pi-check' : 'pi pi-minus'"
								[inputId]="filter.key + '.' + i"></p-checkbox>

					<label [for]="filter.key + '.' + i">
						{{item.label}} <strong *ngIf="item.active && item.selectedChilds > 0">({{item.selectedChilds}}
						geselecteerd)</strong>
					</label>
				</ng-container>

				<!-- MULTI-SELECT BASIC (no children) -->
				<ng-container *ngIf="!asTree">
					<p-checkbox name="multiSelect"
								[binary]="false"
								[value]="item"
								(onChange)="done();"
								[checkboxIcon]="'pi pi-check'"
								[(ngModel)]="selectedFilterValues"
								[inputId]="filter.key + '.' + i"></p-checkbox>

					<label [for]="filter.key + '.' + i">{{item.label}}</label>
				</ng-container>
			</div>

			<!-- THE CHILDREN CHECKBOXES WHEN MULTI-SELECT IS A TREE -->
			<ng-container *ngIf="asTree && item.expanded && refreshed">
				<div class="checkbox-child-container">
					<ng-container *ngFor="let subItem of item.value | filter:searchTerm; let j = index;">
						<div class="p-field-checkbox p-mb-2 p-unselectable-text">
							<p-checkbox name="multiSelect"
										[binary]="false"
										(onChange)="normalCheckboxChanged($event, item); done();"
										[value]="subItem"
										[(ngModel)]="selectedFilterValues"
										[inputId]="filter.key + '.' + i +'-'+ j"></p-checkbox>

							<label [for]="filter.key + '.' + i +'-'+ j">{{subItem.label}}</label>
						</div>
					</ng-container>
				</div>
			</ng-container>
		</div>

		<p class="p-mt-6 p-mb-0 p-text-italic" *ngIf="panel.relevantFiltersOnly">
			Let op: "Toon alleen relevante filteropties" staat aan. U ziet hierdoor alleen de opties die overblijven op basis van de incidenten in het filter-resultaat.
		</p>
	</div>


	<!-- DATE-FILTER (FROM/TO)-PERIOD ALTERNATIVE -->
	<div class="p-p-3 p-fluid" *ngIf="dateMinMax">
		<div class="p-field">
			<label class="p-text-normal" [for]="'date1'">Van</label>
			<p-calendar appendTo="body" inputId="date1"
						dateFormat="dd-mm-yy"
						placeholder="Kies een datum"
						(onSelect)="done()"
						[maxDate]="toDate"
						[(ngModel)]="fromDate"
						[showWeek]="true"
						[autoZIndex]="true"
						[baseZIndex]="12000"
						[showButtonBar]="true"></p-calendar>
			<small id="date1-help">Leeg betekent: alles tot</small>
		</div>
		<div class="p-field">
			<label class="p-text-normal" [for]="'date2'">Tot</label>
			<p-calendar appendTo="body" inputId="date2"
						dateFormat="dd-mm-yy"
						placeholder="Kies een datum"
						(onSelect)="done()"
						[minDate]="fromDate"
						[(ngModel)]="toDate"
						[showWeek]="true"
						[autoZIndex]="true"
						[baseZIndex]="12000"
						[showButtonBar]="true"></p-calendar>
			<small id="date2-help">Leeg betekent: vandaag</small>
		</div>
	</div>
</div>

<div class="p-d-flex p-ai-center p-mt-3">
	<button pButton pRipple (click)="all();" *ngIf="!dateMinMax"
			class="p-button-info p-text-uppercase p-mr-2" label="alles">
	</button>
	<button pButton pRipple (click)="clear()"
			class="p-button-info p-text-uppercase" label="niets">
	</button>

	<div class="p-ml-auto" *ngIf="filter.key === 'date'">
		<div class="p-field-checkbox p-unselectable-text p-m-0">
			<p-checkbox [(ngModel)]="dateMinMax" [binary]="true" inputId="dateMinMax"></p-checkbox>
			<label [for]="'dateMinMax'">Periode van / tot</label>
		</div>
	</div>
</div>


