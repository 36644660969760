<div class="p-d-flex p-ai-center p-mt-3">
	<h2 class="p-m-0">
		{{iriasConfig.getTranslation('dashboard.dashboard_title')}}
	</h2>
	<div class="trends-label">
		<span class="month">{{getStatsMonth()}}</span>
	</div>
</div>


<div class="p-grid p-mb-6">
	<div class="p-col-12">
		<div class="info-container shadow-mrae p-mt-3"
			 [routerLink]="['/', 'data', 'config']"
			 [queryParams]="{id: 1, 'lang': iriasConfig.getActiveLanguageAsString(), 'filter': 'charging-infrastructure-usage-trends'}">
			<div class="p-d-flex p-ai-start">
				<h3 class="p-mt-0">
					{{iriasConfig.getTranslation('reports.trends_menu')}}
				</h3>
				<button pButton class="p-button-rounded p-button-outlined p-button-icon-only p-ml-auto icon-button">
					<img alt="Trends" src="assets/img/new_icon_arrow_blue.png"/>
				</button>
			</div>

			<div class="p-grid trends-grid" *ngIf="statsLatest">

				<div class="p-col-6 p-md-3">
					<div class="p-d-flex p-flex-column p-ai-center p-ai-md-start">
						<div class="img-container">
							<img src="assets/img/1_groot.png"/>
						</div>
						<div class="diff">{{getIncrease('total_sessions')}}</div>
						<div class="value countUp" [title]="statsLatest?.total_sessions">{{statsLatest?.total_sessions}}</div>
						<div class="descr">{{iriasConfig.getTranslation('reports.charging_sessions_title')}}</div>
					</div>
				</div>
				<div class="p-col-6 p-md-3">
					<div class="p-d-flex p-flex-column p-ai-center p-ai-md-start">
						<div class="img-container">
							<img src="assets/img/2_groot.png"/>
						</div>
						<div class="diff">{{getIncrease('total_stations')}}</div>
						<div class="value countUp" [title]="statsLatest?.total_stations">{{statsLatest?.total_stations}}</div>
						<div class="descr">{{iriasConfig.getTranslation('reports.charging_stations_title')}}</div>
					</div>
				</div>
				<div class="p-col-6 p-md-3">
					<div class="p-d-flex p-flex-column p-ai-center p-ai-md-start">
						<div class="img-container">
							<img src="assets/img/3_groot.png"/>
						</div>
						<div class="diff">{{getIncrease('total_rfids')}}</div>
						<div class="value countUp" [title]="statsLatest?.total_rfids">{{statsLatest?.total_rfids}}</div>
						<div class="descr">{{iriasConfig.getTranslation('reports.unique_rfid_title')}}</div>
					</div>
				</div>
				<div class="p-col-6 p-md-3">
					<div class="p-d-flex p-flex-column p-ai-center p-ai-md-start">
						<div class="img-container">
							<img src="assets/img/totaal_aantal_kwh.png"/>
						</div>
						<div class="diff">{{getIncrease('total_kwh')}}</div>
						<div class="value countUp" [title]="statsLatest?.total_kwh">{{statsLatest?.total_kwh}}</div>
						<div class="descr">{{iriasConfig.getTranslation('reports.charged_total_title')}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="p-col-12 p-md-8">
		<div class="p-d-flex p-ai-center p-flex-column p-flex-lg-row blue-container shadow-mrae p-px-5 p-py-4">

			<div class="p-mb-3 p-mb-lg-0 co2-title p-d-flex">
				{{iriasConfig.getTranslation('dashboard.dashboard_co2_title')}}
				<span class="p-d-lg-none p-d-block p-pl-4">
					<span>{{lodash.round(statsLatest?.total_reduction)}}</span>
					<span class="p-pl-1">{{iriasConfig.getTranslation('dashboard.dashboard_co2_metric')}}</span>
				</span>
			</div>
			<div class="p-mx-3 p-d-lg-block p-d-none co2-title">
				<span>{{lodash.round(statsLatest?.total_reduction)}}</span>
				<span class="p-pl-1">{{iriasConfig.getTranslation('dashboard.dashboard_co2_metric')}}</span>
			</div>
			<div class="p-d-flex p-ai-center p-jc-lg-end p-ml-lg-auto">

				<div style="height: calc(131px /2)">
					<img src="assets/img/windmill.png" height="100%"/>
				</div>

				<div class="p-d-flex p-ai-center p-flex-column">
					<div class="p-d-flex">
						<span class="plane-value">{{flightCount}} x</span>
						<div style="height: calc(62px /2)">
							<img src="assets/img/plane.png" height="100%"/>
						</div>
					</div>
					<div class="p-py-3">
						<img src="assets/img/new_line_wave3.png" height="12px"/>
					</div>
					<div class="plane-text p-d-flex p-ai-center">
						<img src="assets/img/ticket_icon.png"/>
						<span class="p-pl-3" style="width: 80%;">{{iriasConfig.getTranslation('dashboard.dashboard_co2_text')}}</span>
					</div>
				</div>

				<div style="height: calc(149px /2)">
					<img src="assets/img/newyork.png" height="100%"/>
				</div>
			</div>

		</div>
	</div>
	<div class="p-col-12 p-md-4">
		<div class="blue-container shadow-mrae p-d-flex p-flex-column p-ai-center p-jc-center" style="height: 100%;">
			<div class="km-text p-pb-4">
				{{iriasConfig.getTranslation('dashboard.dashboard_km_text')}}
			</div>
			<div class="km-value p-d-flex p-jc-lg-evenly">
				<div>
					<span class="countUp" [title]="statsLatest?.total_km">{{statsLatest?.total_km}}</span>
					<span class="p-pl-2">kilometers</span>
				</div>
				<div class="p-ml-2" style="height: calc(68px /2)">
					<img src="assets/img/bike.png" height="100%"/>
				</div>
			</div>

			<img src="assets/img/new_wave_bike2.png" height="2px"/>
		</div>
	</div>


	<ng-container *ngFor="let trend of trends">

		<div class="p-col-12">
			<div class="info-container shadow-mrae"
				 [routerLink]="['/', 'data', 'config']"
				 [queryParams]="{id: trend.id, filter: trend.filter, 'lang': iriasConfig.getActiveLanguageAsString()}">
				<div class="p-d-flex p-ai-center">
					<div style="height: calc(92px /2)">
						<img [src]="'assets/img/' + trend.icon" height="100%"/>
					</div>
					<span class="title">{{iriasConfig.getTranslation(trend.title_trans)}}</span>
					<i class="far fa-info-circle p-pl-1" tooltipPosition="top"
						*ngIf="trend.tooltip_trans"
					   [pTooltip]="iriasConfig.getTranslation(trend.tooltip_trans)"></i>
					<div class="p-ml-auto p-mr-5 text">
						<div class="diff">{{trend.increase_value}}</div>
						<span class="value">{{toLocal(trend.value)}}</span>
						<span>{{iriasConfig.getTranslation('panels.monthly')}}</span>
					</div>
					<div class="p-pl-4">
						<button pButton
								class="p-button-rounded p-button-outlined p-button-icon-only p-ml-auto icon-button">
							<img alt="logo" src="assets/img/new_icon_arrow_blue.png"/>
						</button>
					</div>
				</div>
			</div>
		</div>

	</ng-container>


</div>

