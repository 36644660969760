<form [formGroup]="passwordForm" novalidate>
	<p>Gebruik verschillende letters, cijfers en speciale tekens voor een veilig wachtwoord.</p>

	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col">
			<label for="pw" class="p-text-normal">Nieuw wachtwoord</label>
			<div class="p-inputgroup">
				<input id="pw" type="password" formControlName="password" pPassword
					   promptLabel="Voer een veilig wachtwoord in"
					   weakLabel="Zwak"
					   mediumLabel="Goed"
					   strongLabel="Sterk"
					   [showPassword]="showPassword">
				<button pButton pRipple (click)="showPassword = !showPassword"
						[icon]="showPassword ? 'pi pi-eye-slash' : 'pi pi-eye'"
						[ngClass]="showPassword ? 'p-button-primary' : 'p-button-secondary'">
				</button>
			</div>
			<small id="pw-help"
				   [ngClass]="{'p-invalid':controls.password.hasError('minlength') || (controls.password.hasError('required') && controls.password.dirty)}">
				Wachtwoord moet uit minstens 8 tekens bestaan.
			</small>
		</div>
		<div class="p-field p-col">
			<label for="pw2" class="p-text-normal">Herhaal wachtwoord</label>
			<input id="pw2" type="password" pInputText formControlName="password_repeat">
			<small id="pw2-help" class="p-invalid"
				   *ngIf="controls.password_repeat.invalid && controls.password_repeat.dirty">
				Wachtwoord komt niet overeen.
			</small>
		</div>
	</div>
</form>

<div class="p-grid p-formgrid p-mt-3">
	<div class="p-col-12 p-text-right">
		<button pButton pRipple type="button" label="Annuleren" class="p-button-secondary p-button-text p-button-rounded p-mr-2"
				(click)="ref.destroy()"></button>
		<button pButton pRipple type="button" label="Opslaan" class="p-button-rounded p-text-uppercase"
				(click)="submit()"
				[disabled]="passwordForm.invalid ? true : null"></button>
	</div>
</div>