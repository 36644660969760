
<div class="p-pt-3 p-d-lg-none reports-menu">
	<button type="button" pButton icon="pi pi-bars" class="menu-button" label="Menu" (click)="menu.toggle($event)"></button>
	<p-menu #menu [popup]="true" [model]="items" styleClass="config-menu shadow-mrae"></p-menu>
</div>



<div class="p-d-flex reports-container p-flex-lg-row p-pt-4 p-pt-lg-6">

	<div class="reports-menu sticky p-pt-3 p-d-none p-d-lg-block" *ngIf="items.length">
		<div class="p-d-flex p-ai-center p-jc-center p-py-4 back-to-data" [routerLink]="['/data']" [queryParams]="{'lang': iriasConfig.getActiveLanguageAsString()}">
			<i class="fas fa-caret-left p-pr-3"></i>
			<span>
				{{iriasConfig.getTranslation('panels.back_to')}} dashboard
			</span>
		</div>

		<p-menu [model]="items" styleClass="shadow-mrae"></p-menu>
	</div>

	<div class="reports-mrae-custom">
		<div class="reports shadow-mrae">
			<ng-container *ngIf="!fetched">
				<p-progressSpinner styleClass="panel-loader"></p-progressSpinner>
			</ng-container>
			<ng-container *ngFor="let panel of panels; let i = index;">
				<ng-template *ngTemplateOutlet="reportChart; context: {panel: panel}"></ng-template>
			</ng-container>
		</div>
		<div class="reports-sidebar">
			<div class="sticky">
				<div class="legend-title p-mb-4">{{iriasConfig.getTranslation('panels.legend_title')}}</div>
				<div id="legend-container"></div>
			</div>
		</div>
	</div>

</div>


<!-- SINGLE PANEL -->
<ng-template #reportChart let-panel="panel">

	<ng-container *ngIf="isChart(panel.type) && fetched">

		<div class="chart-container">

			<div class="p-d-flex">
				<h2 class="p-mt-0 p-mb-4 p-d-flex p-ai-center">
					<img [src]="getCaptionIcon(panel.caption)" style="height: 42px" />
					<span class="p-pl-2">
						{{iriasConfig.getTranslation('reports.'+panel.caption+'_title')}},
						<span class="p-text-bold">{{iriasConfig.getTranslation('panels.'+panel.kind)}}</span>
						<i class="far fa-info-circle p-pl-2"  tooltipPosition="top" style="font-size: 16px;"
						   *ngIf="iriasConfig.getTranslation('reports.'+panel.caption+'_tooltip', null) != null"
						   [pTooltip]="iriasConfig.getTranslation('reports.'+panel.caption+'_tooltip')"></i>
					</span>
				</h2>
			</div>

			<filters-topbar-mrae
				(onPanelChange)="panelUpdated($event)"
				[groupOptions$]="groupByOptions$"
				[limits]="limits"
				[initPanel]="panel">
			</filters-topbar-mrae>

			<div class="content p-mb-2" *ngIf="panel.description !== null">
				{{panel.description}}
			</div>

			<div>
				<canvas [id]="'p-'+panel.index"></canvas>
			</div>

			<div class="p-d-flex p-ai-center p-mt-3">
				<div class="p-ml-auto" *ngIf="panel.resultData !== null && panel.resultData.labels?.length">

					<p-button (onClick)="instantDownload(panel)" styleClass="p-button-link p-button-lg button-download">
						<span class="p-mr-2">Download data</span>
						<img alt="logo" src="assets/img/new_download_white.png" style="height: 1rem"/>
					</p-button>

				</div>
			</div>
		</div>

	</ng-container>

</ng-template>
