import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnalysePanel} from "../../../classes/models/AnalysePanel";
import {Filter, FilterField, GroupOption} from "../../../types/sherpa";
import _ from "lodash";
import {util} from "../../../../services";
import {CaptionService} from "../../../services/caption.service";
import {BehaviorSubject} from "rxjs";
import {ConfigsService} from "../../../services/data/configs.service";
import {ImportService} from "../../../services/data/import.service";
import {PrimeNGConfig} from "primeng/api";
import {IriasConfigService} from "../../../services/irias-config.service";

@Component({
	selector: 'filters-topbar-mrae',
	templateUrl: './filters-topbar-mrae.component.html',
	styleUrls: ['./filters-topbar-mrae.component.scss']
})
export class FiltersTopbarMraeComponent implements OnInit {

	@Input() initPanel: AnalysePanel;
	@Input() groupOptions$: Promise<GroupOption[]>;
	@Input() preview: boolean = false;
	@Input() limits: any[];

	@Output() onPanelChange: EventEmitter<AnalysePanel> = new EventEmitter<AnalysePanel>();

	filters: Filter[] = [];
	filtersMap: Map<string, Filter> = new Map<string, Filter>();

	panel: AnalysePanel = null;
	submitted: boolean = true;
	selectedField: FilterField = null;
	groupOptions: GroupOption[] = [];

	type: 'yearly' | 'monthly';
	fromValue: any = null;
	toValue: any = null;
	fromDate: Date;
	toDate: Date;

	// selection limits
	monthLimit: any = null;
	yearLimit: any = null;
	yearRangeForMonthly: string = '';
	yearRangeForYearly: any[] = [];
	minDateLimit: Date;
	maxDateLimit: Date;

	constructor(public iriasConfig: IriasConfigService) {
	}

	ngOnInit(): void {
		this.panel = this.initPanel;
		this.setup(this.panel.filters);
		this.setSelected(this.panel.andFilterFields);


		if(this.panel.groupByField.key === 'date_year') {
			this.type = 'yearly';
			this.selectedField.key = 'date_year';

			if(this.yearLimit !== null) {
				const minDate = new Date(this.yearLimit.minValue);
				const maxDate = new Date(this.yearLimit.maxValue);

				for (let i = minDate.getFullYear(); i <= maxDate.getFullYear(); i++) {
					this.yearRangeForYearly.push({
						value: i,
						label: i.toString()
					});
				}
			}

		}
		else if(this.panel.groupByField.key === 'date_year_month') {
			this.type = 'monthly';
			this.selectedField.key = 'date';

			if(this.monthLimit !== null) {
				this.minDateLimit = new Date(this.monthLimit.minValue);
				this.maxDateLimit = new Date(this.monthLimit.maxValue);

				this.yearRangeForMonthly = `${this.minDateLimit.getFullYear()}:${this.maxDateLimit.getFullYear()}`;
			}
		}
	}

	private setup(updatedFilters: Filter[]) {
		this.filters = updatedFilters;
		this.filtersMap.clear();

		_.forEach(updatedFilters, filter => {
			this.filtersMap.set(filter.key, filter);
		});

		// default
		this.selectedField = {
			key: 'date',
			expectedValue: []
		} as FilterField;

		this.monthLimit = _.find(this.limits, ['type', 'monthly']);
		this.yearLimit = _.find(this.limits, ['type', 'yearly']);
	}

	private setSelected(andFilters: FilterField[]) {

		// case = yearly
		const date_year = _.find(andFilters, ['key', 'date_year']);
		const date = _.find(andFilters, ['key', 'date']);

		if(date_year) {
			this.selectedField = date_year;

			const min = _.min(date_year.expectedValue);
			const max = _.max(date_year.expectedValue);

			this.fromValue = {value: min, label: min.toString()};
			this.toValue = {value: max, label: max.toString()};
		}
		else if(date) {
			this.selectedField = date;

			this.fromDate = new Date(date.expectedValue.minValue);
			this.toDate = new Date(date.expectedValue.maxValue);
		}
	}

	filterYearlyChanged() {

		if(this.fromValue === null || this.toValue === null) {
			return;
		}

		// changes found but no api-call yet
		this.submitted = false;

		// first get all
		const fields = this.filtersMap.get(this.selectedField.key).values;
		const min = _.min([this.fromValue.value, this.toValue.value]);
		const max = _.max([this.fromValue.value, this.toValue.value]);

		const selection = _.filter(fields, filterField => {
			return filterField.value >= min && filterField.value <= max;
		});

		const filterField = {
			key: this.selectedField.key,
			expectedValue: _.map(selection, 'value')
		} as FilterField;

		// if (this.fromValue.value > this.toValue.value) {
		// 	this.panel.sortOrder = "asc" as any;
		// }

		this.panel.andFilterFields = util.upsert(this.panel.andFilterFields, filterField, 'key');
	}

	filterMonthlyChanged() {

		if(_.isNil(this.fromDate) || _.isNil(this.toDate)) {
			return;
		}

		console.log('filterMonthlyChanged', this.fromDate, this.toDate);

		// changes found but no api-call yet
		this.submitted = false;

		const filterField = {
			key: this.selectedField.key,
			expectedValue: {
				minValue: this.fromDate,
				maxValue: this.toDate
			}
		} as FilterField;

		this.panel.andFilterFields = util.upsert(this.panel.andFilterFields, filterField, 'key');
	}

	applyFilters() {
		this.submitted = true;
		this.onPanelChange.emit(this.panel);
	}

}
