import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {global} from "../../globals";
import {ImportService} from "../services/data/import.service";
import {Filter, QueryConfiguration} from "../types/sherpa";
import {AnalysePanel} from "../classes/models/AnalysePanel";

@Injectable({
	providedIn: 'root'
})
export class FiltersResolver implements Resolve<Filter[]> {

	constructor(private incidentsService: ImportService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Filter[]> {
		global.enableLoader();
		return this.incidentsService.getFilters({} as AnalysePanel);
	}
}