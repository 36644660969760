import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {global} from "../../globals";
import {ImportService} from "../services/data/import.service";
import {Filter, GroupOption} from "../types/sherpa";

@Injectable({
	providedIn: 'root'
})
export class GroupOptionsResolver implements Resolve<GroupOption[]> {

	constructor(private incidentsService: ImportService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<GroupOption[]> {
		global.enableLoader();
		return this.incidentsService.getGroupOptions();
	}
}