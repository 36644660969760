import {Component, OnInit} from '@angular/core';
import {util} from "../../../../services";
import _ from "lodash";
import {ConfigsService} from "../../../services/data/configs.service";
import {DashboardStats} from "../../../classes/models/DashboardStats";
import {IriasConfigService} from "../../../services/irias-config.service";
import {Trend} from "../../../classes/models/Trend";
import {LanguageType} from "../../../classes/models/IriasTranslation";

@Component({
	selector: 'base-dashboard-trends',
	templateUrl: './dashboard-trends.component.html',
	styleUrls: ['./dashboard-trends.component.scss']
})
export class DashboardTrendsComponent implements OnInit {

	util = util;
	lodash = _;

	statsLatest: DashboardStats = null;
	statsPrevious: DashboardStats = null;

	intersectionObserver: IntersectionObserver;
	isAnimated = false;
	flightCount = 0;
	flightFormula = 1.136363636363;

	trends: Trend[] = [];

	constructor(private configsService: ConfigsService, public iriasConfig: IriasConfigService) {
	}

	ngOnInit(): void {
		this.fetchStats();

		// for animating numbers
		this.intersectionObserver = new IntersectionObserver(this.onIntersection.bind(this), {
			root: null,   // default is the viewport
			threshold: .5 // percentage of taregt's visible area. Triggers "onIntersection"
		});


		this.iriasConfig.language.subscribe(t => {
			this.runAnimations();
		});
	}

	private onIntersection(entries: any[], opts: any) {
		_.forEach(entries, entry => {
			if(entry.isIntersecting && !this.isAnimated) {
				this.isAnimated = true;
				this.runAnimations();
			}
		});
	}


	private fetchStats() {
		this.configsService.getDashboardData().then(result => {
			this.statsLatest = _.head(result);
			this.statsPrevious = _.nth(result, 1);
			this.setTrends();


			const _flightCount = this.statsLatest.total_reduction * this.flightFormula;
			this.flightCount = _.round(_flightCount);

			this.intersectionObserver.observe(document.querySelector('.countUp'));
		});
	}

	private setTrends() {

		const total_stations = {
			id: 2,
			filter: 'number-of-charging-stations',
			icon: 'aantal_oplaad_stations.png',
			title_trans: 'reports.charging_stations_title',
			increase_value: this.getIncrease('total_stations'),
			value: _.round(this.statsLatest.total_stations),
			tooltip_trans: 'reports.charging_stations_tooltip'
		} as Trend;

		const total_sessions = {
			id: 3,
			filter: 'number-of-charging-sessions',
			icon: 'aantal_oplaad_sessies.png',
			title_trans: 'reports.charging_sessions_title',
			increase_value: this.getIncrease('total_sessions'),
			value: _.round(this.statsLatest.total_sessions)
		} as Trend;

		const unique_rfid = {
			id: 4,
			filter: 'number-of-unique-rfid',
			icon: 'unieke_gebruikers.png',
			title_trans: 'reports.unique_rfid_title',
			increase_value: this.getIncrease('total_rfids'),
			value: _.round(this.statsLatest.total_rfids),
			tooltip_trans: 'reports.unique_rfid_tooltip'
		} as Trend;

		const total_kwh = {
			id: 5,
			filter: 'total-charged-k-wh',
			icon: 'totaal_aantal_kwh.png',
			title_trans: 'reports.charged_total_title',
			increase_value: this.getIncrease('total_kwh'),
			value: _.round(this.statsLatest.total_kwh)
		} as Trend;

		const kwh_per_session = {
			id: 6,
			filter: 'charged-k-wh-per-session',
			icon: 'kwh_per_sessies.png',
			title_trans: 'reports.charged_per_session_title',
			increase_value: this.getIncrease('kwh_per_session'),
			value: _.round(this.statsLatest.kwh_per_session)
		} as Trend;

		const kwh_per_station = {
			id: 7,
			filter: 'charged-k-wh-per-station',
			icon: 'aantal_oplaad_stations.png',
			title_trans: 'reports.charged_per_station_title',
			increase_value: this.getIncrease('kwh_per_station'),
			value: _.round(this.statsLatest.kwh_per_station),
			tooltip_trans: 'reports.charged_per_station_tooltip'
		} as Trend;

		const kwh_per_rfid = {
			id: 8,
			filter: 'charged-k-wh-per-rfid',
			icon: 'kwh_per_RFIDs.png',
			title_trans: 'reports.charged_per_rfid_title',
			increase_value: this.getIncrease('kwh_per_rfid'),
			value: _.round(this.statsLatest.kwh_per_rfid),
			tooltip_trans: 'reports.charged_per_rfid_tooltip'
		} as Trend;

		const unique_rfid_per_station = {
			id: 9,
			filter: 'unique-active-users-per-station',
			icon: 'aantal_unieke_RFIDs.png',
			title_trans: 'reports.users_per_station_title',
			increase_value: this.getIncrease('rfid_per_station'),
			value: _.round(this.statsLatest.rfid_per_station)
		} as Trend;


		this.trends.push(total_stations);
		this.trends.push(total_sessions);
		this.trends.push(unique_rfid);
		this.trends.push(total_kwh);
		this.trends.push(kwh_per_session);
		this.trends.push(kwh_per_station);
		this.trends.push(kwh_per_rfid);
		this.trends.push(unique_rfid_per_station);
	}

	getStatsMonth() {
		if(_.isNil(this.statsLatest)) {
			return '';
		}

		const months = this.iriasConfig.getTranslation('main.months');

		return _.nth(months, this.statsLatest.month -1) + ' ' + this.statsLatest.year;
	}

	getIncrease(key: string) {
		if(_.isNil(this.statsLatest) || _.isNil(this.statsPrevious)) {
			return '';
		}

		const final = _.get(this.statsLatest, key);
		const initial = _.get(this.statsPrevious, key);

		const result = 100 * ((final - initial) / initial);

		const rounded_result = _.round(result, 1);
		const prefix = rounded_result > 0 ? '+' : '';

		return `${prefix}${rounded_result}%`;
	}

	toLocal(value: number, fraction: number = 0) {
		const options = {
			minimumFractionDigits: fraction
		};

		if(this.iriasConfig.getActiveLanguage() == LanguageType.EN) {
			return value.toLocaleString('en-US', options);
		}

		return value.toLocaleString('nl-NL', options);
	}

	private easeOutQuad(t: number) {
		return t * (2 - t);
	}

	// The animation function, which takes an Element
	private animateCountUp(el: Element) {

		// count up effect
		const animationDuration = 1500;
		const frameDuration = 1000 / 60;
		const totalFrames = Math.round(animationDuration / frameDuration);

		let frame = 0;

		//console.log(el.getAttribute('title'));

		const countTo = parseInt(el.getAttribute('title'), 10);

		// Start the animation running 60 times per second
		const counter = setInterval(() => {
			frame++;

			// progress on a curve
			const progress = this.easeOutQuad(frame / totalFrames);

			// Use the progress value to calculate the current count
			const currentCount = Math.round(countTo * progress);

			// If the current count has changed, update the element
			if (parseInt(el.innerHTML, 10) !== currentCount) {
				el.innerHTML = currentCount + '';
			}

			// If we’ve reached our last frame, stop the animation
			if (frame === totalFrames) {
				clearInterval(counter);
				el.innerHTML = this.toLocal(parseInt(el.innerHTML, 10));
			}
		}, frameDuration);
	};

	private runAnimations() {
		const elements = document.querySelectorAll('.countUp');
		elements.forEach(this.animateCountUp.bind(this));
	};


	ngOnDestroy() {
		this.intersectionObserver.unobserve(document.querySelector('.countUp'));
	}
}
