import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {map} from "rxjs/operators";
import {of} from "rxjs";
import {global} from "../../../../globals";
import {AppLoader} from "../../../classes/Globals";
import {LoginCredentials, SessionService} from "../../../services/data/session.service";
import {auth} from "../../../../services";
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {MessageService} from "primeng/api";

@Component({
	selector: 'user-password',
	templateUrl: './password.component.html',
	styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

	passwordForm: FormGroup;
	showPassword: boolean = false;

	public get controls() {
		return this.passwordForm.controls;
	}

	constructor(private _fb: FormBuilder, public ref: DynamicDialogRef, public messageService: MessageService, public sessionService: SessionService) {
	}

	containError(code: string) {
		return this.controls.errors.getError(code);
	}

	passwordEqualValidator(control: AbstractControl) {
		return of(this.controls.password.value !== control.value)
			.pipe(
				map(result => result ? { equal: true } : null)
			);
	}

	ngOnInit() {
		this.passwordForm = this._fb.group({
			password: ['', [Validators.required, Validators.minLength(8)]],
			password_repeat: ['', Validators.required, this.passwordEqualValidator.bind(this)]
		});
	}

	submit() {
		if(this.passwordForm.invalid) {
			return;
		}

		const new_password = this.controls.password.value;

		global.disableLoader();
		this.sessionService.changePassword(new_password)
			.then(() => {

				const cr: LoginCredentials = {
					username: auth.getUser()!.email,
					password: new_password
				}

				// login with new_password
				return this.sessionService.login(cr)
					.then((user)=> {
						this.messageService.add({
							severity: 'success',
							summary: 'U hoeft niet opnieuw in te loggen.',
							detail: 'Wachtwoord wijzigen geslaagd.'
						});
					})
					.catch(() => {
						this.messageService.add({
							severity: 'success',
							summary: 'U dient opnieuw in te loggen.',
							detail: 'Wachtwoord wijzigen geslaagd.'
						});
					})
					.finally(() => this.ref.close());

			})
			.finally(() => {
				global.loader = AppLoader.PAGE;
			});
	}

}
