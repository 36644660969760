import {Component, OnInit} from '@angular/core';
import {TableColumn} from "../../../../classes/primeng/TableColumn";
import _ from "lodash";
import {util} from "../../../../../services";
import {SourceMessage, SourceView, SourceViewWithMessages} from "../../../../types/sherpa";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {ImportService} from "../../../../services/data/import.service";

@Component({
	selector: 'base-import-messages-modal',
	templateUrl: './import-messages-modal.component.html',
	styleUrls: ['./import-messages-modal.component.scss']
})
export class ImportMessagesModalComponent implements OnInit {

	source: SourceView;
	messages: SourceMessage[] = [];
	cols: TableColumn[] = [];
	visibleColumns: TableColumn[] = [];

	showActionButtons : boolean = true;

	constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private incidentsService: ImportService) {
		this.source = this.config.data.sourceView;
	}

	ngOnInit(): void {

		this.cols = [
			{field: 'sheet', header: 'Blad'},
			{field: '_field', header: 'Veld'},
			{field: 'message', header: 'Bericht'},
			{field: 'column', header: 'Waarde'},
		] as TableColumn[];

		this.visibleColumns = _.take(this.cols, 4);
		this.setMessages(this.config.data.messages);
		this.showActionButtons = this.config.data.showActionButtons;
	}

	setMessages(messages: SourceMessage[]) {
		this.messages = [];
		const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

		// when just 1 sheet, dont show the column
		const maxSheet = _.maxBy(messages, 'sheet');
		if (maxSheet.sheet === 0) {
			this.visibleColumns = _.without(this.cols, this.cols[0]);
		} else {
			this.visibleColumns = _.take(this.cols, 4);
		}

		// add property _field to set friendly excel-field like A1, B2, C3
		_.forEach(messages, (m: SourceMessage) => {

			let result = m.column / alphabet.length;
			let position = _.floor(result);
			let rest = m.column % alphabet.length;
			if (rest === 0) {
				rest = 26;
				position -= 1;
			}

			if (position > 0) {
				// @ts-ignore
				m._field = alphabet[position - 1] + alphabet[rest - 1] + ':' + m.line;
			} else {
				// @ts-ignore
				m._field = alphabet[rest - 1] + ':' + m.line;
			}

			this.messages.push(m);
		});
	}

	cancelImport() {
		this.incidentsService.cancelImport(this.source.importsource_id)
			.then((result) => {
				this.ref.close(this.source);
			});
	}

	commitImport(overwrite_non_uniques: boolean) {
		this.incidentsService.commitImport(this.source.importsource_id, overwrite_non_uniques)
			.then((result) => {
				this.ref.close(this.source);
			});
	}

	format(size: number): string {
		return util.formatBytes(size);
	}
}
