import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {IriasTranslation, LanguageType} from "../classes/models/IriasTranslation";
import _ from "lodash";
import {ActivatedRoute} from "@angular/router";
import {HttpClient} from "@angular/common/http";

@Injectable({
	providedIn: 'root'
})
export class IriasConfigService {

	public language: Subject<IriasTranslation>;
	private translation: IriasTranslation;

	public nl: IriasTranslation = null;
	public en: IriasTranslation = null;

	selectedLang = LanguageType.NL;

	constructor(private route: ActivatedRoute, private http: HttpClient) {

		this.language = new Subject<IriasTranslation>();

		this.language.subscribe(t => {
			this.translation = t;
		});

		this.route.queryParamMap.subscribe(params => {

			if(params.has('lang') && params.get('lang') === 'en') {
				this.setLanguage(LanguageType.EN);
			}
			else {
				this.setLanguage(LanguageType.NL);
			}

		});
	}

	setTranslation(value: IriasTranslation) {
		this.language.next(value);
	}

	setLanguage(type: LanguageType) {
		if(type == LanguageType.NL) {
			if(this.nl == null) {
				this.initTranslation(LanguageType.NL);
			}
			else {
				this.setTranslation(this.nl);
			}
		}
		else if(type == LanguageType.EN) {
			if(this.en == null) {
				this.initTranslation(LanguageType.EN);
			}
			else {
				this.setTranslation(this.en);
			}
		}
	}

	initTranslation(type: LanguageType) {
		this.http.get("assets/en.json").toPromise().then(result => {
			this.en = result as IriasTranslation;
			this.en.main.language = LanguageType.EN;
			if(type === this.en.main.language) {
				this.setTranslation(this.en);
			}
		});

		this.http.get("assets/nl.json").toPromise().then(result => {
			this.nl = result as IriasTranslation;
			this.nl.main.language = LanguageType.NL;
			if(type === this.nl.main.language) {
				this.setTranslation(this.nl);
			}
		});
	}

	getTranslation(path: string, alt: string = path) {
		return _.get(this.translation, path, alt) as any;
	}

	getActiveLanguage(): LanguageType {
		return this.getTranslation('main.language');
	}

	getActiveLanguageAsString(): string {
		const current = this.getActiveLanguage();
		if(current == LanguageType.EN) {
			return 'en';
		}

		return 'nl';
	}

	ngOnDestroy() {
		this.language.unsubscribe();
	}
}


