<form [formGroup]="form" (ngSubmit)="submit();" class="">
	<div class="p-fluid">

		<div class="p-field">
			<label for="user" class="p-text-normal">E-mailadres</label>
			<input id="user" pInputText autofocus autocomplete="username" type="email" formControlName="username"
				   class="p-inputtext-lg" [ngClass]="{'p-error': hasError(controls.username)}" />
			<small id="user-help" class="p-invalid" *ngIf="hasError(controls.username, 'required')">
				E-mailadres is verplicht.
			</small>
			<small id="user-help2" class="p-invalid" *ngIf="hasError(controls.username, 'email')">
				Geen geldig e-mailadres.
			</small>
		</div>
		<div class="p-field p-mt-5">
			<label for="pw" class="p-text-normal">Wachtwoord</label>
			<input id="pw" pInputText autocomplete="current-password" type="password" formControlName="password"
				   class="p-inputtext-lg" [ngClass]="{'p-error': hasError(controls.password)}" />
			<small id="pw-help" class="p-invalid" *ngIf="hasError(controls.password, 'required')">
				Wachtwoord is verplicht.
			</small>
			<button pButton type="button" class="p-button-link button-ww p-d-flex" label="Wachtwoord vergeten?" (click)="passwordResetRequest()">
			</button>
		</div>

		<div class="p-d-flex p-mt-5">
			<div class="p-ml-auto">
				<button pButton pRipple type="submit" label="Inloggen"
						class="p-text-uppercase"
						[ngClass]="{'is-loading':signing}">
				</button>
			</div>
		</div>
	</div>

</form>