
<div class="p-d-flex">

	<div class="admin-menu shadow-mrae p-d-flex p-flex-column">
		<div class="p-d-flex logo p-p-3">
			<img src="assets/img/logo-final.png" />
		</div>

		<p-panelMenu *ngIf="items.length"
					 [model]="items"
					 styleClass="p-mt-5"></p-panelMenu>

		<p-panelMenu
			[model]="accountItems"
			styleClass="p-mt-5"></p-panelMenu>
	</div>

	<div class="admin-content p-p-5">
		<router-outlet></router-outlet>
	</div>

</div>
