import {NgModule} from '@angular/core';
import {
	NavigationEnd,
	NavigationError,
	NavigationStart,
	Router,
	RouterModule,
	Routes
} from '@angular/router';
import {PageNotFoundComponent} from "./components/common/page-not-found/page-not-found.component";
import {AuthService} from "./services/auth.service";
import {PanelsComponent} from "./components/pages/panels/panels.component";
import {StoredConfigsResolver} from "./resolvers/storedConfigs.resolver";

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
	}, {
		path: 'beheer',
		canActivate: [AuthService],
		loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
	}, {
		path: 'dashboard/kpis',
		pathMatch: 'full',
		component: PanelsComponent,
		resolve: {
			storedConfigs: StoredConfigsResolver
		}
	}, {
		path: '**',
		component: PageNotFoundComponent
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		onSameUrlNavigation: 'reload'
	})],
	exports: [RouterModule],
	providers: []
})
export class AppRoutingModule {

	constructor(private router: Router) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {

			}

			if (event instanceof NavigationEnd) {
				// Hide loading indicator
			}

			if (event instanceof NavigationError) {

			}
		});
	}
}