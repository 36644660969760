
<p-card header="Geimporteerde data">
			<p class="">Alle geimporteerde data voor het maken van analyses.</p>

			<p-table #dt
					 styleClass=""
					 dataKey="unique_external_id"
					 selectionMode="single"
					 (onRowSelect)="onRowSelect($event)"
					 (onRowUnselect)="onRowUnselect($event)"
					 [(selection)]="selectedRows"
					 [value]="dataResult"
					 [columns]="columns"
					 [loading]="loading"
					 [globalFilterFields]="fields"
					 [rows]="10"
					 [rowsPerPageOptions]="[10,25,50,100,250]"
					 [responsive]="true"
					 [autoLayout]="true"
					 [paginator]="true"
					 [showPageLinks]="false"
					 [paginatorDropdownAppendTo]="'body'"
					 [alwaysShowPaginator]="false"
					 [showCurrentPageReport]="false"
					 [sortField]="fields[4]"
					 [sortOrder]="-1">

				<ng-template pTemplate="caption">
					<div class="table-header">
						<button pButton pRipple class="p-button" *ngIf="showFilterButton"
								label="Filteren"
								icon="far fa-sliders-h"
								(click)="openPanelFilter();">
						</button>

						<button pButton pRipple class="p-button-danger p-ml-2"
								*ngIf="getSelected().length > 0"
								(click)="confirmDelete()"
								[label]="'Verwijder rijen (' +getSelected().length+ ')'"
								icon="fa fa-trash-alt">
						</button>
					</div>
				</ng-template>

				<ng-template pTemplate="header" let-columns>
					<tr>
						<th style="width: 3rem">
							<i class="fa fa-trash"></i>
						</th>
						<ng-container *ngFor="let col of columns">
							<th [pSortableColumn]="col.field">{{col.header}}
								<p-sortIcon [field]="col.field"></p-sortIcon>
							</th>
						</ng-container>
					</tr>
					<tr>
						<th>
							<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
						</th>
						<ng-container *ngFor="let col of columns">
							<th>
								<input pInputText type="text"
									   (input)="searchColumn($event.target, col.field, 'startsWith')"
									   [placeholder]="'Zoeken op ' + col.header" class="p-column-filter p-inputtext-sm">
							</th>
						</ng-container>
					</tr>
				</ng-template>

				<ng-template pTemplate="body" let-item let-columns="columns">
					<tr class="p-selectable-row" (click)="onRowClick(item)">
						<td style="line-height: normal">
							<span *ngIf="!canDelete(item)">
								<i class="fa fa-exclamation-triangle"></i>
							</span>
							<p-tableCheckbox *ngIf="canDelete(item)" [value]="item" (select)="$event.stopPropagation()"></p-tableCheckbox>
						</td>
						<ng-container *ngFor="let col of columns">
							<td>
								<span class="p-column-title">{{col.header}}</span>
								{{item[col.field]}}
								<span *ngIf="item[col.field] === null">-</span>
							</td>
						</ng-container>
					</tr>
				</ng-template>

				<ng-template pTemplate="emptymessage" let-columns>
					<tr>
						<td [attr.colspan]="columns.length + 1">Geen data gevonden.</td>
					</tr>
				</ng-template>
			</p-table>

		</p-card>

<p-sidebar position="right"
		   [style]="{width:'40rem'}"
		   [showCloseIcon]="true"
		   [(visible)]="showSidebar"
		   [blockScroll]="true">
	<base-filters-sidebar
		(onPanelChange)="panelUpdated($event)"
		[groupOptions$]="groupOptions$"
		[panelSubject]="panelSubject">
	</base-filters-sidebar>
</p-sidebar>

<p-sidebar position="left"
		   [style]="{width:'32rem', height: '100%'}"
		   styleClass="p-d-flex"
		   [showCloseIcon]="true"
		   [(visible)]="showRowDetail"
		   [blockScroll]="true">
	<base-incident-detail *ngIf="selectedRow !== null && showRowDetail"
		[inputPanel]="panel"
		[data_id]="selectedRow.data_id">
	</base-incident-detail>
</p-sidebar>

<p-confirmDialog #cd [style]="{width: '30rem'}" [baseZIndex]="10000">
	<p-footer>
		<button pButton pRipple type="button" label="Nee" class="p-button-secondary p-button-text p-button-rounded p-mr-2"
				(click)="cd.reject()"></button>
		<button pButton pRipple type="button" label="Ja, verwijder" class="p-button-rounded p-text-uppercase"
				(click)="cd.accept()"></button>
	</p-footer>
</p-confirmDialog>