import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChange} from '@angular/core';
import {Filter, FilterField, IncidentField, QueryConfiguration, StoredConfig} from "../../../types/sherpa";
import _ from "lodash";
import {Observable, Subscription} from "rxjs";
import {TreeNode} from "primeng/api";
import {AnalysePanel} from "../../../classes/models/AnalysePanel";
import {ConfigsService} from "../../../services/data/configs.service";

@Component({
	selector: 'base-filter-multiple',
	templateUrl: './filter-multiple.component.html',
	styleUrls: ['./filter-multiple.component.scss']
})
export class FilterMultipleComponent implements OnInit {

	@Input() filter: Filter;
	@Input() panelObservable: Observable<AnalysePanel>;
	subscription: Subscription;

	@Output() onFilterFieldChanged: EventEmitter<FilterField> = new EventEmitter<FilterField>();

	collapsed: boolean = true;
	fieldLabel: string = null;

	treeNodes: TreeNode[] = [];
	selectedNodes: TreeNode[];
	relevantFiltersOnly: boolean = false;
	loading: boolean = false;
	noInitValues: boolean;

	constructor(private cd: ChangeDetectorRef, private configsService: ConfigsService) {
	}

	ngOnInit(): void {
		this.initTree(); // create tree object
		this.noInitValues = this.filter.values === null;

		this.subscription = this.panelObservable.subscribe(panel => {
			if(panel === null) {
				return;
			}

			this.relevantFiltersOnly = panel.relevantFiltersOnly;
			this.setSelectedValues(panel.andFilterFields);
			this.cd.markForCheck();
		});
	}

	initTree() {
		let key: number = 1;
		let nodes: TreeNode[] = [];

		_.forEach(this.filter.values, item => {
			let node = {
				key: `${key}`,
				selectable: true,
				leaf: true,
				label: item.label,
				data: item.value
			} as TreeNode;

			nodes.push(node);
			key++;
		});

		let root = {
			key: 'root',
			selectable: true,
			expanded: true,
			styleClass: 'no-children',
			label: `--alles--`,
			children: nodes
		} as TreeNode;

		this.treeNodes = [root];
	}

	done() {
		const childrenOnly = _.filter(this.selectedNodes, node => {
			return !_.isNil(node.data);
		});

		const valuesOnly = _.map(childrenOnly, 'data');

		// trigger parent
		this.onFilterFieldChanged.emit({key: this.filter.key, expectedValue: valuesOnly});

		this.updateFieldLabel();
	}

	setSelectedValues(newFilterFieldConfig: FilterField[]) {
		let matchedField = _.find(newFilterFieldConfig, ['key', this.filter.key]) || {} as FilterField;

		this.selectedNodes = [];
		_.forEach(this.treeNodes[0].children, node => {
			if(_.includes(matchedField.expectedValue, node.data)) {
				this.treeNodes[0].partialSelected = true;
				this.selectedNodes.push(node);
			}
		});

		// update the displayed label
		this.updateFieldLabel();
	}

	backendSearch(event: any) {
		//console.log(event);

		if(!this.noInitValues) {
			return;
		}

		const searchQuery: string = event.filter;

		if(searchQuery.length > 1) {
			this.search(event.filter);
		}
	}

	search(qry: string) {

		this.loading = true;
		this.configsService.searchFilter(this.filter.key, qry)
			.then(results => {
				//console.log('found', results.length);
				this.loading = false;

				this.filter.values = results;
				this.initTree();
				this.selectedNodes = [];
				this.cd.markForCheck();
			});
	}

	updateFieldLabel() {
		this.fieldLabel = null;

		const childrenOnly = _.filter(this.selectedNodes, node => {
			return !_.isNil(node.data);
		});

		if(childrenOnly.length) {
			this.fieldLabel = `(${childrenOnly.length} geselecteerd)`;
		}
	}

	getCaption() {
		this.fieldLabel = null;

		const checkboxes = _.filter(this.selectedNodes, node => {
			return !_.isNil(node.data);
		});

		if(checkboxes.length) {
			let label = checkboxes.length === 1 ? this.selectedNodes[0].label : checkboxes.length.toString();
			this.fieldLabel = `${label} geselecteerd`;
		}
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
