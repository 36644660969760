<div class="p-d-flex p-flex-wrap p-ai-center p-mb-5">

	<div>{{iriasConfig.getTranslation('panels.filter_title_1')}}</div>
	<div class="p-pr-3 p-pl-3">
		<ng-container *ngIf="selectedField !== null && type === 'yearly'">
			<p-dropdown [options]="yearRangeForYearly"
						[(ngModel)]="fromValue"
						[showClear]="true"
						placeholder="jaar"
						optionLabel="label"
						(onChange)="filterYearlyChanged(); applyFilters();"
						[showClear]="false">
			</p-dropdown>
		</ng-container>
		<ng-container *ngIf="selectedField !== null && type === 'monthly'">
			<p-calendar [(ngModel)]="fromDate"
						view="month"
						dateFormat="M | yy"
						placeholder="datum"
						inputStyleClass="calendar-input"
						(onSelect)="filterMonthlyChanged(); applyFilters();"
						[showIcon]="true"
						icon="pi pi-chevron-down"
						[yearNavigator]="true"
						[minDate]="minDateLimit"
						[maxDate]="maxDateLimit"
						[yearRange]="yearRangeForMonthly"
						[readonlyInput]="true"></p-calendar>
		</ng-container>
	</div>
	<div>{{iriasConfig.getTranslation('panels.filter_title_2')}}</div>
	<div class="p-pr-3 p-pl-3">
		<ng-container *ngIf="selectedField !== null && type === 'yearly'">
			<p-dropdown [options]="yearRangeForYearly"
						[(ngModel)]="toValue"
						[showClear]="true"
						placeholder="jaar"
						optionLabel="label"
						(onChange)="filterYearlyChanged(); applyFilters();"
						[showClear]="false">
			</p-dropdown>
		</ng-container>
		<ng-container *ngIf="selectedField !== null && type === 'monthly'">
			<p-calendar [(ngModel)]="toDate"
						view="month"
						dateFormat="M | yy"
						placeholder="datum"
						inputStyleClass="calendar-input"
						(onSelect)="filterMonthlyChanged(); applyFilters();"
						[showIcon]="true"
						icon="pi pi-chevron-down"
						[yearNavigator]="true"
						[minDate]="minDateLimit"
						[maxDate]="maxDateLimit"
						[yearRange]="yearRangeForMonthly"
						[readonlyInput]="true"></p-calendar>
		</ng-container>
	</div>
</div>