import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnalysePanel} from "../../../classes/models/AnalysePanel";
import {Filter, FilterField, GroupOption} from "../../../types/sherpa";
import _ from "lodash";
import {util} from "../../../../services";
import {CaptionService} from "../../../services/caption.service";
import {BehaviorSubject} from "rxjs";
import {ConfigsService} from "../../../services/data/configs.service";
import {ImportService} from "../../../services/data/import.service";

@Component({
	selector: 'base-filters-topbar',
	templateUrl: './filters-topbar.component.html',
	styleUrls: ['./filters-topbar.component.scss']
})
export class FiltersTopbarComponent implements OnInit {

	@Input() initPanel: AnalysePanel;
	@Input() groupOptions$: Promise<GroupOption[]>;
	@Input() preview: boolean = false;

	@Output() onPanelChange: EventEmitter<AnalysePanel> = new EventEmitter<AnalysePanel>();

	filters: Filter[] = [];
	filtersMap: Map<string, Filter> = new Map<string, Filter>();
	mkFilter: Filter = null;

	panel: AnalysePanel = null;
	submitted: boolean = true;
	selectedField: FilterField = null;
	selectedFilterEdit: any = null;
	showEditFilterDialog: boolean = false;
	groupOptions: GroupOption[] = [];

	constructor(private captionService: CaptionService, private incidentsService: ImportService) {
	}

	ngOnInit(): void {
		this.panel = this.initPanel;
		this.updateFilters(this.panel.filters);

		this.groupOptions$.then(options => {
			this.groupOptions = [];

			_.forEach(options, groupOption => {
				if (groupOption.subGroups === null) {
					this.groupOptions.push(groupOption);
					return; // continue;
				}

				_.forEach(groupOption.subGroups, sub => {
					this.groupOptions.push(sub);
				});
			});
		});

		if(this.panel.type === 'map') {
			let config = _.cloneDeep(this.panel);
			config.analyse_type = 'mk_incidents';

			this.incidentsService.getFilters(config).then(result => {
				this.mkFilter = _.head(result);
			});
		}
	}

	updateFilters(updatedFilters: Filter[]) {
		this.filters = updatedFilters;
		this.filtersMap.clear();

		// these are special-filters, they are not in getFilters()
		this.filtersMap.set('extent', {title: 'Gebied'} as Filter);
		this.filtersMap.set('mk_code', {title: 'MK-code'} as Filter);

		_.forEach(updatedFilters, filter => {
			this.filtersMap.set(filter.key, filter);
		});
	}

	filterChanged(filterField: FilterField) {

		// changes found but no api-call yet
		this.submitted = false;

		// update special cases
		if (filterField.key === 'extent') {
			this.panel.extent = filterField.expectedValue;
		} else if (filterField.key === 'orderByIncidents') {
			this.panel.orderByIncidents = filterField.expectedValue;
		} else if (filterField.key === 'top') {
			this.panel.top = filterField.expectedValue;
		} else if (filterField.key === 'groupBy') {
			this.panel.groupBy = filterField.expectedValue;
		} else if (filterField.key === 'sortOrder') {
			this.panel.sortOrder = filterField.expectedValue;
		} else {
			this.panel.andFilterFields = util.upsert(this.panel.andFilterFields, filterField, 'key');
		}
	}

	applyFilters() {
		// remove nulls
		this.panel.andFilterFields = _.filter(this.panel.andFilterFields, (ff: FilterField) => {
			return ff.expectedValue !== null && _.size(ff.expectedValue) > 0;
		});

		this.submitted = true;
		this.onPanelChange.emit(this.panel);
	}

	getButtonTagLabel(field: FilterField) {

		// special case
		if(field.key === 'mk_code') {
			const found = _.find(this.panel.andFilterFields, ['key', field.key]);
			field.expectedValue = _.isUndefined(found) ? [] : found.expectedValue;

			return this.captionService.getButtonLabel([this.mkFilter], field);
		}

		return this.captionService.getButtonLabel(this.filters, field);
	}

	openSingleFilter(field: FilterField) {
		this.selectedField = field;
		this.showEditFilterDialog = true;
	}
}
