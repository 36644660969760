import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'base-analyses-new',
  templateUrl: './analyses-new.component.html',
  styleUrls: ['./analyses-new.component.scss']
})
export class AnalysesNewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
