import {Injectable} from '@angular/core';
import {BackendFunctions, Sherpa} from "../../classes/Sherpa";
import {auth, sherpa, util} from "../../../services";
import {SherpaError} from "../../classes/SherpaError";
import {
	IncidentsGrid,
	SourceView,
	SourceViewWithMessages,
	DataFields,
	Filter, GroupOption, QueryConfiguration, MultiChartQueryConfiguration, UpdateIncident
} from "../../types/sherpa";
import _ from "lodash";
import {AnalysePanel} from "../../classes/models/AnalysePanel";

@Injectable({
	providedIn: 'root'
})
export class ImportService {

	private api: BackendFunctions = {};
	public panelAdditionProperties = ['index', 'visible', 'reload', 'name', 'resultData', 'orgResultData', 'filters', 'kind', 'chartInstance'];

	constructor() {
		sherpa.api$.subscribe((sherpa: Sherpa) => {
			this.api = sherpa.api;
		});
	}

	getSourceMessages(importsource_id: number): Promise<SourceViewWithMessages> {
		return this.api.getSourceMessages<SourceViewWithMessages>(auth.token(), importsource_id)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getImports(): Promise<SourceView[]> {
		return sherpa.api$.toPromise().then((sherpa: Sherpa) => {
			return sherpa.api.getImports<SourceView[]>(auth.token())
				.catch((e: SherpaError) => util.handleError(e));
		});
	}

	commitImport(importsource_id: number, overwrite_non_uniques: boolean): Promise<any> {
		return this.api.commitImport<any>(auth.token(), importsource_id, overwrite_non_uniques)
			.catch((e: SherpaError) => util.handleError(e));
	}

	cancelImport(importsource_id: number): Promise<any> {
		return this.api.cancelImport<SourceViewWithMessages>(auth.token(), importsource_id)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getFields(): Promise<DataFields> {
		return sherpa.api$.toPromise().then((sherpa: Sherpa) => {
			return sherpa.api.getFields<DataFields>(auth.token())
				.catch((e: SherpaError) => util.handleError(e));
		});
	}

	getFilters(panel: AnalysePanel): Promise<Filter[]> {
		let config = _.omit(panel, this.panelAdditionProperties);

		return sherpa.api$.toPromise().then((sherpa: Sherpa) => {
			return sherpa.api.getFilters<Filter[]>(auth.token(), config)
				.catch((e: SherpaError) => util.handleError(e));
		});
	}

	getGroupOptions(): Promise<GroupOption[]> {
		return sherpa.api$.toPromise().then((sherpa: Sherpa) => {
			return sherpa.api.getGroupOptions<GroupOption[]>(auth.token())
				.catch((e: SherpaError) => util.handleError(e));
		});
	}

	getIncidentsGrid(panel: AnalysePanel, grid: number = 10000, opt_extent: any = null): Promise<IncidentsGrid[]> {
		panel = _.cloneDeep(panel);

		let config = _.omit(panel, this.panelAdditionProperties);
		config.includeFields = ["geom"];

		if(opt_extent !== null) {
			panel.extent = opt_extent;
		}

		return this.api.getIncidentsGrid<IncidentsGrid[]>(auth.token(), grid, config)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getResultForPanel(panel: AnalysePanel): Promise<any> {
		const config = _.omit(panel, this.panelAdditionProperties);
		const specialCases = ['kwh_per_paal', 'rfid_per_paal']; // laadpaal naar laadpunt

		return this.api.getResultForPanel<any>(config)
			.then(result => {

				// @ts-ignore
				if(_.includes(specialCases, config.measurement)) {
					_.forEach(result.datasets, ds => {
						const newData: number[] = [];

						// todo
						// waarde delen door 2, we hebben alleen waarde voor laadpaal,
						// een laadpaal bestaat uit (gem.) 2 laadpunten, dus delen door 2
						// dit is een tijdelijk workaround tot wanneer we de externe api gaan gebruiken
						_.forEach(ds.data, data => {
							newData.push(_.divide(data, 2));
						});

						ds.data = newData;
					});
				}

				return result;
			})
			.catch((e: SherpaError) => util.handleError(e));
	}

	getDataForTable(panel: AnalysePanel) {
		panel = _.cloneDeep(panel);
		const partial = _.omit(panel, this.panelAdditionProperties);

		return this.api.getData<any>(auth.token(), partial)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getIncidents(panel: AnalysePanel, opt_extent: any = null, opt_police: boolean = false): Promise<any> {
		panel = _.cloneDeep(panel);

		let config = _.omit(panel, this.panelAdditionProperties);
		config.includeFields = ["geom"];

		if(opt_extent !== null) {
			config.extent = opt_extent;
		}

		config.table = 'data';

		return this.api.getIncidents<any>(auth.token(), config)
			.catch((e: SherpaError) => util.handleError(e));
	}

	deleteBulkData(items: any[]): Promise<void> {
		return this.api.deleteData<void>(auth.token(), items)
			.catch((e: SherpaError) => util.handleError(e));
	}

	saveFilterSettings(filter : Filter[]): Promise<any> {
		return this.api.saveFilterSettings<any>(auth.token(), filter)
			.catch((e: SherpaError) => util.handleError(e));
	}

	checkIsVervoerder() {
		return this.api.checkIsVervoerder<boolean>(auth.token())
			.catch((e: SherpaError) => util.handleError(e));
	}

	checkIsAdmin() {
		return this.api.checkIsAdmin<boolean>(auth.token())
			.catch((e: SherpaError) => util.handleError(e));
	}

	requestExportCSV(panel: AnalysePanel) {
		panel = _.cloneDeep(panel);
		let config = _.omit(panel, this.panelAdditionProperties);
		config.table = "data";

		return this.api.exportToCSV<string>(auth.token(), config)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getColors(key: string): Promise<any[]> {
		return this.api.getColors<any[]>(auth.token(), key)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getLimits(): Promise<any[]> {

		return sherpa.api$.toPromise().then((sherpa: Sherpa) => {
			return sherpa.api.getLimits<any[]>()
				.catch((e: SherpaError) => util.handleError(e));
		});
	}
}
