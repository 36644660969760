
<div [style.cursor]="filter.values === null || filter.values?.length ? 'pointer' : 'default'"
	 (click)="(filter.values === null || filter.values?.length) && overLay.toggle($event);">
	<div class="fake-dropdown p-d-flex p-ai-center p-unselectable-text">

		<span class="p-mr-auto">{{filter.title}} <span *ngIf="fieldLabel !== null" class="p-text-bold">{{fieldLabel}}</span></span>

		<ng-container *ngIf="filter.values?.length === 0">
			<span class="p-text-italic p-text-light" style="color: #959595;">geen opties gevonden</span>
		</ng-container>

		<div class="fake-dropdown-icon pi p-ml-3"
			 *ngIf="filter.values === null || filter.values?.length"
			 [ngClass]="collapsed ? 'pi-chevron-down' : 'pi-chevron-up'"></div>
	</div>
</div>

<p-overlayPanel #overLay appendTo="body" styleClass="fake-dropdown-overlay p-shadow-0 p-mt-0" [style]="{width: '608px'}"
				(onShow)="collapsed = false;"
				(onHide)="collapsed = true;"
				[showCloseIcon]=""
				[dismissable]="true">
	<ng-template pTemplate>
			<ng-container *ngIf="filter.values === null">
				<div class="p-text-italic p-text-light p-p-5" style="color: #959595;">
					<span>
						Gebruik overige filters om de selectie te verfijnen, zodat een lijst getoond wordt. Hiervoor dient u een vinkje te zetten bij 'Toon alleen relevante filteropties'.
						U kunt ook het zoekveld gebruiken.
					</span>
				</div>
			</ng-container>
			<p-tree selectionMode="checkbox" filterPlaceholder="Typ om te zoeken.."
					[filter]="true"
					(onFilter)="backendSearch($event)"
					[loading]="loading"
					(onNodeSelect)="done()"
					(onNodeUnselect)="done()"
					[value]="treeNodes"
					[(selection)]="selectedNodes"></p-tree>
	</ng-template>
</p-overlayPanel>
