
<div class="p-field-checkbox p-mb-1 p-mt-1 p-ml-auto p-unselectable-text p-d-print-none" *ngIf="!presentationMode">
	<p-checkbox inputId="bg"
		[(ngModel)]="showBackground"
		[disabled]="!showValues"
		[binary]="true" ></p-checkbox>
	<label [for]="'bg'">Toon achtergrondkleuren</label>
</div>

<div class="p-field-checkbox p-mb-3 p-mt-1 p-ml-auto p-unselectable-text p-d-print-none" *ngIf="!presentationMode">
	<p-checkbox inputId="val"
				[(ngModel)]="showValues"
				[disabled]="!showBackground"
				(ngModelChange)="setShowValues.emit(showValues)"
				[binary]="true" ></p-checkbox>
	<label [for]="'val'">Toon labels</label>
</div>

<p-table styleClass="p-datatable-dova"
	[value]="tableData"
	[autoLayout]="true"
	[responsive]="true">
	<ng-template pTemplate="caption">

	</ng-template>
	<ng-template pTemplate="header">
		<tr>
			<th rowspan="2"><strong>{{title}}</strong></th>
			<th [colSpan]="customColumns.length">
				Aantal incidenten <span *ngIf="customColumns.length > 1">per <strong>{{colgroup}}</strong></span>
			</th>
		</tr>
		<tr *ngIf="customColumns.length > 1">
			<th *ngFor="let key of customColumns" class="fixed-column">
				<div class="p-d-flex p-ai-center">
					<span *ngIf="showValues">{{key}}</span>
				</div>
			</th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-item>
		<tr class="data-row">
			<td class="p-d-flex p-ai-center" class="fixed-column">
				<strong>{{item.label}}</strong>
			</td>
			<td class="data-values" *ngFor="let key of customColumns"
				[ngStyle]="getBackgroundStyle(item[key])"
				[tooltipDisabled]="showValues"
				[pTooltip]="key +': '+ item[key]"
				[showDelay]="250"
				tooltipPosition="left"
				tooltipEvent="hover">
				<span *ngIf="showValues">{{item[key]}}</span>
			</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="footer">
		<tr *ngIf="showValues">
			<td class="p-text-right"><strong>Totaal</strong></td>
			<td *ngFor="let key of customColumns">
				{{getTotal(key)}}
			</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="emptymessage">
		<tr>
			<td [attr.colspan]="customColumns.length + 1">
				Geen data gevonden..
			</td>
		</tr>
	</ng-template>
</p-table>