<!-- TOOLBAR ANALYSE -->
<div class="sticky-toolbar p-d-print-none" *ngIf="!presentationMode">
	<p-toolbar styleClass="">
		<div class="p-toolbar-group-left">
			<button pButton pRipple type="button" (click)="myFiltersPanel.toggle($event);"
					class="p-mr-2"
					icon="fal fa-filter"
					label="Mijn analyses"></button>

			<button pButton pRipple (click)="selectedStoredConfig = null; save_menu.toggle($event);"
					class="p-mr-2"
					icon="fal fa-lg fa-save"></button>

			<p-menu #save_menu id="save_menu" [model]="saveMenu" [popup]="true" appendTo="body"></p-menu>


			<button pButton pRipple (click)="openPresentation()"
					class="p-button-icon-only"
					icon="fal fa-lg fa-print"></button>

		</div>

		<div class="p-toolbar-group-right">
			<p-multiSelect [options]="panels"
						   [(ngModel)]="selectedPanels"
						   (ngModelChange)="selectTogglePanel($event)"
						   [filter]="false"
						   [maxSelectedLabels]="2"
						   optionLabel="name"
						   scrollHeight="400px"
						   selectedItemsLabel="{0} visualisaties geselecteerd"
						   defaultLabel="Selecteer visualisaties"
						   styleClass="select-visuals">

				<ng-template let-panel pTemplate="item">
					<div class="p-d-flex p-ai-center">
						<ng-container
							*ngTemplateOutlet="chartIcons; context: {type: panel.type, stacked: panel.stacked, size: 20}">
						</ng-container>
						<div class="p-pl-2">{{panel.name}}</div>
					</div>
				</ng-template>
			</p-multiSelect>
		</div>
	</p-toolbar>
</div>

<!-- TOOLBAR PRINT -->
<div class="presentation-toolbar p-d-flex p-p-3 p-d-print-none" *ngIf="presentationMode">

	<button pButton pRipple type="button" (click)="print()"
			class="p-button-rounded p-button-outlined p-button-secondary"
			label="Afdrukken"></button>

	<button pButton pRipple type="button" (click)="downloadFile()"
			class="p-button-rounded p-button-outlined p-button-secondary p-ml-2"
			[disabled]="pdfGenerating"
			[label]="pdfGenerating ? 'Pdf genereren..' : 'Download pdf'"></button>

	<p-progressSpinner
		*ngIf="pdfGenerating"
		[style]="{width: '1.5rem', height: '1.5rem'}"
		styleClass="p-ml-3"
		class="p-d-flex p-as-center"
		strokeWidth="6"
		fill="#EEEEEE"
		animationDuration=".5s"></p-progressSpinner>

	<button type="button" pButton pRipple (click)="closePresentation();"
			icon="pi pi-times"
			class="p-ml-auto p-button-rounded p-button-text p-button-secondary"></button>
</div>


<p-messages severity="info" styleClass="p-p-2" *ngIf="selectedPanels.length === 0">
	<ng-template pTemplate>
		<div>Geen visualisaties gekozen..</div>
	</ng-template>
</p-messages>

<!-- OUTLET -->
<div class="p-grid p-mt-3" *ngIf="!presentationMode">
	<ng-container *ngFor="let panel of panels;">
		<ng-container *ngTemplateOutlet="chartPanel; context: {panel: panel}"></ng-container>
	</ng-container>
</div>

<div *ngIf="presentationMode">
	<ng-container *ngTemplateOutlet="printMode"></ng-container>
</div>


<!-- PRESENTATION-MODE TEMPLATE -->
<ng-template #printMode>

	<div class="p-d-print-none" [style]="{'margin-top': '100px'}"></div>

	<div class="p-d-flex p-ai-center p-flex-column">

		<ng-container *ngFor="let panel of selectedPanels; let i = index; let last = last;">
			<ng-container *ngIf="panel.reload">

				<div [ngClass]="{'p-shadow-2':!showReportPages}" class="page4 p-p-5">

					<div class="pager p-d-print-none" *ngIf="!showReportPages">{{i + 1}}</div>

					<div class="page-content preview">

						<h1 class="p-mt-0" *ngIf="!showReportPages">{{panel.caption}}</h1>
						<h1 class="p-mt-0 p-text-bold" style="color: #9D4D96" *ngIf="showReportPages">{{i + 2}}
							. {{panel.caption}}</h1>

						<base-filters-topbar
							(onPanelChange)="panelUpdated($event)"
							[groupOptions$]="groupByOptions$"
							[initPanel]="panel"
							[preview]="true">
						</base-filters-topbar>

						<ng-container *ngIf="isChart(panel.type) && refreshed">
							<div class="chart-container"
								 *ngIf="panel.resultData !== null && panel.resultData.labels?.length else cardEmpty">
								<p-chart [type]="panel.type"
										 [data]="panel.resultData"
										 [options]="panel.chartOptions"
										 [plugins]="[]"
										 [responsive]="true"
										 [height]="'125mm'">
								</p-chart>
							</div>
						</ng-container>

						<ng-container *ngIf="panel.type === 'table'">
							<div class="table-container"
								 *ngIf="panel.resultData !== null && panel.resultData.labels?.length else cardEmpty">
								<base-incident-table
									[presentationMode]="presentationMode"
									[data]="panel.resultData"
									[groupOptions$]="groupByOptions$"
									[panelGroupBy]="panel.groupBy"
									[showValues]="panel.show_labels"
									(setShowValues)="panel.show_labels = $event;">
								</base-incident-table>
							</div>
						</ng-container>

						<ng-container *ngIf="panel.resultData !== null && panel.type === 'map'">
							<div class="map-container" [style]="{'height': '125mm', 'position': 'relative'}">
								<div id="incidents-map" class="map"></div>

								<div class="legend-map" *ngIf="currZoom > zoomSwitchLevel">
									<h3 class="p-mt-0 p-mb-1">Legenda</h3>

									<ul>
										<li *ngFor="let c of mapColors">
											<span class="p-pr-2">
												<i class="fa fa-square" [style.color]="c.color"></i>
											</span>
											<span>{{c.label}}</span>
										</li>
										<li>
											<span class="p-pr-2">
												<i class="fa fa-square" [style.color]="policeColor"></i>
											</span>
											<span>Politie</span>
										</li>
									</ul>
								</div>
							</div>
						</ng-container>

						<div class="content"
							 *ngIf="panel.description !== null"
							 [innerHTML]="asMarkdown(panel.description)">
						</div>

					</div>

					<div class="footer p-mb-2 p-d-flex" *ngIf="showReportPages">
						<div>Maandelijkse rapportage sociale veiligheid in het openbaar
							vervoer {{ periodDescription }}</div>
						<div class="p-ml-auto">{{i + 4}}</div>
					</div>

				</div>

				<div *ngIf="!showReportPages" class="p-mb-3 p-d-print-none"></div>

				<div *ngIf="last && !showReportPages" class="p-mb-3 p-d-print-none"></div>

			</ng-container>
		</ng-container>

		<ng-container *ngIf="false">
			<div class="page4 p-p-5 p-d-flex p-flex-column" id="page-appendix-1">
				<div class="head p-mb-5">
					<img src="assets/img/rapport_logo_dova.jpg"/>
				</div>
				<div class="body p-d-flex p-flex-column index p-p-5">
					<h1>Bijlagen</h1>

					<div class="appendix-table p-datatable-dova p-datatable">
						<table>
							<thead class="p-datatable-thead">
							<tr>
								<th>Naam</th>
								<th>Type</th>
								<th colspan="3">Definitie</th>
								<th>Verplicht</th>
							</tr>
							</thead>
							<tbody class="p-datatable-tbody"></tbody>
						</table>
					</div>
				</div>
				<div class="footer p-mt-6 p-mb-2 p-d-flex">
					<div>Maandelijkse rapportage sociale veiligheid in het openbaar vervoer</div>
					<div class="p-ml-auto"></div>
				</div>
			</div>
		</ng-container>


	</div>

</ng-template>

<!-- ANALYSE-MODE TEMPLATE -->
<ng-template #chartPanel let-panel="panel">

	<ng-container *ngIf="panel !== null && panel.visible">

		<div [ngClass]="panel.size === null ? 'p-col' : 'p-col-' + panel.size">

			<p-card styleClass="analyse-card shadow-mrae">
				<ng-template pTemplate="header">
					<ng-container *ngTemplateOutlet="panelHeader; context: {panel: panel}"></ng-container>
				</ng-template>

				<ng-container *ngTemplateOutlet="panelToolbar; context: {panel: panel}"></ng-container>

				<ng-container *ngIf="isChart(panel.type)">
					<irias-chart [panel]="panel"
								 [subject]="subject">
					</irias-chart>
				</ng-container>

				<ng-container *ngIf="panel.type === 'table'">
					<div class="table-container"
						 *ngIf="panel.resultData !== null && panel.resultData.labels?.length else cardEmpty">
						<p-scrollPanel [style]="{width: '100%', height: '70vh'}" styleClass="customScroll">
							<base-incident-table
								[data]="panel.resultData"
								[groupOptions$]="groupByOptions$"
								[panelGroupBy]="panel.groupBy"
								[showValues]="panel.show_labels"
								(setShowValues)="panel.show_labels = $event;">
							</base-incident-table>
						</p-scrollPanel>
					</div>
				</ng-container>

				<ng-container *ngIf="panel.resultData !== null && panel.type === 'map'">
					<div class="map-container" [style]="{'height': '70vh'}">
						<div class="p-field-checkbox map-checkbox">
							<p-checkbox [(ngModel)]="panel.show_police" (onChange)="moveEndHandler()"
										[binary]="true"
										inputId="binaryPolice"></p-checkbox>
							<label [for]="'binaryPolice'">Toon politie-incidenten</label>
						</div>

						<div tabindex="1" id="incidents-map" class="map"></div>


						<div class="legend-map" *ngIf="currZoom <= zoomSwitchLevel">
							<div class="p-p-1">
								<p class="p-m-0">
									Kaart toont een indicatie van aantal incidenten in een gebied. Donkere kleur
									betekent meer incidenten.
								</p>
								<p class="p-m-0">
									Zoom in om individuele incidenten te bekijken.
								</p>
							</div>
						</div>

						<div class="legend-map" *ngIf="currZoom > zoomSwitchLevel">
							<h3 class="p-mt-0 p-mb-1">Legenda</h3>

							<ul>
								<li *ngFor="let c of mapColors">
											<span class="p-pr-2">
												<i class="fa fa-square" [style.color]="c.color"></i>
											</span>
									<span>{{c.label}}</span>
								</li>
								<li>
											<span class="p-pr-2">
												<i class="fa fa-square" [style.color]="policeColor"></i>
											</span>
									<span>Politie</span>
								</li>
							</ul>
						</div>

					</div>
				</ng-container>

				<p-footer>

					<p-inplace
						[closable]="true"
						[closeIcon]="'far fa-check'"
						styleClass="caption">
						<ng-template pTemplate="display">

							<ng-container *ngIf="panel.description === null || !panel.description?.length">
								<button pButton pRipple [style]="{'border': '2px dashed #eee'}"
										class="p-button-plain p-button-text"
										label="Beschrijving"
										icon="pi pi-plus"></button>
							</ng-container>

							<ng-container *ngIf="panel.description !== null">
								<div class="content"
									 [innerHTML]="asMarkdown(panel.description)">
								</div>

								<i class="fa fa-pencil fa-lg"></i>
							</ng-container>

						</ng-template>
						<ng-template pTemplate="content">
									<textarea pInputTextarea placeholder="Beschrijving.."
											  [className]="'p-mr-1'"
											  [style]="{'width': '100%'}"
											  [(ngModel)]="panel.description"
											  [rows]="5"
											  [autoResize]="true"></textarea>
						</ng-template>
					</p-inplace>

				</p-footer>

			</p-card>

		</div>
	</ng-container>

</ng-template>


<!-- SAVE ANALYSE DIALOG -->
<p-dialog *ngIf="showSaveFilterDialog"
		  [header]="selectedStoredConfig === null ? 'Nieuwe analyse opslaan' : 'Analyse bewerken'"
		  [(visible)]="showSaveFilterDialog"
		  [draggable]="false"
		  [resizable]="false"
		  [modal]="true"
		  [style]="{width: '50vw'}"
		  [baseZIndex]="10000">
	<base-my-filter [storedConfig]="selectedStoredConfig"
					[myConfigs]="myStoredConfigs"
					[panels]="panels"
					(onSave)="afterSaveFilter($event)"
					(onDelete)="afterDeleteFilter($event)"
					(onClose)="selectedStoredConfig = null; showSaveFilterDialog = false;"></base-my-filter>
</p-dialog>

<!-- MY-STORED ANALYSES OVERLAY -->
<p-overlayPanel #myFiltersPanel [style]="{width: '450px'}" [showCloseIcon]="true" [dismissable]="false">
	<ng-template pTemplate>
		<p-table [value]="myStoredConfigs" selectionMode="single" styleClass="p-datatable-dova"
				 [paginator]="true"
				 (onRowSelect)="onRowSelect($event)"
				 [rows]="5"
				 [alwaysShowPaginator]="false"
				 [autoLayout]="true">
			<ng-template pTemplate="header">
				<tr>
					<th pSortableColumn="name">Naam
						<p-sortIcon field="name"></p-sortIcon>
					</th>
					<th>Toon in menu</th>
					<th></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-myFilter>
				<tr [pSelectableRow]="myFilter">
					<td>{{myFilter.label}}</td>
					<td>{{myFilter.show_in_menu ? 'ja' : 'nee'}}</td>
					<td class="p-text-right">
						<button pButton pRipple (click)="openSaveFilterDialog(myFilter, false, false);"
								class="p-button-rounded p-button-secondary p-button-text"
								icon="fa fa-pencil">
						</button>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</ng-template>
</p-overlayPanel>

<!-- FILTER-SIDEBAR -->
<p-sidebar position="right"
		   [style]="{width:'40rem'}"
		   [showCloseIcon]="true"
		   [(visible)]="showSidebar"
		   [blockScroll]="true">
	<base-filters-sidebar
		(onPanelChange)="panelUpdated($event)"
		[groupOptions$]="groupByOptions$"
		[panelSubject]="panelSubject">
	</base-filters-sidebar>
</p-sidebar>

<p-dialog *ngIf="showDownloadDialog"
		  [header]="'Download incidenten'"
		  [(visible)]="showDownloadDialog"
		  [draggable]="false"
		  [resizable]="false"
		  [modal]="true"
		  [style]="{width: '50vw'}"
		  [baseZIndex]="1500">
	<base-link
		[action]="'Download'"
		[showLink]="false"
		[message]="'Klik om de incidenten te downloaden die gebruikt worden in deze grafiek, tabel of kaart.'"
		[url]="downloadUrl"></base-link>

	<ng-template pTemplate="footer">
		<div class="p-d-flex p-jc-end">

			<button pButton pRipple type="button" (click)="showDownloadDialog = false;"
					label="Sluiten"
					class="p-button-secondary p-button-text p-button-rounded p-mr-2"></button>
		</div>
	</ng-template>

</p-dialog>

<!-- PANEL TEMPLATES -->

<!-- panelHeader -->
<ng-template #panelHeader let-panel="panel">

	<div class="p-d-flex p-ai-center p-py-3">

		<p-inplace [closable]="true" [closeIcon]="'far fa-check'" styleClass="caption">

			<ng-template pTemplate="display">

				<ng-container *ngIf="!panel.caption?.length">
					<button pButton pRipple
							[style]="{'border': '2px dashed #eee'}"
							class="p-button-plain p-button-text"
							label="Titel"
							icon="far fa-plus"></button>
				</ng-container>
				<ng-container *ngIf="panel.caption?.length">
					<div class="p-d-flex p-ai-center">
						<h1 class="p-m-0 p-pr-2">{{panel.caption}}</h1>
						<i class="fa fa-pencil"></i>
					</div>
				</ng-container>

			</ng-template>
			<ng-template pTemplate="content">
				<input pInputText type="text" style="width: 300px;"
					   class="p-mr-2"
					   placeholder="Titel.."
					   [(ngModel)]="panel.caption">
			</ng-template>

		</p-inplace>

		<button pButton pRipple (click)="deselectPanel(panel);"
				class="p-button-rounded p-button-text p-button-secondary p-ml-auto"
				icon="fal fa-lg fa-times"></button>
	</div>
</ng-template>

<!-- panelToolbar -->
<ng-template #panelToolbar let-panel="panel">

	<div class="p-d-flex p-ai-center">
		<div>
			<base-filters-topbar
				(onPanelChange)="panelUpdated($event)"
				[groupOptions$]="groupByOptions$"
				[initPanel]="panel">
			</base-filters-topbar>
		</div>
		<div class="p-ml-auto">
			<button pButton icon="fad fa-expand-arrows fa-lg"
					type="button" (click)="overLay2.toggle($event);"
					class="p-button-icon-only p-button-text p-mr-2">
			</button>

			<button pButton icon="fad fa-analytics fa-lg"
					type="button" (click)="overLay.toggle($event);" *ngIf="panel.type !== 'map'"
					class="p-button-icon-only p-button-text p-mr-2">
			</button>

			<button pButton icon="fad fa-file-export fa-lg"
					type="button" (click)="requestExportData(panel);"
					class="p-button-icon-only p-button-text p-mr-2">
			</button>

			<button pButton icon="far fa-sliders-h-square fa-lg" iconPos="left"
					label="filteren"
					type="button" (click)="openPanelFilter(panel.index);"
					class="">
			</button>

			<p-overlayPanel #overLay appendTo="body" styleClass="no-arrow"
							[style]="{width: '190px'}"
							[showCloseIcon]=""
							[dismissable]="true">
				<ng-template pTemplate>
					<div class="p-grid">
						<div class="p-col-4 p-p-1" *ngFor="let item of chartToggleOptions">
							<div class="box icon-box"
								 (mouseenter)="setChartToggleHelpText(panel, item)"
								 (mouseleave)="chartToggleHelpText = null"
								 (click)="transformChart(panel.index, item.type, item.stacked)"
								 [ngClass]="{'active':panel.type === item.type && panel.stacked === item.stacked}">
								<ng-container
									*ngTemplateOutlet="chartIcons; context: {type: item.type, stacked: item.stacked, size: 32}"></ng-container>
							</div>
						</div>
						<div class="p-col-12" *ngIf="chartToggleHelpText !== null">
							<p class="p-mb-0 p-unselectable-text">{{chartToggleHelpText}}</p>
						</div>
					</div>
				</ng-template>
			</p-overlayPanel>

			<p-overlayPanel #overLay2 appendTo="body" styleClass="no-arrow"
							[style]="{width: '190px'}"
							[showCloseIcon]=""
							[dismissable]="true">
				<ng-template pTemplate>
					<div class="p-grid">
						<div class="p-col-4 p-p-1" *ngFor="let size of [6, 12, null]">
							<div class="box icon-box"
								 (mouseenter)="autoSizeHelpText = (size === null)"
								 (mouseleave)="autoSizeHelpText = false"
								 (click)="changeSize(panel.index, size); overLay2.hide();"
								 [ngClass]="{'active':panel.size === size}">
								<span *ngIf="size === 6">1</span>
								<span *ngIf="size === 12">2</span>
								<div *ngIf="size === null">auto</div>
							</div>
						</div>
						<ng-container *ngIf="panel.type !== 'map' && panel.type !== 'table'">
							<div class="p-col-6 p-p-1" *ngFor="let size of [1, 2]">
								<div class="box icon-box"
									 (click)="setPanelVerticalSize(panel, size); overLay2.hide();"
									 [ngClass]="{'active': panel.vertical_size === size}">
									<i class="fa fa-arrow-alt-from-right fa-lg" *ngIf="size === 1"></i>
									<i class="fa fa-arrow-alt-from-left fa-lg" *ngIf="size === 2"></i>
								</div>
							</div>
						</ng-container>
						<div class="p-col-12" *ngIf="autoSizeHelpText">
							<p class="p-mb-0 p-unselectable-text">Vult automatisch uit</p>
						</div>
					</div>
				</ng-template>
			</p-overlayPanel>
		</div>
	</div>

</ng-template>

<ng-template #cardEmpty>
	Voor deze visualisatie is er momenteel (nog) geen data beschikbaar.
</ng-template>

<ng-template #defaultPanelHeader>
	<h1>Panel</h1>
</ng-template>

<!-- CHART TYPES ICONS -->
<ng-template #chartIcons let-type="type" let-stacked="stacked" let-size="size">

	<i class="fal" *ngIf="type === 'bar'" [ngClass]="stacked ? 'fa-layer-group' : 'fa-chart-bar'"
	   [style.font-size]="size+'px'"></i>
	<i class="fal fa-flip-and-rotate" [ngClass]="stacked ? 'fa-layer-group' : 'fa-chart-bar'"
	   *ngIf="type === 'horizontalBar'" [style.font-size]="size+'px'"></i>
	<i class="fal fa-chart-line" *ngIf="type === 'line'" [style.font-size]="size+'px'"></i>
	<i class="fal fa-chart-pie" *ngIf="type === 'pie'" [style.font-size]="size+'px'"></i>
	<i class="fal fa-chart-network" *ngIf="type === 'radar'" [style.font-size]="size+'px'"></i>
	<i class="fal fa-table" *ngIf="type === 'table'" [style.font-size]="size+'px'"></i>
	<i class="fal fa-map-marked-alt" *ngIf="type === 'map'" [style.font-size]="size+'px'"></i>


</ng-template>
