import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import _ from 'lodash';
import {SherpaJson} from "../classes/SherpaJson";
import {Sherpa} from "../classes/Sherpa";
import {catchError, map, retry, shareReplay} from 'rxjs/operators';
import {environment} from "../../environments/environment";
import {SherpaError} from "../classes/SherpaError";
import {SherpaResponse} from "../classes/SherpaResponse";
import {Observable, throwError} from "rxjs";

/**
 *    -==[ Usage in components ]==-
 *
 *    Example 1:
 *        this.userService.getUsers()
 *        	.then((users: User[]) => {
 *        	 	this.users = users;
 *        	});
 *
 *    Example 2:
 *        let users$: Promise<User[]> = this.userService.getUsers();
 *        	or
 *        this.users = this.userService.getUsers();
 *
 *
 *    -==[ Usage in data-services ]==-
 *
 *    Example:
 *    	  // constructor data-service
 *        sherpa.auth$.subscribe((sherpa: Sherpa) => {
 * 		 	this.api = sherpa.api;
 * 		  });
 *
 * 		  // within function which is called at page-load
 *        return sherpa.auth$.subscribe((sherpa: Sherpa) => {
 *            sherpa.api.getUsers<User[]>(auth.token())
 *            	.then(result => {
 *            	   this.users = result;
 *            	});
 *        });
 *
 *        // within function which is called at page-action
 *        return this.api.getUsers<User[]>(auth.token());
 */

@Injectable({
	providedIn: 'root'
})
export class SherpaService {

	/* The Sherpa-API's */
	public readonly api$: Observable<Sherpa>;
	public readonly auth$: Observable<Sherpa>;
	public readonly baseReg$: Observable<Sherpa>;

	private readonly httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json'
		})
	};

	/**
	 * The environment-configs should be a sherpa.json endpoint
	 * For example: api/sherpa.json or via sherpa-remote/*
	 */
	constructor(private readonly http: HttpClient) {
		this.api$ = this.setupSherpa(environment.api); // api/sherpa.json
		this.auth$ = this.setupSherpa(environment.authApi); //sherpa-remote
		this.baseReg$ = this.setupSherpa(environment.baseregApi);
	}

	/**
	 * Setting up a new Sherpa.
	 * 1. rest-call to sherpa.json
	 * 2. for each json.functions as a http.post.toPromise()
	 * 3. you can subscribe the api's in your data-services
	 */
	private setupSherpa(url: string): Observable<Sherpa> {
		return this.rest<SherpaJson>(url, true)
			.pipe(
				map((result: SherpaJson) => {
					console.log('Setup new Sherpa:', result.title);
					let sherpa = new Sherpa(result);

					_.forEach(sherpa.json.functions, (func: string) => {
						const _url = _.trimEnd(sherpa.json.baseurl, '/') + '/' + func;
						sherpa.api[func] = <T>(...args: any[]) => this.sherpaPost(_url, {params: args});
					});

					return sherpa;
				})
			)
			.pipe(
				shareReplay() // no need for multiple setups
			);
	}


	public sherpaPost<T>(url: string, body: {}): Promise<T> {

		return this.http
			.post<SherpaResponse>(url, JSON.stringify(body), this.httpOptions)
			.toPromise()
			.then((response: any) => {
				if (response.error !== null) {
					return Promise.reject(response.error);
				}

				return response.result as T;

			})
			.catch((error: any) => {
				let sherpaError: SherpaError;

				if (_.has(error, ['code', 'message'])) {
					sherpaError = new SherpaError(error.code, error.message);
				}
				else if (error instanceof Error) {
					sherpaError = new SherpaError('sherpaClientError', error.message);
				}
				else if (error.status === 404) {
					sherpaError = new SherpaError('sherpaBadFunction', 'function does not exist');
				}
				else {
					sherpaError = new SherpaError('sherpaHttpError', error.message);
				}

				if(!environment.production) {
					console.error(sherpaError);
				}

				return Promise.reject(sherpaError);
			});
	}

	public rest<T>(url: string, once: boolean = false): Observable<T> {
		let ob = this.http
			.get<T>(url, this.httpOptions)
			.pipe(
				retry(2), // aka 3 attempts
				catchError((error: HttpErrorResponse) => {
					return throwError(error);
				})
			);

		if(once) {
			return ob.pipe(
				shareReplay() // call it just once
			);
		}

		return ob;
	}

}
