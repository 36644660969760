import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {auth, util} from "../../../../services";
import {SherpaError} from "../../../classes/SherpaError";
import {global} from 'src/globals';
import {LoginCredentials, SessionService} from "../../../services/data/session.service";
import {NavigationExtras, Router} from "@angular/router";
import _ from "lodash";
import {AppComponent} from "../../../app.component";

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	form: FormGroup;
	signing: boolean = false;
	passwordInvalid: boolean = false;

	get controls() {
		return this.form.controls;
	}

	constructor(private fb: FormBuilder, private app: AppComponent, public router: Router, public sessionService: SessionService) {
	}

	ngOnInit() {
		this.form = this.fb.group({
			username: [null, [Validators.required, Validators.email]],
			password: [null, Validators.required]
		});
	}

	submit() {
		if (this.form.invalid) {
			_.forEach(this.controls, control => {
				control.markAsDirty();
				control.markAsTouched();
			});
			return;
		}

		let credentials: LoginCredentials = {
			username: this.controls.username.value,
			password: this.controls.password.value
		};

		this.passwordInvalid = false;
		this.signing = true;

		// no page loader for login modal
		global.disableLoader();

		// store
		const requestedUrl = global.requestedUrl;

		this.sessionService.login(credentials)
			.then((loginResult) => {

				if(_.isNil(requestedUrl)) {
					return;
				}

				let url = '/';
				if (!!requestedUrl && requestedUrl.length) {
					url = requestedUrl;
				}

				this.router.navigateByUrl(url).then(r => console.log('redirected to', url));

			})
			.catch((error: SherpaError) => {
				util.alert(error);
			})
			.finally(() => {
				this.signing = false;
				global.enableLoader();
			});
	}

	hasError(control: AbstractControl, errorCode: string = null): boolean {

		// no interaction yet
		if(control.pristine || control.untouched) {
			return false;
		}

		// match specific errorCode
		if(errorCode !== null) {
			return control.hasError(errorCode);
		}

		// return true when some errorCode
		return !_.isNil(control.errors);
	}

	passwordResetRequest() {
		auth.passwordResetModal();
	}

}