<div class="p-d-flex p-flex-column" [style.height]="'100%'">
	<div>
		<h1 class="p-m-0 p-pb-1">Record: #{{data_id}}</h1>
		<p class="p-m-0 p-pb-3">Alle geimporteerde velden voor dit record</p>
	</div>
	<div class="p-sidebar-content p-mt-3" style="overflow-y: auto">

		<p-table styleClass="p-datatable-gridlines"
				dataKey="data_id"
				 [autoLayout]="true"
				[lazy]="true"
				[loading]="loading"
				(onLazyLoad)="loadData($event)"
				[value]="columns">
			<ng-template pTemplate="header">
				<tr>
					<th>Key</th>
					<th>Value</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-col>
				<tr *ngIf="rowdata != null">
					<td>
						<span class="p-column-title p-text-bold">{{col.header}}</span>
					</td>
					<td>
						<span *ngIf="rowdata[col.field] != null">{{rowdata[col.field]}}</span>
						<span *ngIf="rowdata[col.field] === null">-</span>
					</td>
				</tr>
			</ng-template>
		</p-table>

	</div>
</div>