import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {RegistrationCredentials, RegistrationResult} from "../../../types/sherpa";
import {ConfigsService} from "../../../services/data/configs.service";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {IriasConfigService} from "../../../services/irias-config.service";

@Component({
	selector: 'base-confirm',
	templateUrl: './confirm.component.html',
	styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

	queryParamSubscription: Subscription;
	registrationResult : RegistrationResult = null;
	secret : string = null;

	constructor(private route: ActivatedRoute, private configsService : ConfigsService, private ref: DynamicDialogRef, private dialogService : DialogService, public iriasConfig: IriasConfigService) {

	}

	ngOnInit(): void {
		this.queryParamSubscription = this.route.queryParamMap.subscribe(params => {
			const uuid = params.get("uuid");
			const secret = params.get("secret");

			if(uuid !== null && secret !== null){
				this.confirm(uuid, secret);
			}
		});
	}

	private confirm(uuid: string, secret: string) {
		this.configsService.confirmRegistration(uuid, secret).then((result : RegistrationResult) => {
			this.registrationResult = result;
			this.secret = secret;

			if(result.status === "confirmed") {
				localStorage.setItem('registration', this.registrationResult.registration.email);
				localStorage.setItem('secret', secret);
			}
		});
	}

	downloadFile(): void {
		const credentials = {
			result: this.registrationResult,
			secret: this.secret
		} as RegistrationCredentials;

		this.ref.close(credentials);
	}

	ngOnDestroy() {
		this.queryParamSubscription.unsubscribe();
	}
}
