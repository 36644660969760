
<div>
	Verklaring codes:
	<ul>
<!--		<li><strong>#GGI:</strong> Aantal goedgekeurde incidenten tijdens het validatieproces.</li>-->
<!--		<li><strong>#NUI:</strong> Niet-unieke incidentnummers. Deze incidentnummers komen/kwamen al voor in de database ten tijde van het uploaden van het nieuwe bestand.</li>-->
<!--		<li><strong>#UI:</strong> Unieke incidentnummers.</li>-->
<!--		<li><strong>#AI:</strong> Afgekeurde incidenten. Deze incidenten zijn afgekeurd op basis van inhoudelijke fouten (zoals niet bestaande buslijn) of syntactisch (bijvoorbeeld een tekstwaarde waar een getal werd verwacht). Het kan ook zijn dat een verplicht veld niet was ingevuld.</li>-->
<!--		<li><strong>#TI:</strong> Incidenten in bestand. Aantal regels in het geuploade bestand dat een incident bevat, die al dan niet goedgekeurd is.</li>-->
		<li><strong>#W:</strong> Totaal aantal fouten of waarschuwingen. Per regel in het bestand kunnen er meerdere fouten of waarschuwingen worden gegeven.</li>
	</ul>
</div>
<div>
	Verklaring gebruiker-acties:
	<ul>
		<li><strong>{{ translateUserAction('pending')}}:</strong> De gebruiker heeft een bestand geupload, maar nog niet gereageerd op het validatieresultaat.</li>
		<li><strong>{{ translateUserAction('canceled')}}:</strong> De gebruiker heeft het bestand verworpen. De data is dus niet toegevoegd, en zijn niet zichtbaar in de analyses.</li>
		<li><strong>{{ translateUserAction('committed')}}:</strong> De gebruiker heeft het bestand en het validatieresultaat goedgekeurd, de data is geimporteerd en zichtbaar in de analyses.</li>

	</ul>
</div>
<p-table
	[value]="imports"
	[responsive]="true"
	[autoLayout]="true"
	[paginator]="true"
	[showCurrentPageReport]="true"
	[rows]="10"
	[sortField]="'processed_at'"
	[sortOrder]="1"
>
	<ng-template pTemplate="header">
		<tr>
			<th>Bestand</th>
			<th>
				Tijdstip
			</th>
			<th>Gebruiker</th>
			<th pTooltip="Aantal goedgekeurde rijen">#GGI</th>
			<th pTooltip="Niet-unieke rijen">#NUI</th>
			<th pTooltip="Unieke rijen">#UI</th>
			<th pTooltip="Afgekeurde rijen">#AI</th>
			<th pTooltip="Rijen in bestand">#TI</th>
			<th pTooltip="Totaal aantal fouten of waarschuwingen">#W</th>
			<th>Uitgevoerde actie</th>
			<th></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-f>
		<tr>
			<td>
				{{ f.filename }}
			</td>
			<td>{{ f.processed_at | date : 'dd-MM-yyyy HH:mm' }}</td>
			<td>{{ f.user_email }}</td>
			<td>{{ f.import_result?.number_of_incidents_imported }}</td>
			<td>{{ f.import_result?.non_uniques }}</td>
			<td>{{ f.import_result?.uniques }}</td>
			<td>{{ f.import_result?.number_of_validation_skipped_rows }}</td>
			<td>{{ f.import_result?.number_of_incidents_in_file }}</td>
			<td>{{ f.import_result?.number_of_validation_warning }}</td>
			<td>{{ translateUserAction(f.import_result?.user_action) }}</td>
			<td>
				<button pButton pRipple label="Bekijk berichten"
						class="p-button-sm p-button-warning"
						(click)="getDetail(f);"></button>
			</td>
		</tr>
	</ng-template>
</p-table>
