
export declare class IriasTranslation {

	main: {
		language?: LanguageType;
		language_label: string;
		short_months: string[];
	};
	dashboard: {
		page_title: string;
		dashboard_title: string;
		dashboard_box_title: string;
		dashboard_co2_title: string;
		dashboard_co2_text: string;
		dashboard_km_text: string;
	};
	panels: {
		page_title: string;
		legend_title: string;
		filter_title_1: string;
		filter_title_2: string;
		register_title: string;
		register_text: string;
		register_name: string;
		register_org: string;
		register_checkbox: string;
	};

}

export enum LanguageType {
	NL,
	EN
}