import {Injectable} from '@angular/core';
import {SherpaError} from "../../classes/SherpaError";
import {BackendFunctions, Sherpa} from "../../classes/Sherpa";
import {auth, sherpa, util} from "../../../services";
import {UserModel} from "../../classes/models/UserModel";
import _ from "lodash";
import {LoginResult} from "../../classes/models/LoginResult";
import {global} from "../../../globals";

export type LoginCredentials = {
	username: string;
	password: string;
}

@Injectable({
	providedIn: 'root'
})
export class SessionService {

	private appCode: string = 'mrae';
	private authAPI: BackendFunctions = {};

	constructor() {
		sherpa.auth$.subscribe((sherpa: Sherpa) => {
			this.authAPI = sherpa.api;
		});
	}

	login(credentials: LoginCredentials): Promise<LoginResult> {

		let app = {
			app_code: this.appCode
		};

		return this.authAPI.login<LoginResult>(credentials.username, credentials.password, app)
			.then((result: LoginResult) => {

				auth.setUser(result.user);
				auth.setToken(result.token);

				return result;

			});
	}

	logout(url: string = '/'): Promise<void> {
		return this.authAPI.logout<void>(auth.token())
			.then(() => {
				global.loginModal = true;
				global.requestedUrl = url;
			})
			.catch((e: SherpaError) => {
				auth.clear(true);
			});
	}

	whoami(): Promise<UserModel> {
		if (_.isEmpty(auth.token())) {
			const error = new SherpaError('error', 'Invalid token');
			return Promise.reject(error);
		}

		return sherpa.auth$.toPromise().then((sherpa: Sherpa) => {
			return sherpa.api.whoami2<UserModel>(auth.token());
		});
	}

	requestPasswordReset(email: string): Promise<any> {
		return this.authAPI.requestPasswordReset(email, this.appCode)
			.catch((e: SherpaError) => util.handleError(e));
	}

	changePassword(new_password: string): Promise<any> {
		return this.authAPI.changePasswordCurrentUser(auth.token(), new_password)
			.catch((e: SherpaError) => util.handleError(e));
	}

}
