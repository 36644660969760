import {ChangeDetectorRef, Component, OnChanges, OnInit, ViewChild} from '@angular/core';
import {Map as OLMap} from 'ol';
import VectorLayer from "ol/layer/Vector";
import Select from "ol/interaction/Select";
import {MapService} from "../../../services/map.service";
import {StyleService} from "../../../services/style.service";
import {StyleFunction} from "ol/style/Style";
import _ from "lodash";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";
import Geometry from "ol/geom/Geometry";
import VectorSource from "ol/source/Vector";
import Point from "ol/geom/Point";
import {
	Filter,
	FilterField,
	GroupOption, IncidentField,
	IncidentsGrid,
	MultiChartDataWithConfig,
	StoredConfig,
	StoredConfigsWrapper
} from "../../../types/sherpa";
import {MenuItem} from "primeng/api";
import {Globals} from "../../../classes/Globals";
import {global} from "../../../../globals";
import {ConfigsService} from "../../../services/data/configs.service";
import {ActivatedRoute, NavigationEnd, ParamMap, Router} from "@angular/router";
import {BehaviorSubject, Subject, Subscription} from "rxjs";
import WebGLPointsLayer from "ol/layer/WebGLPoints";
import {LiteralStyle} from "ol/style/LiteralStyle";
import {click} from "ol/events/condition";
import View from "ol/View";
import * as olExtent from "ol/extent";
import {Extent} from "ol/extent";
import {AnalysePanel, PanelDisplay, PanelType} from "../../../classes/models/AnalysePanel";
import {StaticDataService} from "../../../services/data/static-data.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {auth, util} from "../../../../services";
import {DialogService} from "primeng/dynamicdialog";
import marked from "marked";
import {isArray} from "rxjs/internal-compatibility";
import {Location, registerLocaleData} from "@angular/common";
import localeNl from '@angular/common/locales/nl';
import {AuthService} from "../../../services/auth.service";
import {ImportService} from "../../../services/data/import.service";
import {
	Chart,
	ChartDataset,
	Plugin,
	registerables,
	ChartEvent,
	LegendItem,
	LegendElement, ChartOptions
} from 'chart.js';
import {ChartUpdate} from "../../common/chart/chart.component";
Chart.register(...registerables);

registerLocaleData(localeNl, 'nl');

@Component({
	selector: 'base-incidents',
	templateUrl: './panels-admin.component.html',
	styleUrls: ['./panels-admin.component.scss']
})
export class PanelsAdminComponent implements OnInit {

	global: Globals = global;
	hideAll: boolean = false;
	presentationMode: boolean;
	showReportPages: boolean;
	previousMenu: boolean = global.menu;

	// subscriptions
	navigationSubscription: Subscription;
	queryParamSubscription: Subscription;

	// subject for broadcasting to other components like sidebar + filters
	panelSubject: BehaviorSubject<AnalysePanel> = new BehaviorSubject<AnalysePanel>(null);

	// page resolvers
	storedConfigs: StoredConfigsWrapper;

	// data
	fields: any = null;
	filters: Filter[];
	panels: AnalysePanel[] = [];
	selectedPanels: AnalysePanel[] = []; // dropdown menu
	groupByOptions$: Promise<GroupOption[]>;
	selectedStoredConfig: StoredConfig = null; // bookmarks table-item
	storedConfig: StoredConfig = null;
	myStoredConfigs: StoredConfig[] = [];
	mapColors: any[] = [];

	// helpers
	stored_config_id: number;
	stored_config_temp_id: number;
	submitted: boolean = true;
	showSidebar: boolean = false;
	filterPathClean: string = null;
	filterPath: string = 'analyse';
	showTableBackground: boolean = false;
	chartToggleHelpText: string = null;
	autoSizeHelpText: boolean = false
	refreshed: boolean = true; // needed for re-size charts

	// openlayers
	map: OLMap;
	vectorIncidentSource = new VectorSource();
	vectorPoliceSource = new VectorSource();
	incidentsLayer: VectorLayer;
	policeLayer: VectorLayer;
	gridLayer: VectorLayer;
	selectClick: Select;
	mapAlreadyInit: boolean = false;
	currZoom = 0;
	zoomSwitchLevel = 12;
	gridCache: Map<number, any> = new Map<number, any>();
	policeColor: string = '#AD5F00';

	// menu
	@ViewChild('myFiltersPanel') myFiltersPanel: any;
	saveMenu: MenuItem[];
	chartToggleOptions: PanelDisplay[];

	// dialogs
	showSaveFilterDialog: boolean = false;
	showEditFilterDialog: boolean = false;
	showDownloadDialog: boolean = false;
	downloadUrl: string = null;

	// chart plugin for labeling
	pdfGenerating: boolean = false;
	public periodDescription: string = null;
	charts: Chart[] = [];
	fetched = false;

	subject: Subject<ChartUpdate> = new Subject<ChartUpdate>();

	constructor(private cd: ChangeDetectorRef, public mapService: MapService, private route: ActivatedRoute, private router: Router, public styles: StyleService, public importService: ImportService, public configsService: ConfigsService, public staticData: StaticDataService, public dialogService: DialogService, private readonly http: HttpClient, private location: Location, public authService: AuthService) {

		this.chartToggleOptions = [
			new PanelDisplay(PanelType.bar),
		//	new PanelDisplay(PanelType.horizontalBar),
			new PanelDisplay(PanelType.bar, true),
		//	new PanelDisplay(PanelType.horizontalBar, true),
			new PanelDisplay(PanelType.line),
		//	new PanelDisplay(PanelType.pie),
		//	new PanelDisplay(PanelType.radar),
			new PanelDisplay(PanelType.table)
		];

		this.setMenu();
	}

	ngOnInit(): void {
		this.filters = this.route.snapshot.data['filters'] as Filter[];
		this.storedConfigs = this.route.snapshot.data['storedConfigs'] as StoredConfigsWrapper;

		this.myStoredConfigs = this.storedConfigs.myFilters;
		this.groupByOptions$ = this.importService.getGroupOptions();

		this.importService.getColors('abc_main_code').then((result: any) => {
			this.mapColors = _.sortBy(result, 'label');
		});

		this.watch();
	}

	watch() {
		// navigate to same url will refresh panels
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			if (e instanceof NavigationEnd) {
				this.init();
			}
		});

		// keep track of the queryParamMap to fetch new panels
		this.queryParamSubscription = this.route.queryParamMap.subscribe(params => {

			/* STORE PATH */
			const isAnalyse = params.has('filter');
			this.filterPath = isAnalyse ? params.get('filter') : 'analyse';
			this.filterPathClean = isAnalyse ? _.replace(this.filterPath, new RegExp('-', 'g'), ' ') : null;

			/* INIT */
			// the + converts a string to a number
			this.stored_config_id = +params.get('id');
			this.stored_config_temp_id = +params.get('tmp_id');

			this.init();
		});
	}

	init() {
		// 0 means -> not set in url
		if(this.stored_config_id === 0 && this.stored_config_temp_id === 0) {
			this.storedConfig = null;
			this.newPanels();

			this.fetched = true;
			return;
		}

		this.mapAlreadyInit = false;

		const is_temp = this.stored_config_temp_id > 0;
		const fetch_id = is_temp ? this.stored_config_temp_id : this.stored_config_id;

		this.fetched = false;
		this.configsService.getPanelsForConfigId(fetch_id).then(wrapper => {
			this.storedConfig = _.find(this.myStoredConfigs, ['stored_config_id', this.stored_config_id]) || null;

			this.fetched = true;
			this.setMenu();
			this.preparePanels(wrapper);
		});
	}

	setMenu() {
		this.saveMenu = [{
			label: this.storedConfig?.label || 'Analyse' + ':',
			items: [{
				label: 'Opslaan..',
				disabled: _.isNil(this.storedConfig),
				command: () => {
					this.storedConfig.config = [];
					this.openSaveFilterDialog(this.storedConfig, true, false);
				}
			}, {
				label: 'Opslaan als..',
				command: () => {

					this.openSaveFilterDialog(this.storedConfig, true, true);
				}
			}]
		}];
	}

	preparePanels(wrapper: MultiChartDataWithConfig) {
		let panels: AnalysePanel[] = [];
		const indices = _.range(wrapper.config.length);

		_.forEach(indices, i => {
			const visible = wrapper.config[i] !== null;

			// extend new AnalysePanel with config
			let panel = _.assignIn(new AnalysePanel(visible, i), wrapper.config[i]);

			// default to bar
			if(panel.type === null) {
				panel.type = PanelType.bar;
			}

			if(panel.type !== PanelType.map) {
				panel.resultData = wrapper.chartData[i];
			}

			panels.push(panel);
		});

		this.initPanels(panels);
	}

	newPanels() {
		let panels: AnalysePanel[] = [];
		const indices = _.range(10);

		_.forEach(indices, i => {
			let panel = new AnalysePanel(i === 0, i)
			panels.push(panel);
		});

		this.initPanels(panels);
		//this.openPanelFilter(0);
	}

	initPanels(panels: AnalysePanel[]) {
		this.selectedPanels = [];

		_.forEach(panels, panel => {
			panel.filters = _.cloneDeep(this.filters);
			panel.orgResultData = _.cloneDeep(panel.resultData);
		})

		this.panels = panels;

		_.forEach(this.panels, panel => {
			// this.transformChart(panel.index, panel.type, panel.stacked);
			//
			// _.delay(() => {
			// 	const canvas = document.getElementById('p-' + panel.index) as HTMLCanvasElement;
			//
			// 	// no panel
			// 	if(canvas !== null) {
			// 		panel.chartInstance = new Chart(canvas, {
			// 			type: panel.type as any,
			// 			data: panel.resultData,
			// 			options: panel.chartOptions,
			// 			plugins: []
			// 		});
			// 		this.charts.push(panel.chartInstance);
			// 	}
			//
			// }, 0);
		});

		// initiate dropdown
		_.forEach(this.panels, p => {
			if(p.visible) {
				this.selectedPanels.push(p);
			}
		})
	}

	transformChart(index: number, toChart: PanelType, stacked: boolean = false) {
		const next = {
			index: index,
			toChart: toChart,
			stacked: stacked
		} as ChartUpdate;

		this.subject.next(next);
	}

	reloadPanel(index: number) {
		// get chart instance
		const chart = Chart.getChart('p-' + index);

		if(chart) {
			const panel = this.panels[index];
			chart.data = panel.resultData;
			chart.update();
		}
	}

	setChartOptions(index: number, type: PanelType, stacked: boolean) {
		this.panels[index].stacked = stacked;
		this.panels[index].chartOptions = null;

		if(!this.isChart(type)) {
			return;
		}

		let showLegend = this.showLegend(this.panels[index]);
		let legendPosition = 'top';
		if(type === PanelType.pie || type == PanelType.radar){
			showLegend = true; // always use legend in pie charts
			legendPosition = 'left'; // always use legend in pie charts
		}

		// all chart types
		let options = {
			responsive: true,
			maintainAspectRatio: true,
			aspectRatio: 5/4,
			interaction: {
				intersect: true,
				mode: 'point',
			},
			legend: {
				display: showLegend,
				position: legendPosition,
				align: 'start',
				labels: {
					boxWidth: 14,
					fontSize: 14,
					fontStyle: '300',
					fontFamily: 'MuseoSans',
					fontColor: '#000'
				}
			},
			animation: {
				duration: 0
			},
			scales: {}
		} as ChartOptions;


		// custom additions
		if(type === PanelType.bar || type === PanelType.horizontalBar || type === PanelType.line) {
			options.scales = {
				y: {
					beginAtZero: true,
					stacked: stacked,
					position: 'right',
					ticks: {
						padding: 5
					}
				},
				x: {
					beginAtZero: true,
					stacked: stacked,
					ticks: {
					}
				}
			};
		}

		this.panels[index].chartOptions = options;
		// console.log(options);
		// console.log(this.panels[index].resultData);
	}

	setChartData(index: number, type: PanelType) {
		switch (type) {
			case PanelType.bar:
			case PanelType.horizontalBar:
				this.panels[index].resultData = this.asBarChartData(this.panels[index].orgResultData, index);
				break;
			case PanelType.line:
				this.panels[index].resultData = this.asLineChartData(this.panels[index].orgResultData);
				break;
			case PanelType.pie:
				this.panels[index].resultData = this.asPieChartData(this.panels[index].orgResultData, index);
				break;
			case PanelType.radar:
				this.panels[index].resultData = this.asRadarChartData(this.panels[index].orgResultData, index, this.panels[index].groupBy?.length > 1);
				break;
			default:
				this.panels[index].resultData = _.cloneDeep(this.panels[index].orgResultData);
		}
	}

	setChartToggleHelpText(panel: AnalysePanel, display: PanelDisplay) {
		this.chartToggleHelpText = null;

		if(display.stacked && panel.groupBy.length < 2) {
			this.chartToggleHelpText = 'Let op er staat maar 1 categorie ingesteld.';
		}
	}

	duplicatePanel(selectedTarget: AnalysePanel) {

		// find latest chart
		let latest = _.findLast(this.panels, p => {
			return p.resultData !== null && p.type !== PanelType.map;
		});

		// no latest, meaning empty panel
		if(latest === undefined) {
			if (selectedTarget.type === PanelType.map) {
				this.mapAlreadyInit = false;
				console.warn('no grid');
				//this.getIncidentsGrid();
			}

			return;
		}

		// clone from latest
		let clonedPanel = _.cloneDeep(_.omit(latest, 'type', 'index')) as AnalysePanel;
		// restore omitted properties
		clonedPanel.index = selectedTarget.index; // set original index
		clonedPanel.type = selectedTarget.type; // set original type
		clonedPanel.visible = true; // set visible, first time click

		// update original
		this.panels[selectedTarget.index] = clonedPanel;

		// meaning map-panel
		if (clonedPanel.type === PanelType.map) {
			this.mapAlreadyInit = false;
			console.warn('no grid');
			//this.getIncidentsGrid();
		}
		else {
			this.transformChart(clonedPanel.index, clonedPanel.type, clonedPanel.stacked);
		}
	}

	selectTogglePanel(selection: AnalysePanel[]) {

		// set visible when selected
		_.forEach(this.panels, p => {

			this.panels[p.index].visible = _.some(selection, ['index', p.index]);

			if(this.panels[p.index].visible && this.panels[p.index].resultData === null) {
				this.duplicatePanel(this.panels[p.index]);
			}
		});
	}

	deselectPanel(panel: AnalysePanel) {
		panel.visible = false;

		if(panel.type === PanelType.map) {
			this.mapAlreadyInit = false;
		}

		// store the visible=true
		this.selectedPanels = _.remove(this.selectedPanels, 'visible');
	}

	openSaveFilterDialog(myConfig: StoredConfig, updateConfig: boolean, createNewConfig : boolean) {
		if(myConfig !== null) {
			this.selectedStoredConfig = myConfig;
			if(this.selectedStoredConfig !== null && this.selectedStoredConfig !== undefined) {
				this.selectedStoredConfig.config = null; // we dont know this, null will be skipped in backend
				this.selectedStoredConfig._updateConfig = updateConfig;
				this.selectedStoredConfig._createNewConfig = createNewConfig;
			}
		}

		this.showSaveFilterDialog = true;
	}

	openPanelFilter(index: number) {
		this.showSidebar = !this.showSidebar;
		// broadcast new active panel
		this.panelSubject.next(this.panels[index]);
	}

	panelUpdated(panel: AnalysePanel) {

		if(panel.type === PanelType.map) {
			this.setMapExtentConstraint();
			this.moveEndHandler(true); // for fetching data by zoomLevel
		}
		else {
			this.getPanelData(panel);
		}

		this.panels[panel.index] = panel;

		if(panel.type !== PanelType.map) {
			this.reloadPanel(panel.index);
		}
	}

	afterSaveFilter(storedConfig: StoredConfig) {

		let current = _.find(global.menuItems, item => {
			return item.queryParams && item.queryParams.id === this.stored_config_id;
		});

		if(storedConfig.stored_config_id !== null) {

			// remove from menu first, will be add later if "show_in_menu"
			_.remove(global.menuItems, (menuItem: MenuItem) => {
				return menuItem.queryParams?.id === storedConfig.stored_config_id;
			});

			this.myStoredConfigs = util.upsert(this.myStoredConfigs, storedConfig, 'stored_config_id');
		}

		let menuItem = {
			label: storedConfig.label,
			routerLink: '/analyses',
			queryParams: {filter: _.kebabCase(storedConfig.label), id: storedConfig.stored_config_id}
		} as MenuItem;

		if(storedConfig.show_in_menu) {
			global.menuItems.push(menuItem);
		}

		this.cd.markForCheck();
		this.cd.detectChanges();

		this.router.navigate([menuItem.routerLink], {queryParams: menuItem.queryParams});


		if(true)return;
		// not working as i hoped

		if(current.queryParams.id === menuItem.queryParams.id) {
			this.router.navigate([menuItem.routerLink], {queryParams: menuItem.queryParams});
		}
		// redirect
		else {
			this.router.navigate([current.routerLink], {
				queryParams: current.queryParams
			});
		}
	}

	afterDeleteFilter(id: any) {

		_.remove(global.menuItems, (menuItem: MenuItem) => {
			return menuItem.queryParams?.id === id;
		});

		this.router.navigate(['/analyses', 'nieuw']);
	}

	asLineChartData(chartData: any) {
		if (chartData === null) {
			return chartData;
		}

		let clone = _.cloneDeep(chartData);

		_.forEach(clone.datasets, (dataset: ChartDataset) => {

			// dataset.showLine = true;
			// dataset.fill = false;

			if(isArray(dataset.backgroundColor)){
				dataset.backgroundColor = dataset.backgroundColor[0];
			}
			dataset.borderColor = dataset.backgroundColor;

			// hacky
			if(dataset.label === 'Trend') {
				// dataset.fill = true;
				dataset.backgroundColor = 'rgba(0,0,0,0)';
			}
		});

		return clone;
	}

	asPieChartData(chartData: any, index: number) {

		if (chartData === null) {
			return chartData;
		}

		let clone = _.cloneDeep(chartData);
		let colors = this.staticData.getColorPalette();

		_.remove(clone.datasets, (dataset: ChartDataset) => {
			return dataset.label === 'Trend';
		});

		_.forEach(clone.datasets, (dataset: ChartDataset) => {

			if(!isArray(dataset.backgroundColor)){
				dataset.backgroundColor = colors;
			}
			else {
				// check if all colors are equal
				if(_.uniq(dataset.backgroundColor).length === 1){
					dataset.backgroundColor = colors;
				}
			}

			dataset.borderColor = '#f9f9f9';
			// dataset.datalabels = {
			// 	color: '#5a5858',
			// 	display: context => {
			// 		return this.panels[index].show_labels ? 'auto' : false;
			// 	}
			// }
		});

		return clone;
	}

	asRadarChartData(chartData: any, index: number, multipleGroups: boolean) {

		if (chartData === null) {
			return chartData;
		}

		let clone = _.cloneDeep(chartData);

		_.remove(clone.datasets, (dataset: ChartDataset) => {
			return dataset.label === 'Trend';
		});

		_.forEach(clone.datasets, (dataset: ChartDataset) => {
			// dataset.showLine = false;
			// dataset.fill = false;
			if(isArray(dataset.backgroundColor)){
				dataset.borderColor = dataset.backgroundColor[0];
				dataset.backgroundColor = dataset.backgroundColor[0];
			}

			// dataset.datalabels = {
			// 	color: '#9f9f9f',
			// 	display: context => {
			// 		return this.panels[index].show_labels ? 'auto' : false;
			// 	}
			// }
		});

		return clone;
	}

	asBarChartData(chartData: any, index: number) {

		if (chartData === null) {
			return chartData;
		}

		let clone = _.cloneDeep(chartData);

		_.forEach(clone.datasets, (dataset: ChartDataset) => {
			// dataset.fill = true;

			if(dataset.label !== 'Trend') {
				// dataset.showLine = false;
				dataset.borderColor = '#9f9f9f';
				// dataset.datalabels = {
				// 	color: '#1d1d1d',
				// 	display: context => {
				// 		const value = context.dataset.data[context.dataIndex];
				//
				// 		if(value === null || value === 0) {
				// 			return false;
				// 		}
				//
				// 		return this.panels[index].show_labels ? 'auto' : false;
				// 	},
				// 	anchor: 'end',
				// 	align: 'end',
				// 	offset: 0,
				// 	clamp: true
				// }
			}
			else {
				dataset.backgroundColor = 'rgba(0,0,0,0)';
			}
		});

		return clone;
	}

	changeSize(index: number, size: number) {
		let panel = this.panels[index];
		panel.size = size;

		//console.log('changeSize', panel);

		if(panel.type === PanelType.radar || panel.type === PanelType.pie) {
			// update legend position
			this.setChartOptions(panel.index, panel.type, panel.stacked);
		}
		else if(panel.type === PanelType.map) {
			// update canvas size
			this.updateMapSize();
		}

		this.refreshPanelSize();
	}

	isChart(type: PanelType) {
		return _.includes([PanelType.bar, PanelType.horizontalBar, PanelType.line, PanelType.pie, PanelType.radar], type);
	}

	getMostRecentYearFilterField(): FilterField[] {
		let dateFilter = _.find(this.filters, ['key', 'date']);
		if(!dateFilter) {
			return [];
		}

		const latestDateFilter = _
			.chain(dateFilter.values)
			.orderBy('label', 'desc')
			.head()
			.value();

		const valuesOnly = _.map(latestDateFilter.value, 'value');

		return [{key: 'date', expectedValue: valuesOnly}] as FilterField[];
	}

	addNewPanel() {
		let panel = new AnalysePanel(true, this.panels.length);
		this.panels = [panel, ...this.panels];

		// set year to most recent
		this.getPanelData(panel);
		this.selectedPanels.push(panel);
	}

	// button table row
	onRowSelect(event: any) {
		this.myFiltersPanel.hide();

		const storedConfig = event.data as StoredConfig;
		const params = {filter: _.kebabCase(storedConfig.label), id: storedConfig.stored_config_id};

		this.router.navigate(['/analyses'], {queryParams: params});
	}

	refreshPanelSize() {
		this.refreshed = false;
		this.cd.detectChanges();
		this.refreshed = true;
	}




	/* FETCH DATA */

	getPanelData(panel: AnalysePanel) {
		const i = panel.index;

		this.fetched = false;
		this.importService.getResultForPanel(panel).then(result => {
			this.submitted = true;
			this.fetched = true;

			// reset the data
			this.panels[i].resultData = result;
			this.panels[i].orgResultData = _.cloneDeep(result);

			// make sure the chartsData and chartOptions are correct for this type
			this.transformChart(i, panel.type, panel.stacked);
		});
	}

	showLegend(p : AnalysePanel ) {

		let result : boolean = false;
		if(p.groupBy?.length > 1){
			result = true;
		}
		else if(p.resultData !== null){
			let trend = _.find(p.resultData.datasets, (ds => {
				return ds.label === 'Trend';
			}));
			if(trend !== undefined && trend !== null){
				result = true;
			}
		}
		else {
			result = p.show_legend === true;
		}

		if(p.type === PanelType.pie){
			result = true;
		}

		return result;
	}

	getIncidents(extent: Extent) {
		const mapPanel = _.find(this.panels, {'type':PanelType.map, 'visible':true});
		if(!mapPanel) {
			return;
		}

		this.importService.getIncidents(mapPanel, extent).then(result => {
			this.submitted = true;
			this.panels[mapPanel.index].resultData = result;

			_.delay(() => {
				this.initIncidentsLayer(result);
			}, 0);
		});
	}

	getPoliceIncidents(extent: Extent) {
		const mapPanel = _.find(this.panels, {'type': PanelType.map, 'visible': true});
		if(!mapPanel) {
			return;
		}

		this.policeLayer.setVisible(mapPanel.show_police);

		if (!mapPanel.show_police) {
			this.vectorPoliceSource.clear();
			this.vectorPoliceSource.changed();
			return;
		}

		this.importService.getIncidents(mapPanel, extent, true).then(result => {
			this.submitted = true;

			_.delay(() => {
				this.initPoliceLayer(result);
			}, 0);
		});
	}


	/* OPENLAYERS */

	initIncidentsLayer(data: any[]) {
		if (!this.mapAlreadyInit) {
			this.initMap();
		}

		this.vectorIncidentSource.clear(true);
		let features: Feature[] = [];
		let emptyGeomCounter: number = 0;

		const bronze = this.policeColor;
		const a = _.find(this.mapColors, ['label', 'A']);
		const b = _.find(this.mapColors, ['label', 'B']);
		const c = _.find(this.mapColors, ['label', 'C']);

		_.forEach(data, item => {
			if (item.geom === null || item.geom === undefined || item.geom.length === 0) {
				emptyGeomCounter++;
				return; // continue
			}

			let geom = new Point(item.geom) as Geometry;
			let feat = new Feature(geom);
			feat.setId(item.incident_id);

			if (item.abc_code?.length > 0) {
				let code = item.abc_code[0];
				let color;
				if(code === a.label) {
					color = a.color;
				}
				if(code === b.label) {
					color = b.color;
				}
				if(code === c.label) {
					color = c.color;
				}

				feat.set('abc_code', code);
				feat.set('color', color);

			} else {
				feat.set('abc_code', 'P');
				feat.set('color', bronze);
			}

			features.push(feat);
		});

		if (emptyGeomCounter > 0) {
			//console.log(`Skipped ${emptyGeomCounter} items with empty geom`);
		}

		//console.log(`${features.length} items added`);

		this.vectorIncidentSource.addFeatures(features);
		this.vectorIncidentSource.changed();
	}

	initPoliceLayer(data: any[]) {
		if (!this.mapAlreadyInit) {
			this.initMap();
		}

		this.vectorPoliceSource.clear(true);
		let features: Feature[] = [];
		let emptyGeomCounter: number = 0;

		_.forEach(data, item => {
			if (item.geom === null) {
				emptyGeomCounter++;
				return; // continue
			}

			let geom = new Point(item.geom) as Geometry;
			let feat = new Feature(geom);
			feat.setId(item.mk_incident_id);
			feat.set('abc_code', 'P');
			feat.set('color', this.policeColor);
			features.push(feat);
		});

		if (emptyGeomCounter > 0) {
			//console.log(`Skipped ${emptyGeomCounter} items with empty geom`);
		}

		//console.log(`${features.length} police-items added`);

		this.vectorPoliceSource.addFeatures(features);
		this.vectorPoliceSource.changed();
	}

	initGrid(data: IncidentsGrid[]) {
		if (!this.mapAlreadyInit) {
			this.initMap();
		}

		this.gridLayer.getSource().clear();
		let features: Feature[] = [];

		_.forEach(data, (item: IncidentsGrid) => {
			let geom = new Polygon(item.geom) as Geometry;
			let feat = new Feature(geom);
			feat.set('value', item.incidents)
			features.push(feat);
		});

		this.gridLayer.getSource().addFeatures(features);
		this.gridLayer.getSource().changed();
	}

	initMap() {
		this.map = this.mapService.createMap('incidents-map');
		this.setMapExtentConstraint();

		this.incidentsLayer = new VectorLayer({
			source: this.vectorIncidentSource,
			style: StyleService.abcMainStyle as StyleFunction,
			visible: false,
			zIndex: 2
		});
		this.policeLayer = new VectorLayer({
			source: this.vectorPoliceSource,
			style: StyleService.abcMainStyle as StyleFunction,
			visible: false,
			zIndex: 1
		});
		this.gridLayer = this.mapService.getLayer(this.styles.heatMapStyle as StyleFunction, 10);

		this.map.addLayer(this.incidentsLayer);
		this.map.addLayer(this.policeLayer);
		this.map.addLayer(this.gridLayer);

		this.selectClick = new Select({
			condition: click,
			multi: true,
			hitTolerance: 10,
			layers: p0 => {
				return p0 === this.incidentsLayer || p0 === this.policeLayer;
			}
		});

		this.selectClick.on('select', evt => {
			//console.log('>>', evt);
		})

		//this.map.addInteraction(this.selectClick);

		this.currZoom = this.map.getView().getZoom();
		this.map.on('moveend', e => this.moveEndHandler());
		this.mapAlreadyInit = true;

		if(this.presentationMode) {
			this.setPresentationMap();
		}
	}

	setMapExtentConstraint() {
		if (_.isNil(this.map)) {
			return;
		}

		const mapPanel = _.find(this.panels, {'type': PanelType.map, 'visible': true});
		if(!mapPanel) {
			return;
		}

		const extent = mapPanel.extent as Extent;
		const zoom = this.map.getView().getZoom();
		let view;

		if (_.isNil(extent)) {
			view = new View({
				center: this.map.getView().getCenter(),
				zoom: zoom
			});
		} else {
			view = new View({
				center: olExtent.getCenter(extent),
				zoom: zoom,
				extent: extent
			});
		}

		view.setMaxZoom(17);
		this.map.setView(view);

		if(mapPanel.latest_extent !== null) {
			this.map.getView().fit(mapPanel.latest_extent);
		}
	}

	updateMapSize() {
		if (_.isNil(this.map)) {
			return;
		}

		_.delay(() => {
			this.map.updateSize();
		}, 200);
	}

	moveEndHandler(force: boolean = false) {
		const mapPanel = _.find(this.panels, {'type': PanelType.map, 'visible': true});
		if(!mapPanel) {
			return;
		}

		const newZoom = this.map.getView().getZoom();
		let extent = this.map.getView().calculateExtent(this.map.getSize());

		// update size when menu toggles
		if(this.previousMenu != global.menu) {
			this.map.updateSize();
			this.previousMenu = global.menu;
		}

		if (newZoom > this.zoomSwitchLevel) {
			this.gridLayer.setVisible(false);

			this.incidentsLayer.setVisible(true);
			this.getIncidents(extent);

			this.policeLayer.setVisible(mapPanel.show_police);
			if (mapPanel.show_police) {
				this.getPoliceIncidents(extent);
			}
		}
		else {
			this.gridLayer.setVisible(true);
			this.incidentsLayer.setVisible(false);
			this.policeLayer.setVisible(false);

			let grid = 10000;

			if (newZoom !== this.currZoom || force) {

				if (newZoom > 11) {
					grid = 500;
				} else if (newZoom > 10) {
					grid = 1000;
				} else if (newZoom > 9) {
					grid = 2500;
				} else if (newZoom > 8) {
					grid = 5000;
				}

				const useCache = false;
				if (useCache && this.gridCache.has(grid)) {
					this.initGrid(this.gridCache.get(grid));
				} else {
					this.importService.getIncidentsGrid(mapPanel, grid).then(result => {
						this.submitted = true;
						this.gridCache.set(grid, result);

						_.delay(() => {
							this.initGrid(result);
						}, 0);
					});
				}
			}
		}

		this.panels[mapPanel.index].latest_extent = extent;
		this.currZoom = newZoom;
	}

	setPanelVerticalSize(p : AnalysePanel, vertical_size: number) {
		p.vertical_size = vertical_size;
		this.changeSize(p.index, p.size);
		this.reloadPanel(p.index);
	}


	/* PRESENTATION MODE */

	storeTempConfig(): Promise<StoredConfig> {
		let cleanPanels = [] as AnalysePanel[];
		let panels = _.cloneDeep(this.panels);

		_.forEach(panels, panel => {
			let clean = _.omit(panel, this.importService.panelAdditionProperties) as AnalysePanel;
			cleanPanels.push(clean);
		});

		let range = _.range(cleanPanels.length);
		_.forEach(range, i => {
			if (!this.panels[i].visible) {
				cleanPanels[i] = null;
			}
		});

		const concept = {
			config: cleanPanels,
			label: `temp_${this.stored_config_id}_${new Date().getTime()}`,
			show_in_menu: false
		} as StoredConfig;

		return this.configsService.saveTempStoredConfig(concept);
	}

	setPresentationMap() {
		const mapPanel = _.find(this.panels, {'type': PanelType.map, 'visible': true});
		if(!mapPanel) {
			return;
		}

		this.map.getControls().forEach(control => {
			this.map.removeControl(control);
		});

		this.map.getInteractions().forEach(interaction => {
			interaction.setActive(false);
		});

		if(mapPanel.latest_extent === null) {
			return;
		}

		const view = new View({
			center: olExtent.getCenter(mapPanel.latest_extent),
			extent: mapPanel.latest_extent,
			zoom: this.map.getView().getZoom()
		});

		this.map.setView(view);
	}

	openPresentation() {
		// save concept in db
		this.storeTempConfig().then(config => {
			const params = {
				filter: this.filterPath,
				id: this.stored_config_id,
				tmp_id: config.stored_config_id,
				hideAll: true
			};

			this.router.navigate(['/analyses'], {queryParams: params});
		});
	}

	closePresentation() {
		const params = {
			filter: this.filterPath,
			id: this.stored_config_id,
			tmp_id: this.stored_config_temp_id
		};

		this.router.navigate(['/analyses'], {queryParams: params});
	}

	isPrintable(panel: AnalysePanel) {

		if(panel.resultData.datasets.length > 4 && panel.show_labels) {
			return false;
		}

		if(panel.resultData.datasets.length > 18 && !panel.show_labels) {
			return false;
		}


		return true;
	}

	print() {
		window.print();
	}

	downloadFile(): void {
		const id = this.stored_config_temp_id > 0 ? this.stored_config_temp_id : this.stored_config_id;

		const baseUrl = `/rest/render/${auth.token()}/${id}/pdf`;
		const headers = new HttpHeaders();
		//headers.set('authorization','Bearer '+token);

		this.pdfGenerating = true;

		this.http.get(baseUrl,{headers, responseType: 'blob' as 'json'}).subscribe(
			(response: any) =>{
				let dataType = 'application/pdf';
				let binaryData = [];
				binaryData.push(response);

				let downloadLink = document.createElement('a');
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute('download', this.filterPath);

				document.body.appendChild(downloadLink);
				downloadLink.click();

				this.pdfGenerating = false;
			}
		)
	}

	asMarkdown(txt: string) {
		return marked(txt);
	}

	requestExportData(panel : AnalysePanel) {
		if(this.showDownloadDialog) {
			return;
		}

		this.downloadUrl = null;
		this.importService.requestExportCSV(panel).then((url : string) => {
			this.downloadUrl = url;
			this.showDownloadDialog = true;
		});
	}


	drawAppendixTable() {

		let id = 1;
		const maxHeight = 760;
		const keys = ['title', 'type', 'instructions.instruction_text', 'requirements.required'];

		let page = document.getElementById('page-appendix-'+id);
		let body = page.querySelector('.body.index');
		let table = body.querySelector('.appendix-table');
		let tableBody = table.querySelector('tbody');



		_.forEach(this.fields.incident_fields, field => {

			const row = document.createElement('tr');

			_.forEach(keys, path => {
				const td = document.createElement('td');
				//td.style.lineHeight = '22px';
				td.style.lineHeight = 'unset';
				let value = _.get(field, path, '');

				if(path === 'title' && _.includes(['Concessienaam', 'Concessieverlener'], value)) {
					td.style.overflowWrap = 'break-word';
				}

				if(path === 'requirements.required') {
					td.textContent = (value as boolean) ? 'Ja' : 'Nee';;
				}
				else if(path === 'instructions.instruction_text') {
					const content = document.createElement('div');
					content.innerHTML = this.asMarkdown(value);
					td.colSpan = 3;
					td.appendChild(content);
				}
				else {
					td.textContent = value;
				}

				row.appendChild(td);
			});


			tableBody.appendChild(row);


			if(tableBody.clientHeight > maxHeight) {

				tableBody.removeChild(row);
				id++;

				page = this.createPage(page, id);

				body = page.querySelector('.body.index');
				table = body.querySelector('.appendix-table');
				tableBody = table.querySelector('tbody');

				tableBody.appendChild(row);
			}

			return true;
		});
	}

	createPage(currentPage: HTMLElement, newId: number): HTMLElement {

		const page = document.createElement('div');
		page.id = 'page-appendix-' + newId;
		page.classList.add('page4', 'p-d-flex', 'p-flex-column', 'p-p-5');

		const head = document.createElement('div');
		head.classList.add('head', 'p-mb-5');
		const logo = document.createElement('img');
		logo.src = 'assets/img/rapport_logo_dova.jpg';
		logo.style.paddingLeft = '2rem';
		logo.style.height = '3.25rem';
		head.appendChild(logo);

		const body = document.createElement('div');
		body.classList.add('body', 'index', 'p-d-flex', 'p-flex-column', 'p-p-5');
		body.style.height = '100%';

		const footer = document.createElement('div');
		footer.classList.add('footer', 'p-mt-6', 'p-mb-2', 'p-d-flex');
		const left = document.createElement('div');
		const right = document.createElement('div');
		right.className = 'p-ml-auto';
		left.textContent = 'Maandelijkse rapportage sociale veiligheid in het openbaar vervoer';
		right.textContent = 'n+' + newId;
		footer.style.color = '#5A5A5A';
		footer.style.fontSize = '12px';
		footer.appendChild(left);
		footer.appendChild(right);

		const tableDiv = document.createElement('div');
		tableDiv.classList.add('appendix-table', 'p-datatable-dova', 'p-datatable');
		const table = document.createElement('table');
		const thead = document.createElement('thead');
		thead.className = 'p-datatable-thead';
		const tbody = document.createElement('tbody');
		tbody.className = 'p-datatable-tbody';
		const tr = document.createElement('tr');

		const fields = ['Naam', 'Type', 'Definitie', 'Verplicht'];
		_.forEach(fields, label => {
			const th = document.createElement('th');
			th.textContent = label;
			th.colSpan = label === 'Definitie' ? 3 :1;
			tr.appendChild(th);
		});

		thead.appendChild(tr);

		table.appendChild(thead);
		table.appendChild(tbody);

		tableDiv.appendChild(table);

		body.appendChild(tableDiv);

		page.appendChild(head);
		page.appendChild(body);
		page.appendChild(footer);

		currentPage.parentElement.append(page);

		return page;
	}




	/* unsubscribe when changing pages */
	ngOnDestroy() {
		this.panelSubject.unsubscribe();

		if(this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
		if(this.queryParamSubscription) {
			this.queryParamSubscription.unsubscribe();
		}
	}

	getDate() {
		return new Date();
	}

	getUserInfoString() {
		return this.authService.getUser().name;
	}

}
