import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {global} from "../../../../globals";
import {AppLoader} from "../../../classes/Globals";
import {SessionService} from "../../../services/data/session.service";
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {MessageService} from "primeng/api";
import _ from "lodash";
import {util} from "../../../../services";

@Component({
  selector: 'base-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

	public form: FormGroup;

	public get controls() {
		return this.form.controls;
	}

	constructor(private _fb: FormBuilder, public ref: DynamicDialogRef, public messageService: MessageService, public sessionService: SessionService) {
	}

	ngOnInit() {
		this.form = this._fb.group({
			email: ['', [Validators.required, Validators.email]],
		});
	}

	hasError(control: AbstractControl, errorCode: string = null): boolean {

		// no interaction yet
		if(control.pristine || control.untouched) {
			return false;
		}

		// match specific errorCode
		if(errorCode !== null) {
			return control.hasError(errorCode);
		}

		// return true when some errorCode
		return !_.isNil(control.errors);
	}

	submit() {
		if (this.form.invalid) {
			this.controls.email.markAsDirty();
			this.controls.email.markAsTouched();
			return;
		}

		const forEmail = this.controls.email.value;

		global.disableLoader();
		this.sessionService.requestPasswordReset(forEmail)
			.then(() => {
				util.toast(`Er is een e-mail verstuurd naar: ${forEmail} met de vervolg instructies.`, 'E-mail succesvol verstuurd', 'info');
			})
			.finally(() => {
				global.loader = AppLoader.PAGE;
				this.ref.close();
			});
	}

}

