<div class="p-field-checkbox p-mb-2 p-mt-1 p-unselectable-text"
	 *ngIf="panel.resultData !== null && panel.resultData.labels?.length">
	<p-checkbox inputId="showLabels"
				[(ngModel)]="panel.show_labels"
				(ngModelChange)="reloadPanel()"
				[binary]="true">
	</p-checkbox>
	<label [for]="'showLabels'">Toon labels</label>
</div>

<div>
	<canvas [id]="'p-'+panel.index"></canvas>
</div>