import {Injectable} from '@angular/core';
import {BackendFunctions, Sherpa} from "../../classes/Sherpa";
import {auth, sherpa, util} from "../../../services";
import {
	ChartDataWithConfig,
	GroupOption,
	MultiChartDataWithConfig, RegistrationResult,
	StoredConfig,
	StoredConfigsWrapper, UserRegistration
} from "../../types/sherpa";
import {SherpaError} from "../../classes/SherpaError";
import {AnalysePanel} from "../../classes/models/AnalysePanel";
import {UserModel} from "../../classes/models/UserModel";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {DialogService} from "primeng/dynamicdialog";
import {DashboardStats} from "../../classes/models/DashboardStats";
import _ from "lodash";

@Injectable({
	providedIn: 'root'
})
export class ConfigsService {

	private api: BackendFunctions = {};

	constructor(private readonly http: HttpClient) {
		sherpa.api$.subscribe((sherpa: Sherpa) => {
			this.api = sherpa.api;
		});
	}

	saveConfig(filter: StoredConfig): Promise<StoredConfig> {
		return this.api.saveStoredConfig<StoredConfig>(auth.token(), filter)
			.catch((e: SherpaError) => util.handleError(e));
	}

	deleteConfig(stored_config_id: number): Promise<void> {
		return this.api.deleteStoredConfig<void>(auth.token(), stored_config_id)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getStoredConfigs(): Promise<StoredConfigsWrapper> {
		return sherpa.api$.toPromise().then((sherpa: Sherpa) => {;
			return sherpa.api.getStoredConfigs<StoredConfigsWrapper>(auth.token())
				.catch((e: SherpaError) => util.handleError(e));
		})
	}

	getPanelsForConfigId(stored_config_id: number): Promise<MultiChartDataWithConfig> {
		const specialCases = ['kwh_per_paal', 'rfid_per_paal']; // laadpaal naar laadpunt

		return this.api.getPanelsForConfigId<MultiChartDataWithConfig>(auth.token(), stored_config_id)
			.then((result) => {

				let index = 0;
				_.forEach(result.config, config => {
					if(_.includes(specialCases, config.measurement)) {
						_.forEach(result.chartData[index].datasets, ds => {
							const newData: number[] = [];

							// todo
							// waarde delen door 2, we hebben alleen waarde voor laadpaal,
							// een laadpaal bestaat uit (gem.) 2 laadpunten, dus delen door 2
							// dit is een tijdelijk workaround tot wanneer we de externe api gaan gebruiken
							_.forEach(ds.data, data => {
								newData.push(_.divide(data, 2));
							});

							ds.data = newData;
						});
					}
					index++;
				});

				return result;
			})
			.catch((e: SherpaError) => util.handleError(e));
	}

	getChartDataForConfig(stored_config_id: number): Promise<ChartDataWithConfig> {
		return this.api.getChartDataForConfig<ChartDataWithConfig>(auth.token(), stored_config_id)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getUserDefaultFilters(): Promise<any> {
		return this.api.getUserDefaultFilters<any>(auth.token())
			.catch((e: SherpaError) => util.handleError(e));
	}

	getLoggingAgreement(): Promise<boolean> {
		return this.api.getLoggingAgreement<boolean>(auth.token())
			.catch((e: SherpaError) => util.handleError(e));
	}

	setLoggingAgreement(value: boolean): Promise<void> {
		return this.api.setLoggingAgreement<void>(auth.token(), value)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getUserPreferences(): Promise<any> {
		return this.api.getUserPreferences<any>(auth.token())
			.catch((e: SherpaError) => util.handleError(e));
	}

	setUserPreferences(config: any): Promise<any> {
		return this.api.setUserPreferences<any>(auth.token(), config)
			.catch((e: SherpaError) => util.handleError(e));
	}

	storeUserDefaultFilter(defaultFilters: any): Promise<void> {
		return this.api.storeUserDefaultFilter<any>(auth.token(), defaultFilters)
			.catch((e: SherpaError) => util.handleError(e));
	}

	searchFilter(filterKey: string, searchQuery: string): Promise<any> {
		return this.api.searchFilter<any>(auth.token(), filterKey, searchQuery)
			.catch((e: SherpaError) => util.handleError(e));
	}

	saveTempStoredConfig(notSavedConfig: StoredConfig): Promise<StoredConfig> {
		return this.api.saveTempStoredConfig<StoredConfig>(auth.token(), notSavedConfig)
			.catch((e: SherpaError) => util.handleError(e));
	}

	sleep(ms : number): Promise<any> {
		return this.api.sleep<any>(ms)
			.catch((e: SherpaError) => util.handleError(e));
	}

	confirmRegistration(uuid : string, secret: string): Promise<RegistrationResult> {
		return sherpa.api$.toPromise().then((sherpa: Sherpa) => {
			return sherpa.api.confirmRegistration<RegistrationResult>(uuid, secret).catch((e: SherpaError) => util.handleError(e));
		});
	}

	getRegistrationStatus(email: string, secret: string) {
		return sherpa.api$.toPromise().then((sherpa: Sherpa) => {
			return sherpa.api.getRegistrationStatus<RegistrationResult>(email, secret).catch((e: SherpaError) => util.handleError(e));
		});
	}

	registerUser(registration: UserRegistration): Promise<RegistrationResult> {
		return sherpa.api$.toPromise().then((sherpa: Sherpa) => {
			return sherpa.api.registerUser<RegistrationResult>(registration).catch((e: SherpaError) => util.handleError(e));
		});
	}

	downloadFile(email : string, secret: string, analyse_type: string): void {

		const baseUrl = `/rest/download/${email}/${secret}/${analyse_type}`;
		const headers = new HttpHeaders();

		this.http.get(baseUrl,{headers, responseType: 'blob' as 'json'}).subscribe(
			(response: any) =>{
				let dataType = 'application/csv';
				let binaryData = [];
				binaryData.push(response);

				let downloadLink = document.createElement('a');
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute('download', "evdata.csv");

				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		)
	}


	getDashboardData(): Promise<DashboardStats[]> {
		return sherpa.api$.toPromise().then((sherpa: Sherpa) => {
			return sherpa.api.getDashboardStats<DashboardStats[]>()
				.then((result) => {

					_.forEach(result, stat => {
						stat.kwh_per_station = _.divide(stat.kwh_per_station, 2);
						stat.rfid_per_station = _.divide(stat.rfid_per_station, 2);
					});

					return result;
				})
				.catch((e: SherpaError) => util.handleError(e));
		});
	}


}
